import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const theme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: 'none !important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          borderBottom: 'none !important',
        },
        root: {
          height: 52,
          padding: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: 0.5,
          fontWeight: '600',
          fontSize: 14,
          minHeight: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          maxWidth: '360px',
          overflow: 'hidden',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '360px',
          overflow: 'hidden',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          width: '100%',
          '&::before': {
            height: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: 'center',
        },
        root: {
          '&.Mui-disabled': {
            opacity: 1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
  },
  palette: {
    action: {
      activatedOpacity: 0.12,
      active: '#00EE77',
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      hoverOpacity: 0.06,
      selected: 'rgba(0,238,119,0.08)',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    contrastThreshold: 3,
    error: {
      contrastText: 'rgba(255,255,255,0.87)',
      dark: '#A90706',
      light: '#D33130',
      main: '#CA0807',
    },
    info: {
      contrastText: 'rgba(255,255,255,0.87)',
      dark: '#182123',
      light: '#7A8284',
      main: '#223033',
    },
    primary: {
      contrastText: 'rgba(0,0,0,0.87)',
      dark: '#0DE070',
      light: '#00EE77',
      main: '#00EE77',
    },
    secondary: {
      contrastText: 'rgba(255,255,255,0.87)',
      dark: '#182123',
      light: '#7A8284',
      main: '#223033',
    },
    success: {
      contrastText: '#rgba(0,0,0,0.87)',
      dark: '#0DE070',
      light: '#00EE77',
      main: '#00EE77',
    },
    // type: 'light',
    warning: {
      contrastText: 'rgba(0,0,0,0.87)',
      dark: '#FFA000',
      light: '#FFD54F',
      main: '#FFC107',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: '"Inter Regular", sans-serif',
  },
})

interface Props {
  children: React.ReactNode
}

const LightTheme: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default LightTheme
