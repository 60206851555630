// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
.custom-theme__rs-theme-light--OQpdq {
  --rs-btn-link-text: #0c0d0d;
  --rs-btn-link-active-text: #0c0d0d;
  --rs-btn-link-hover-text: #0c0d0d;
  --rs-input-focus-border: #0c0d0d;
  --rs-state-focus-outline: #0c0d0d;
  --rs-text-link: #0c0d0d;
  --rs-text-link-hover: #00a751;
  --rs-bg-active: #00a751;
  --rs-calendar-range-bg: #eaffe9;
  --rs-btn-primary-bg: #313235;
  --rs-btn-primary-hover-bg: #313235;
  --rs-state-hover-bg: #f9fafa;
  --rs-listbox-option-hover-bg: #f9fafa;
  --rs-listbox-option-hover-text: #0c0d0d;
  --rs-calendar-time-unit-bg: #f9fafa;
  --rs-calendar-date-selected-text: #0c0d0d;
  --rs-calendar-cell-selected-hover-bg: #f9fafa;
  --rs-picker-value: #0c0d0d;
  --rs-state-error: #cacdce;
}
h4 {
  font-size: initial;
  line-height: initial;
}
`, "",{"version":3,"sources":["webpack://./src/custom-theme.less"],"names":[],"mappings":"AAEA;;EAEE,2BAAA;EACA,kCAAA;EACA,iCAAA;EACA,gCAAA;EACA,iCAAA;EACA,uBAAA;EACA,6BAAA;EACA,uBAAA;EACA,+BAAA;EACA,4BAAA;EACA,kCAAA;EACA,4BAAA;EACA,qCAAA;EACA,uCAAA;EACA,mCAAA;EACA,yCAAA;EACA,6CAAA;EACA,0BAAA;EACA,yBAAA;AADF;AAIA;EACE,kBAAA;EACA,oBAAA;AAFF","sourcesContent":["@import '@yaak/components/src/colors';\n\n:root,\n.rs-theme-light {\n  --rs-btn-link-text: @color-asphalt-grey-005;\n  --rs-btn-link-active-text: @color-asphalt-grey-005;\n  --rs-btn-link-hover-text: @color-asphalt-grey-005;\n  --rs-input-focus-border: @color-asphalt-grey-005;\n  --rs-state-focus-outline: @color-asphalt-grey-005;\n  --rs-text-link: @color-asphalt-grey-005;\n  --rs-text-link-hover: @color-green-060;\n  --rs-bg-active: @color-green-060;\n  --rs-calendar-range-bg: @color-green-099;\n  --rs-btn-primary-bg: @color-asphalt-grey-020;\n  --rs-btn-primary-hover-bg: @color-asphalt-grey-020;\n  --rs-state-hover-bg: @color-asphalt-grey-099;\n  --rs-listbox-option-hover-bg: @color-asphalt-grey-099;\n  --rs-listbox-option-hover-text: @color-asphalt-grey-005;\n  --rs-calendar-time-unit-bg: @color-asphalt-grey-099;\n  --rs-calendar-date-selected-text: @color-asphalt-grey-005;\n  --rs-calendar-cell-selected-hover-bg: @color-asphalt-grey-099;\n  --rs-picker-value: @color-asphalt-grey-005;\n  --rs-state-error: @color-asphalt-grey-080;\n}\n\nh4 {\n  font-size: initial;\n  line-height: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rs-theme-light": `custom-theme__rs-theme-light--OQpdq`
};
export default ___CSS_LOADER_EXPORT___;
