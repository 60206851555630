import React from 'react'
import { User } from '@yaak/components/services/api/api'
import Switch from '@yaak/components/src/Switch'

export const activeRowToggle = (
  user: User,
  handleChange: (user: User) => void
) => (
  <Switch
    checked={user?.active}
    onClick={(event) => event.stopPropagation()}
    onChange={(event) => {
      event.stopPropagation()
      handleChange(user)
    }}
  />
)

export const transpose = (matrix: any) =>
  matrix?.[0]?.map((col: any, i: number) => matrix.map((row: any) => row[i])) ||
  []
