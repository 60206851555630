import classNames from 'classnames'
import React, { memo } from 'react'
import style from './style.less'

export const IconSizes = {
  large: 'large',
  normal: 'normal',
  medium: 'medium',
}

export const IconTypes = {
  regular: 'regular',
}

export interface IconProps {
  name: string
  className?: string
  styles?: any
  size?: string
  type?: string
  onClick?: (event?: any) => void
  dataTestId?: string
}

const Icon: React.FunctionComponent<IconProps> = ({
  className,
  name,
  styles,
  size = IconSizes.normal,
  type,
  onClick,
  dataTestId,
}) => (
  <span
    className={classNames(
      style.icon,
      style[`icon${name}`],
      className,
      style[`icon${size}`],
      style[`icon${type}`]
    )}
    style={styles}
    onClick={onClick}
    data-testid={dataTestId}
  />
)

export default memo(Icon)
