// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__languageItem--wRvWj {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}
.style__languageItem--wRvWj > svg {
  transform: scale(0.833);
}
`, "",{"version":3,"sources":["webpack://./src/pages/PartnerOverview/dialogs/PartnerEditDialog/style.less"],"names":[],"mappings":"AAEA;EAEI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AAFJ;AAHA;EAQM,uBAAA;AAFN","sourcesContent":["@import '@yaak/components/src/spacing';\n\n:local {\n  .languageItem {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: @spacing-75;\n\n    > svg {\n      transform: scale(0.833);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageItem": `style__languageItem--wRvWj`
};
export default ___CSS_LOADER_EXPORT___;
