import style from './style.less'

export const isActiveRow = (
  data: {
    rows: any[]
    options?: any
  },
  i: number
) =>
  data.options && data.options.active && data.options.active.indexOf(i) !== -1

export const onMouseOver = (idx: number) => {
  const row = [...document.querySelectorAll(`.gridItem${idx}`)]
  row.map((r) => r.classList.add(style.hoverRow))
}

export const onMouseLeave = (idx: number) => {
  const row = [...document.querySelectorAll(`.gridItem${idx}`)]
  row.map((r) => r.classList.remove(style.hoverRow))
}
