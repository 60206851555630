import React, { ReactElement, useEffect, useState } from 'react'
import style from './style.less'
import Grid from '@yaak/components/src/Grid'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Toast from '@yaak/components/src/Toast'
import { toastType } from '@yaak/components/src/Toast/Toast'
import { useNavigate } from 'react-router-dom'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import { useSmallScreenMatches } from '../../customHooks/useSmallScreenMatches'
import Empty from '../Empty'
import {
  getMappedDrives,
  HEADERS,
  NUTRON_HEADERS,
} from '@yaak/admin/src/helpers/drives'
import {
  getActiveSessions,
  getSessions,
  Session,
} from '@yaak/components/services/api/api'
import SearchFilter from '../SearchFilter'

const headerSort = [
  'Time',
  'Partner',
  'Type',
  'Vehicle',
  'Kit ID',
  'Driver',
  'Instructor',
]

const sortValue = [
  'startTimestamp',
  'partnerName',
  'driveType',
  'vin',
  'dongleId',
  'driverName',
  'instructorName',
]

const headerSortNutron = ['Type', 'Kit ID']

const sortValueNutron = ['driveType', 'dongleId']

interface SessionFilter {
  partnerId?: string
  userId?: string
  searchQuery?: string
  vin?: string
  dongleId?: string
  driveType?: number
}

interface DrivesOverviewProps {
  token: string
  filter?: SessionFilter
  withHeader?: boolean
  withInstructor?: boolean
  withPartner?: boolean
  withKit?: boolean
  dsAdmin?: boolean
  withDriver?: boolean
  withVehicle?: boolean
  fixedColumns?: number
  nutron?: boolean
  onClick?: (sessionId: string) => void
}

interface dateRange {
  sessionStart: string | undefined
  sessionEnd: string | undefined
}

const DrivesOverview: React.FunctionComponent<DrivesOverviewProps> = ({
  token,
  filter = {},
  withHeader = true,
  withInstructor = true,
  withPartner = true,
  withKit = true,
  withDriver = true,
  withVehicle = true,
  fixedColumns = 0,
  dsAdmin = false,
  nutron = false,
  onClick,
}): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [interval, setIntervalValue] = useState<NodeJS.Timeout>()
  const [sessions, setSessions] = useState<Session>({} as Session)
  const [mappedDrives, setMappedDrives] = useState<any>()
  const [activeSessions, setActiveSessions] = useState<Session>({} as Session)
  const [toast, setShowToast] = useState<toastType | null>(null)
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [searchQuery, setSearchQuery] = useState('')
  const [dateRange, setDateRange] = useState<dateRange | null>({
    sessionStart: undefined,
    sessionEnd: undefined,
  })
  const smallScreenMatches = useSmallScreenMatches()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchSessions = async () => {
      const sessions = await getSessions({
        token,
        sortBy,
        sortOrder,
        partnerId: filter.partnerId,
        userId: filter.userId,
        searchQuery: filter.searchQuery,
        vin: filter.vin,
        dongleId: filter.dongleId,
        driveType: filter.driveType,
        onAlert: setShowToast,
      })
      const activeSessions = await getActiveSessions({
        token,
        onAlert: setShowToast,
      })
      setActiveSessions(activeSessions)
      setSessions(sessions)
      setLoading(false)
    }

    token && fetchSessions()
  }, [token, sortBy, sortOrder, filter.userId])

  useEffect(() => {
    const fetchSessions = async () => {
      const sessions = await getSessions({
        token,
        sortBy,
        sortOrder,
        partnerId: filter.partnerId,
        userId: filter.userId,
        onAlert: setShowToast,
        searchQuery,
        ...dateRange,
        vin: filter.vin,
        dongleId: filter.dongleId,
        driveType: filter.driveType,
      })

      setSessions(sessions)
    }

    if (searchQuery !== null) {
      const timeout = setTimeout(() => {
        token && fetchSessions()
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [token, searchQuery, dateRange, sortBy])

  useEffect(() => {
    if (sessions?.data?.length > 0) {
      const mappedDrives = getMappedDrives({
        sessionsData: sessions?.data,
        activeSessionsData: activeSessions?.data,
        setShowToast,
        totalCount: sessions.metaData.totalCount,
        matches: smallScreenMatches,
        navigate,
        options: {
          withPartner,
          withInstructor,
          fixedColumns,
          withKit,
          dsAdmin,
          withDriver,
          withVehicle,
          nutron,
        },
      })
      setMappedDrives(mappedDrives)
    }
  }, [sessions?.data, activeSessions?.data, smallScreenMatches])

  useEffect(() => {
    fetchMoreData && fetchData2()
    setFetchMoreData(false)
  }, [fetchMoreData])

  const fetchData = () => {
    setFetchMoreData(true)
  }
  const fetchData2 = async () => {
    const morePages =
      sessions.data.length &&
      sessions.data.length < sessions.metaData.totalCount

    if (morePages) {
      const newSessionPage = await getSessions({
        token,
        onAlert: setShowToast,
        offset: sessions.data.length,
        sortBy,
        sortOrder,
        searchQuery,
        partnerId: filter.partnerId,
        userId: filter.userId,
        ...dateRange,
        dongleId: filter.dongleId,
        vin: filter.vin,
        driveType: filter.driveType,
      })
      if (newSessionPage) {
        setSessions({
          ...sessions,
          data: [...sessions.data, ...newSessionPage.data],
        })
      }
    }
  }

  const onRowClick = (rowIndex: number) => {
    !nutron
      ? !dsAdmin && navigate(`/drives/${sessions.data[rowIndex].id}`)
      : onClick?.(sessions.data[rowIndex].id)
  }

  const onHeaderClicked = (props: any) => {
    if (props.sortOrder[props.index] !== null) {
      setSortBy(nutron ? sortValueNutron[props.index] : sortValue[props.index])
      setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
    }
  }

  return (
    <div className={withHeader ? style.overview : style.overviewWithoutHeader}>
      <Typography
        type={TypographyTypes.headline}
        className={!withHeader ? style.hide : undefined}
      >
        {'Drives'}
      </Typography>
      <Divider className={!withHeader ? style.hide : undefined} />
      <SearchFilter
        setSearchQuery={setSearchQuery}
        setDateRange={setDateRange}
      />
      <div
        className={withHeader ? style.gridContainer : style.userGridContainer}
      >
        {loading ? (
          <ProgressBar />
        ) : sessions?.data?.length > 0 && mappedDrives?.rows?.length > 0 ? (
          <Grid
            data={mappedDrives}
            fetchData={fetchData}
            headerSort={nutron ? headerSortNutron : headerSort}
            onHeaderClicked={onHeaderClicked}
            onRowClick={onRowClick}
            headers={nutron ? NUTRON_HEADERS : HEADERS}
          />
        ) : searchQuery ? (
          <Empty
            header={'Looks like no drives match your search criteria.'}
            content={'Try changing your search to see more results.'}
          />
        ) : (
          <Empty
            header={'Whoops! Looks like there are no drives yet.'}
            content={
              'Drives will appear as soon as partners start expert and student drives or internal test drives are conducted.'
            }
          />
        )}
      </div>
      {toast && <Toast toast={toast} setShowToast={setShowToast} />}
    </div>
  )
}

export default DrivesOverview
