export const getDriveType = (type: number) => {
  switch (type) {
    case 0:
      return 'Invalid'
    case 1:
      return 'Expert'
    case 2:
      return 'Student'
    case 3:
      return 'Test'
    case 4:
      return 'Simulation'
    default:
      return 'Unknown'
  }
}
