import React from 'react'
import style from './style.less'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'

interface ExamResultProps {
  passedAnExam?: boolean
  userActive?: boolean
  success: boolean
}

const ExamResult: React.FunctionComponent<ExamResultProps> = ({
  passedAnExam = true,
  userActive = true,
  success,
}) => {
  const className = success
    ? userActive
      ? style.passedExamActive
      : style.passedExamInactive
    : style.failedExam

  return (
    <div
      className={
        passedAnExam ? classNames(className, style.examResult) : undefined
      }
    >
      {passedAnExam ? (
        success ? (
          <Typography type={TypographyTypes.label}>
            <FormattedMessage id={'add_exam_dialog.passed'} />
          </Typography>
        ) : (
          <Typography type={TypographyTypes.label}>
            <FormattedMessage id={'add_exam_dialog.failed'} />
          </Typography>
        )
      ) : (
        '—'
      )}
    </div>
  )
}

export default ExamResult
