import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import config from '@yaak/components/services/api/config'
import App from './App'
import LightTheme from '@yaak/components/src/LightTheme'
import AccessDenied from '@yaak/components/src/AccessDenied'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import packageJson from '../package.json'
import Toast from '@yaak/components/src/Toast'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import 'rsuite/dist/rsuite.min.css'
import './custom-theme.less'

const FallbackComponent = ({ error, componentStack }: any) => {
  const toast = {
    type: ToastTypes.error,
    text:
      process.env.NODE_ENV === 'development'
        ? `${error.cause.name} ${componentStack}`
        : error.cause.name,
  }

  return error.cause.name && <Toast toast={toast} />
}

Sentry.init({
  dsn: config.sentry.dns,
  release: packageJson.version,
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/admin-staging.yaak\.ai/,
    /^https:\/\/admin.yaak\.ai/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const Auth0App = () => {
  const accessDeniedError = document.location.search.includes(
    'error=access_denied'
  )

  return (
    <Auth0Provider
      audience={config.auth.audience}
      clientId={config.auth.clientId}
      domain={config.auth.domain}
      redirectUri={`${window.location.origin}?location=${window.location.pathname}`}
      scope={config.auth.scope}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <LightTheme>
        <BrowserRouter>
          {accessDeniedError ? <AccessDenied /> : <App />}
        </BrowserRouter>
      </LightTheme>
    </Auth0Provider>
  )
}

const rootElement = document.getElementById('root')
const root = rootElement && createRoot(rootElement)

export default Auth0App
root?.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <React.StrictMode>
      <Auth0App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>
)
