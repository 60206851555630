import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import {
  Partner,
  getPartners,
  getVehicles,
  updateVehicle,
  createVehicle,
  CreateVehicle,
  VehicleResponse,
} from '@yaak/components/services/api/api'
import Grid from '@yaak/components/src/Grid'
import appStyle from '../../style.less'
import SearchBar from '@yaak/components/src/SearchBar'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Button from '@yaak/components/src/Button'
import Typography from '@yaak/components/src/Typography'
import Divider from '@yaak/components/src/Divider'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import {
  editVehicleAction,
  getMappedVehicles,
  HEADERS,
} from '../../helpers/vehicles'
import { EditVehicleDialog } from './VehicleDialogs'
import { useNavigate } from 'react-router-dom'
import Empty from '@yaak/components/src/Empty'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'

interface VehicleOverviewProps {
  token: string
}

const HEADER_SORT = [
  'License Plate',
  'VIN',
  'Kit ID',
  'Partner',
  'Calibration data',
]

const sortValue = [
  'licensePlate',
  'vin',
  '',
  'dongleId',
  'partnerName',
  'calibrationAvailable',
]

const VehicleOverview: React.FunctionComponent<VehicleOverviewProps> = ({
  token,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [vehicles, setVehicles] = useState<VehicleResponse>()
  const [partners, setPartners] = useState<Partner[]>([])
  const [selectedVehicle, setSelectedVehicle] = useState<CreateVehicle>()
  const [searchQuery, setSearchQuery] = useState('')
  const [creatingVehicle, setCreatingVehicle] = useState<boolean>(false)
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()

  const navigate = useNavigate()

  const smallScreenMatches = useSmallScreenMatches()

  const mappedData = useMemo(
    () =>
      vehicles &&
      getMappedVehicles(vehicles.data, navigate, smallScreenMatches, {
        onEdit: (vin) =>
          editVehicleAction({
            vin,
            vehicles,
            setSelectedVehicle,
          }),
      }),
    [vehicles]
  )

  const fetchVehicles = async () => {
    const vehicles = await getVehicles({
      token,
      onAlert: setShowToast,
      searchQuery,
    })

    setVehicles(vehicles)
    setLoading(false)
  }

  useEffect(() => {
    const fetchVehicles = async () => {
      const [vehicles, partners] = await Promise.all([
        getVehicles({
          token,
          onAlert: setShowToast,
          searchQuery,
          sortBy,
          sortOrder,
        }),
        getPartners({ token, onAlert: setShowToast }),
      ])
      setLoading(false)
      setVehicles(vehicles)
      setPartners(
        partners?.reduce(
          (pre, curr) => Object.assign(pre, { [curr.id]: curr }),
          []
        )
      )
    }
    token && fetchVehicles()
  }, [token, searchQuery, sortBy, sortOrder])

  const fetchData = () => {
    setFetchMoreData(true)
  }

  useEffect(() => {
    fetchMoreData && fetchData2()
    setFetchMoreData(false)
  }, [fetchMoreData])

  const fetchData2 = async () => {
    const morePages =
      vehicles?.data.length &&
      vehicles.data.length < vehicles.metaData.totalCount

    if (morePages) {
      const newVehiclesPages = await getVehicles({
        token,
        onAlert: setShowToast,
        offset: vehicles.data.length,
        sortBy,
        sortOrder,
      })

      if (newVehiclesPages) {
        setVehicles({
          ...vehicles,
          data: [...vehicles.data, ...newVehiclesPages.data],
        })
      }
    }
  }

  const refetchVehicles = async () => {
    setLoading(true)
    await fetchVehicles()
  }

  const onUpdateVehicle = async (vehicle?: CreateVehicle) => {
    if (vehicle) {
      await updateVehicle({ token, vehicle, onAlert: setShowToast })
      setSelectedVehicle(undefined)
      await refetchVehicles()
    }
  }

  const onCreateVehicle = async (vehicle?: CreateVehicle) => {
    if (vehicle) {
      await createVehicle({ token, vehicle, onAlert: setShowToast })
      setCreatingVehicle(false)
      await refetchVehicles()
    }
  }

  const onRowClick = useCallback(
    (rowIndex: number) => {
      navigate(`/vehicles/${vehicles?.data[rowIndex].vin}`)
    },
    [vehicles]
  )

  return (
    <div className={appStyle.page}>
      <Typography type={TypographyTypes.headline}>{'Vehicles'}</Typography>
      <Divider />
      <div className={style.searchContainer}>
        <SearchBar
          clear
          placeholder={
            'Search license plates, VINs, kits and partners suggestion'
          }
          setSearchQuery={setSearchQuery}
        />
        <Button
          className={style.addButton}
          onClick={() => setCreatingVehicle(true)}
          text={'Add vehicle'}
        />
      </div>
      {loading ? (
        <ProgressBar />
      ) : (
        vehicles?.data && (
          <div className={style.gridWrapper}>
            {mappedData && (
              <Grid
                data={mappedData}
                headerSort={HEADER_SORT}
                onHeaderClicked={(props) => {
                  setSortBy(sortValue[props.index])
                  setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
                }}
                fetchData={fetchData}
                headers={HEADERS}
                onRowClick={onRowClick}
              />
            )}
            {vehicles.data?.length === 0 &&
              (searchQuery ? (
                <Empty
                  header={'Looks like no vehicles match your search criteria.'}
                  content={'Try changing your search to see more results.'}
                />
              ) : (
                <Empty
                  header={'Whoops! Looks like there are no vehicles yet.'}
                  content={
                    'Start adding vehicles using the ‘Add vehicle’ button above.'
                  }
                />
              ))}
          </div>
        )
      )}
      <EditVehicleDialog
        isOpen={!!selectedVehicle || creatingVehicle}
        vehicle={selectedVehicle}
        partners={Object.values(partners)}
        onCancel={() => {
          setSelectedVehicle(undefined)
          setCreatingVehicle(false)
        }}
        onSave={creatingVehicle ? onCreateVehicle : onUpdateVehicle}
      />
    </div>
  )
}

export default VehicleOverview
