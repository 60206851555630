import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  deleteUser,
  createUser,
  User,
  Partner,
  updateUser,
  getUsers,
} from '@yaak/components/services/api/api'
import appStyle from '../../style.less'
import {
  getHeaderByRole,
  getMappedUsers,
  getRoleNameByRole,
  UserOverviewOptions,
} from '../../helpers/users'
import SearchBar from '@yaak/components/src/SearchBar'
import style from './style.less'
import WarningDialog from '@yaak/components/src/WarningDialog'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Button from '@yaak/components/src/Button'
import { UserCreationDialog, UserEditDialog } from './UserDialogs'
import Grid from '@yaak/components/src/Grid'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import Empty from '@yaak/components/src/Empty'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { useAuth0 } from '@auth0/auth0-react'
import { isSimAdmin } from '../../utils/simAdmin'

const HEADER_SORT = ['Name', 'Partner', 'E-Mail', 'Status', 'ToS']

const sortValue = [
  'firstName',
  'partner',
  'email',
  '',
  'active',
  'tosAcceptedAt',
]

interface UserOverviewProps {
  token: string
  role: string
  partnerId?: string
  options?: UserOverviewOptions
  headers: string[]
}

export interface MappedPartner {
  [key: string]: Partner
}

export type UsersResponse = {
  data: User[]
  metaData: {
    count: number
    totalCount: number
  }
}

const getDeleteDialogTitle = (role: string, user?: User) => {
  switch (role) {
    case 'ds-admin':
      return `Are you sure you want to delete DS Admin ${user?.firstName} ${user?.lastName}?`
    case 'ds-instructor':
      return `Are you sure you want to delete instructor ${user?.firstName} ${user?.lastName}?`
    case 'ds-student':
      return `Are you sure you want to delete student ${user?.firstName} ${user?.lastName}?`
    default:
      return 'Delete User'
  }
}

const getDeleteDialogDescription = (role: string) => {
  switch (role) {
    case 'ds-admin':
    case 'ds-instructor':
    case 'ds-student':
      return 'Associated drives, vehicles, and other users will not be affected.'
    default:
      return 'Are you sure you want to delete this user?'
  }
}

const defaultOptions: UserOverviewOptions = {
  smallColumns: [],
  fixedColumns: 0,
  withHeader: true,
  withPartner: true,
  withToS: true,
  searchPlaceholder: '',
  yaakAdmin: false,
  withInstructorAdmin: false,
}

const UserOverview: React.FunctionComponent<UserOverviewProps> = ({
  headers,
  token,
  role,
  partnerId,
  options,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<UsersResponse>({} as UsersResponse)
  const [userToDelete, setUserToDelete] = useState<User>()
  const [searchQuery, setSearchQuery] = useState('')
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [creatingUser, setCreatingUser] = useState<boolean>(false)
  const [userToEdit, setUserToEdit] = useState<User | null>(null)
  const [sortOrder, setSortOrder] = useState<string>('ASC')
  const [sortBy, setSortBy] = useState<string>()

  const smallScreenMatches = useSmallScreenMatches()

  const navigate = useNavigate()

  const { user } = useAuth0()
  const simAdmin = isSimAdmin(user)

  options = {
    ...defaultOptions,
    ...options,
  }

  useEffect(() => {
    token && fetchUsers()
  }, [token, role, sortOrder, sortBy])

  useEffect(() => {
    if (searchQuery !== null) {
      const timeout = setTimeout(() => {
        token && fetchUsers()
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [searchQuery])

  const deleteUserAction = (user: User) => {
    setUserToDelete(user)
  }

  const fetchUsers = async () => {
    const users = await getUsers({
      token,
      onAlert: setShowToast,
      searchQuery,
      roles: [role],
      partnerId,
      sortBy,
      sortOrder,
    })
    setUsers(users)
    setLoading(false)
  }

  const refetchUsers = async () => {
    setLoading(true)
    await fetchUsers()
  }

  const onCreateUser = async (user: any) => {
    setCreatingUser(false)
    user.roles = [role]
    await createUser({ user, token, onAlert: setShowToast })
    return refetchUsers()
  }

  const onUserDeleteCancel = () => setUserToDelete(undefined)

  const onUserDelete = async () => {
    if (userToDelete) {
      await deleteUser({
        token,
        user: userToDelete,
        onAlert: setShowToast,
      })
      const users = await getUsers({
        token,
        onAlert: setShowToast,
        roles: [role],
      })
      setUsers(users)
      setUserToDelete(undefined)
    }
  }

  const fetchData = () => {
    setFetchMoreData(true)
  }

  useEffect(() => {
    fetchMoreData && fetchData2()
    setFetchMoreData(false)
  }, [fetchMoreData])

  const fetchData2 = async () => {
    const morePages =
      users.data?.length && users.data?.length < users.metaData.totalCount

    if (morePages) {
      const newUsersPages = await getUsers({
        token,
        onAlert: setShowToast,
        offset: users.data?.length,
        roles: [role],
        sortBy,
        sortOrder,
        partnerId,
      })

      if (newUsersPages) {
        setUsers({
          ...users,
          data: [...users.data, ...newUsersPages.data],
        })
      }
    }
  }

  const onEditUser = (id: string) => {
    setUserToEdit(users.data?.find((user) => user.id === id) || null)
  }

  const onUpdateUser = async (user: any) => {
    user.id = userToEdit?.id
    setUserToEdit(null)
    await updateUser({ user, token, onAlert: setShowToast })
    return refetchUsers()
  }

  return (
    <div className={options.withHeader ? appStyle.page : style.content}>
      {options?.withHeader && (
        <>
          <Typography type={TypographyTypes.headline}>
            {getHeaderByRole(role)}
          </Typography>
          <Divider />
        </>
      )}
      {loading ? (
        <ProgressBar />
      ) : (
        <>
          <div className={appStyle.actionBar}>
            <div className={style.searchBox}>
              <SearchBar
                clear
                placeholder={options?.searchPlaceholder || 'Search users'}
                setSearchQuery={setSearchQuery}
              />
            </div>
            <div className={style.actionBarButtonBox}>
              <Button
                onClick={() => setCreatingUser(true)}
                text={`Add ${getRoleNameByRole(role)}`}
              ></Button>
            </div>
          </div>
          <Grid
            data={
              users?.data
                ? getMappedUsers(
                    {
                      users: users.data,
                      setShowToast,
                      actions: {
                        onEditUser,
                        deleteUserAction,
                        navigate,
                      },
                      matches: smallScreenMatches,
                    },
                    options,
                    headers
                  )
                : { rows: [] }
            }
            fetchData={fetchData}
            headerSort={HEADER_SORT}
            onHeaderClicked={(props) => {
              setSortBy(sortValue[props.index])
              setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
            }}
            headers={headers}
          ></Grid>
          {users?.data?.length === 0 &&
            (searchQuery ? (
              <Empty
                header={'Looks like no users match your search criteria.'}
                content={'Try changing your search to see more results.'}
              />
            ) : (
              <Empty
                header={`Whoops! Looks like there are no ${
                  role === 'ds-student'
                    ? 'students'
                    : role === 'ds-instructor'
                    ? 'instructors'
                    : 'DS Admins'
                } yet.`}
                content={
                  'Start adding instructors using the ‘Add instructor’ button above.'
                }
              />
            ))}
        </>
      )}
      <UserCreationDialog
        token={token}
        isOpen={creatingUser}
        role={role}
        onClose={() => setCreatingUser(false)}
        onCreate={onCreateUser}
        simAdmin={simAdmin}
      />
      <UserEditDialog
        token={token}
        user={userToEdit}
        isOpen={userToEdit !== null}
        role={role}
        onClose={() => setUserToEdit(null)}
        onCreate={onUpdateUser}
        simAdmin={simAdmin}
      />
      <WarningDialog
        isOpen={userToDelete !== undefined}
        onCancel={onUserDeleteCancel}
        onSubmit={onUserDelete}
        buttons={{
          cancel: 'Cancel',
          submit: 'Delete',
        }}
        dialogTitle={getDeleteDialogTitle(role, userToDelete)}
        dialogContentText={getDeleteDialogDescription(role)}
      />
    </div>
  )
}

export default UserOverview
