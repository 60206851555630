import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import PartnerOverview from './pages/PartnerOverview'
import UserOverview from './pages/UserOverview'
import UserPage from './pages/UserPage'
import PartnerPage from './pages/PartnerPage'
import DrivesPage from './pages/DrivesPage'
import SettingsPage from './pages/SettingsPage'
import DriveDetailsPage from './pages/DriveDetailsPage'
import KitsPage from './pages/KitsPage'
import VehicleOverview from './pages/VehicleOverview'
import KitDetailsPage from './pages/KitDetailsPage'
import VehiclePage from './pages/VehicleDetailsPage'
import {DS_ADMINS_MAIN_HEADERS, INSTRUCTORS_MAIN_HEADERS, STUDENTS_MAIN_HEADERS,} from './helpers/users'
import SimulatorRigs from './pages/SimulatorRigsOverview'
import SimulationOverview from './pages/SimulationOverview'
import SimulatorRigsPage from './pages/SimulatorRigsPage'

interface RouterProps {
  token: string
}


const Router: React.FunctionComponent<RouterProps> = ({ token }) =>{
  return (
    <Routes>
      <Route path="/users/:userId" element={<UserPage token={token}/>}/>
      <Route path="/users/:userId/:tab" element={<UserPage token={token}/>}/>
      <Route
        path="/dsadmins"
        element={
          <UserOverview
            token={token}
            role={'ds-admin'}
            headers={DS_ADMINS_MAIN_HEADERS}
            options={{
              fixedColumns: 3,
              searchPlaceholder:
                'Search DS Admin names, partners, emails, mobile numbers, and IDs',
            }}
          />
        }
      />
      <Route
        path="/instructors"
        element={
          <UserOverview
            headers={INSTRUCTORS_MAIN_HEADERS}
            token={token}
            role={'ds-instructor'}
            options={{
              fixedColumns: 3,
              smallColumns: [0],
              withInstructorAdmin: true,
              withToS: false,
              searchPlaceholder:
                'Search instructor names, partners, emails, mobile numbers, and IDs',
            }}
          />
        }
      />
      <Route
        path="/students"
        element={
          <UserOverview
            token={token}
            role={'ds-student'}
            headers={STUDENTS_MAIN_HEADERS}
            options={{
              fixedColumns: 3,
              searchPlaceholder:
                'Search student names, partners, emails, mobile numbers, and IDs',
            }}
          />
        }
      />
      <Route
        path="/partners/:partnerId/:tab"
        element={<PartnerPage token={token}/>}
      />
      <Route path="/partners" element={<PartnerOverview token={token}/>}/>
      <Route path="/drives/onroad" element={<DrivesPage token={token}/>}/>
      <Route
        path="/drives/simulation/:tab"
        element={<SimulationOverview token={token}/>}
      />
      <Route
        path="/drives/:driveId"
        element={<DriveDetailsPage token={token}/>}
      />
      <Route path="/kits/:tab" element={<KitsPage token={token}/>}/>
      <Route
        path="/kits/details/:dongleId"
        element={<KitDetailsPage token={token}/>}
      />
      <Route path="/vehicles" element={<VehicleOverview token={token}/>}/>
      <Route path="/vehicles/:vin" element={<VehiclePage token={token}/>}/>
      <Route path="/simulatorrigs" element={<SimulatorRigs token={token}/>}/>
      <Route
        path="/simulatorrigs/:id"
        element={<SimulatorRigsPage token={token}/>}
      />

      <Route path="/settings/:tab" element={<SettingsPage token={token}/>}/>
      <Route path="/" element={<Navigate to="/partners"/>}/>
    </Routes>
  )
}

export default Router
