import React, { useContext } from 'react'
import FormDialog, {
  FormDetailsEntry,
  FormDialogTypes,
  InputProp,
} from '@yaak/components/src/FormDialog/FormDialog'
import {
  getDevices,
  getVehicles,
  Vehicle,
} from '@yaak/components/services/api/api'
import Icon from '@yaak/components/src/Icon'
import style from './style.less'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

interface CreatePairingDialogProps {
  token: string
  isOpen: boolean
  onClose: () => void
  onCreate: (result: any) => Promise<any>
}

const vehicleInput = (vehicles: Array<Vehicle>) => ({
  editable: true,
  name: 'vin',
  title: 'Vehicle',
  placeHolderText: 'Select vehicle',
  required: true,
  selections: vehicles.map((vehicle) => ({
    value: vehicle.vin,
    name: vehicle.vin,
    item: (props: any) => (
      <div {...props}>
        <div className={style.input}>
          <Icon name={'Car'} />
          {vehicle.vin}
        </div>
      </div>
    ),
  })),
})

const kitInput = (kits: Array<any>) => ({
  editable: true,
  name: 'dongleId',
  title: 'Kit',
  placeHolderText: 'Select kit',
  required: true,
  selections: kits.map((kit) => ({
    value: kit.dongleId,
    name: kit.dongleId,
    item: (props: any) => (
      <div {...props}>
        <div className={style.input}>
          <Icon name={'Device'} />
          {kit.dongleId}
        </div>
      </div>
    ),
  })),
})

export const CreatePairingDialog: React.FunctionComponent<
  CreatePairingDialogProps
> = ({ token, isOpen, onClose, onCreate }) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const buildCreateUserDialogInputs = async (): Promise<InputProp[]> => {
    const vehicles = await getVehicles({
      token,
      onAlert: setShowToast,
      limit: 2000000,
      paired: false,
    })
    const kits = await getDevices({
      token,
      paired: false,
      onAlert: setShowToast,
    })
    return [vehicleInput(vehicles.data), kitInput(kits)]
  }

  const toPairingRequestDetailsEntry = (result: any): FormDetailsEntry[] => {
    return [
      { icon: <Icon name={'Car'} />, value: result.vin },
      { icon: <Icon name={'Device'} />, value: result.dongleId },
    ].filter((e) => e) as FormDetailsEntry[]
  }

  return (
    <FormDialog
      title={`Create pairing request`}
      confirmationTitle="Confirm details"
      titleIcon={<Icon name={'Link'} size={IconSizes.large} />}
      open={isOpen}
      onCancel={onClose}
      onConfirmed={onCreate}
      inputs={buildCreateUserDialogInputs}
      dialogType={FormDialogTypes.creating}
      toDetailEntries={toPairingRequestDetailsEntry}
    />
  )
}
