import {Dispatch, SetStateAction} from "react";
import {toastType, ToastTypes} from "../../src/Toast/Toast";
import config from "./config";
import {LessonTopics} from "./api";

type alertMessagesType = {
  success?: {
    text?: string
    textId?: string
  }
  error?: { text: string }
}

export type RadarReport = {
  theme: string
  metacog: number
}

export type RadarReportResponse = {
  report: RadarReport[]
  themes: string[]
}

export type TimeReport = {
  metacog: number
}

export type TimeReportResponse = {
  entries: {
    [key: string]: TimeReport
  }
  theme: string
}

const processResponse = async ({
                                 response,
                                 onAlert,
                                 messages,
                                 auth0,
                               }: {
  response: Response
  onAlert: Dispatch<SetStateAction<toastType | null>>
  messages?: alertMessagesType
  auth0?: boolean
}) => {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202
  ) {
    messages?.success &&
    onAlert({
      text: messages.success.text,
      type: messages.success ? ToastTypes.success : ToastTypes.error,
    })
    return auth0 ? response?.text() : response?.json?.()
  } else {
    const text = await response.text()
    try {
      const parsed = JSON.parse(text)
      onAlert({
        text: `${parsed.code}: ${parsed.message}`,
        type: ToastTypes.error,
      })
      console.warn(parsed.code, parsed.message)
    } catch (e) {
      onAlert({
        text: `${response.status}: ${response.statusText}`,
        type: ToastTypes.error,
      })
      console.warn(response.status, response.statusText)
    }
  }
}

const getElearningRadarReportData = ({
  userId,
  token,
  onAlert,
}: {
  userId: string
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<RadarReportResponse> => {
  return fetch(`${config.elearningApiUrl}/v1/instructor/report/radar?driverId=${userId}`, {
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `Could not fetch Elearning data. ${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getElearningTimeReportData = ({
  userId, theme, token, onAlert
}: {
  userId: string
  theme: string
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<TimeReportResponse> => {
  return fetch(`${config.elearningApiUrl}/v1/instructor/report/time?theme=${theme}&driverId=${userId}`, {
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      console.log(error);
      onAlert({
        text: `Could not fetch Elearning data. ${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

export { getElearningRadarReportData, getElearningTimeReportData }
