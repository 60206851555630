const lessonTopicsToReadable: { [key: string]: string } = {
  DRIVING: 'Driving basics',
  PARKING: 'Parking basics',
  LANE_NAVIGATION: 'Lane Navigation',
  MAKING_TURNS: 'Making turns',
  GIVE_WAY: 'Give way',
  SPEED_AND_DISTANCE: 'Speed & distance',
  TRAFFIC_INTERACTION: 'Traffic interaction',
  SPECIFIC_SCENARIOS: 'Specific scenarios',
  OVERLAND_DRIVES: 'Overland drives',
  MOTORWAY: 'Night drives',
  NIGHT_DRIVES: 'Exam prep drives',
  EXAM_PREP: 'Motorway drives',
  STICK_SHIFT: 'Stick shift drives',
}

export const getReadableLessonTopic = (topic: string): string => {
  return lessonTopicsToReadable[topic] || ''
}
