import React, { useEffect, useState } from 'react'
import { toDuration } from '@yaak/admin/src/helpers/time'
export interface CounterProps {
  time: string
}

const Counter: React.FunctionComponent<CounterProps> = ({ time }) => {
  const [count, setCount] = useState(toDuration(time, new Date()))

  useEffect(() => {
    const timeout = setInterval(() => {
      setCount(toDuration(time, new Date()))
    }, 1000 * 60)

    return () => {
      clearTimeout(timeout)
    }
  }, [count])

  return <div>{count}</div>
}

export default Counter
