import React from 'react'
import { CreateVehicle, Partner } from '@yaak/components/services/api/api'
import { VINValidationRegex } from '../../helpers/vehicles'
import Icon, { IconSizes } from '@yaak/components/src/Icon/Icon'
import FormDialog from '@yaak/components/src/FormDialog'
import { partnerInput } from '../UserOverview/UserDialogInputs'
import { InputProp } from '@yaak/components/src/FormDialog/FormDialog'

interface VehicleEditDialogProps {
  vehicle?: CreateVehicle
  partners: Partner[]
  onCancel: () => void
  isOpen: boolean
  onSave: (vehicle?: CreateVehicle) => Promise<any>
}

const vinDialogInput = {
  name: 'vin',
  title: 'VIN',
  required: true,
  placeHolderText: 'VIN',
  validate: (vin: string) => VINValidationRegex.test(vin),
}

const licensePlateDialogInput = {
  name: 'licensePlate',
  title: 'License plate',
  required: true,
  placeHolderText: 'License plate',
}

const friendlyNameDialogInput = {
  name: 'friendlyName',
  title: 'Friendly name',
  placeHolderText: 'Friendly name',
}

export const EditVehicleDialog: React.FunctionComponent<
  VehicleEditDialogProps
> = ({ isOpen, vehicle, partners, onCancel, onSave }) => {
  const toVehicleDetailEntries = (result: any) => [
    { icon: <Icon name={'Car'} />, value: result.vin },
    {
      icon: <Icon name={'LicensePlate'} />,
      value: result.licensePlate,
    },
    {
      icon: <Icon name={'Partner'} size={IconSizes.medium} />,
      value: partners?.find((partner) => partner.id === result.partnerId)?.name,
    },
  ]

  const buildVehicleDialogInputs = async (): Promise<InputProp[]> => {
    return [
      {
        ...vinDialogInput,
        value: vehicle?.vin,
      },
      {
        ...licensePlateDialogInput,
        value: vehicle?.licensePlate,
      },
      {
        ...partnerInput(partners),
        value: vehicle?.partnerID,
      },
      {
        ...friendlyNameDialogInput,
        value: vehicle?.friendlyName,
      },
    ]
  }

  return (
    <FormDialog
      titleIcon={<Icon name={'Car'} size={IconSizes.large} />}
      title={!vehicle ? 'Add vehicle' : 'Edit vehicle'}
      inputs={buildVehicleDialogInputs}
      open={isOpen}
      confirmationTitle={'Confirm details'}
      toDetailEntries={toVehicleDetailEntries}
      onCancel={onCancel}
      onConfirmed={onSave}
    />
  )
}
