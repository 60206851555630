import React, { ReactElement, useContext, useEffect, useState } from 'react'
import appStyle from '../../style.less'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import Badge from '@yaak/components/src/Badge'
import Divider from '@yaak/components/src/Divider'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import {
  getSimDevices,
  SimRigsData,
  updateSimRig,
} from '@yaak/components/services/api/api'
import { useParams } from 'react-router-dom'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { getStatus } from '../../helpers/simulation'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import Icon from '@yaak/components/src/Icon'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import Tooltip from '@yaak/components/src/Tooltip'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import ProgressBar from '@yaak/components/src/ProgressBar'
import SimulationOverview from '../SimulationOverview'
import Button from '@yaak/components/src/Button'
import { SimulatorEditDialog } from '@yaak/components/src/SimulatorRigsOverview/SimulatorRigsDialogs'

interface SimulatorRigsPageProps {
  token: string
}
const SimulatorRigsPage: React.FunctionComponent<SimulatorRigsPageProps> = ({
  token,
}): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [simRig, setSimRig] = useState<SimRigsData>()
  const [simRigToEdit, setSimRigToEdit] = useState<SimRigsData | null>(null)

  const { id } = useParams()

  const { setShowToast } = useContext(ToastContext) as ToastContextType

  useEffect(() => {
    const fetchSimRig = async () => {
      const simRigs = await getSimDevices({
        token,
        onAlert: setShowToast,
      })
      setLoading(false)
      setSimRig(simRigs.data.filter((simRig) => simRig.id === id)[0])
    }

    token && fetchSimRig()
  }, [token, id])

  const editSimRig = async (simRig: SimRigsData) => {
    const simRigToUpdate = {
      ...simRigToEdit,
      ...simRig,
    }

    const updatedSimRig = await updateSimRig({
      token,
      simRig: simRigToUpdate,
      onAlert: setShowToast,
    })
    if (updatedSimRig) {
      setSimRigToEdit(null)
      setSimRig(updatedSimRig)
    }
  }

  return (
    <div className={appStyle.page}>
      <div className={style.container}>
        <Breadcrumbs
          first={{
            text: 'Simulator rigs',
            url: '/simulatorrigs',
          }}
          second={{
            text: simRig?.name,
          }}
          selfLink={true}
        />
        <div className={style.header}>
          <div className={style.headerInfo}>
            <Typography
              type={TypographyTypes.headline}
              size={TypographySizes.small}
            >
              {simRig?.name}
            </Typography>
            <Tooltip text={'Copy simulator rig ID'} position={'top'}>
              <Icon
                name={'Copy'}
                size={IconSizes.large}
                onClick={() => {
                  navigator.clipboard.writeText(simRig!.id)
                  setShowToast({ text: `ID Copied`, type: ToastTypes.success })
                }}
                className={style.icon}
              />
            </Tooltip>
          </div>
          <Button
            onClick={() => {
              simRig && setSimRigToEdit(simRig)
            }}
            icon={<Icon name={'Edit'} />}
            text={'Edit'}
            secondary
          />
        </div>
        <div className={style.tags}>
          {simRig?.status && getStatus(simRig?.status)}
          <Badge
            type={BadgeType.grey}
            icon={<Icon name={'Partner'} />}
            label={simRig?.partner?.name}
          />
        </div>
        <Divider />
      </div>
      {loading ? (
        <ProgressBar />
      ) : (
        simRig && (
          <div className={style.simRigsOverviewContainer}>
            <SimulationOverview
              token={token}
              withHeader={false}
              partnerId={simRig.partner?.id}
            />
          </div>
        )
      )}
      <SimulatorEditDialog
        token={token}
        simRig={simRigToEdit}
        isOpen={simRigToEdit !== null}
        onClose={() => setSimRigToEdit(null)}
        onCreate={editSimRig}
      />
    </div>
  )
}

export default SimulatorRigsPage
