// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__progressPath--pXDbO {
  stroke: #1aa6da;
  stroke-linecap: round;
}
.style__progressTrail--E_vfE {
  stroke: #f1f4f2;
}
`, "",{"version":3,"sources":["webpack://./../components/src/Progress/style.less"],"names":[],"mappings":"AAEA;EAEI,eAAA;EACA,qBAAA;AAFJ;AADA;EAOI,eAAA;AAHJ","sourcesContent":["@import '../colors';\n\n:local {\n  .progressPath {\n    stroke: @color-light-blue-060;\n    stroke-linecap: round;\n  }\n\n  .progressTrail {\n    stroke: @color-pewter-grey-095;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressPath": `style__progressPath--pXDbO`,
	"progressTrail": `style__progressTrail--E_vfE`
};
export default ___CSS_LOADER_EXPORT___;
