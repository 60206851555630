// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__header--d55zp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.style__content--knwIO {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  padding: 0.125rem;
  height: 100%;
}
.style__searchBar--mHW5I {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
}
.style__searchBar--mHW5I > div:first-of-type {
  width: 75%;
}
.style__gridContainer--bYIje {
  background: #fff;
  height: calc(100% - 10rem);
  display: flex;
  flex-direction: column;
}
.style__success--zID_a {
  color: #00a751;
}
.style__cancel--Bt8B9 {
  color: #d72a1d;
}
.style__input--gyxBx {
  display: flex !important;
  gap: 1rem !important;
}
.style__button--DKJMQ {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/KitsPage/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,mBAAA;EACA,8BAAA;AAHJ;AADA;EAQI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AAJJ;AAVA;EAkBI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,SAAA;EACA,mBAAA;AALJ;AAjBA;EAyBM,UAAA;AALN;AApBA;EA8BI,gBAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;AAPJ;AA1BA;EAqCI,cAAA;AARJ;AA7BA;EAyCI,cAAA;AATJ;AAhCA;EA6CI,wBAAA;EACA,oBAAA;AAVJ;AApCA;EAkDI,mBAAA;AAXJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/shadows';\n\n:local {\n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: @spacing-100;\n    overflow: hidden;\n    padding: @spacing-15;\n    height: 100%;\n  }\n\n  .searchBar {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    gap: @spacing-100;\n    margin-bottom: @spacing-100;\n\n    > div:first-of-type {\n      width: 75%;\n    }\n  }\n\n  .gridContainer {\n    background: @color-white;\n    height: calc(100% - 10rem);\n    display: flex;\n    flex-direction: column;\n  }\n\n  .success {\n    color: @color-green-060;\n  }\n\n  .cancel {\n    color: @color-red-050;\n  }\n\n  .input {\n    display: flex !important;\n    gap: @spacing-100 !important;\n  }\n\n  .button {\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `style__header--d55zp`,
	"content": `style__content--knwIO`,
	"searchBar": `style__searchBar--mHW5I`,
	"gridContainer": `style__gridContainer--bYIje`,
	"success": `style__success--zID_a`,
	"cancel": `style__cancel--Bt8B9`,
	"input": `style__input--gyxBx`,
	"button": `style__button--DKJMQ`
};
export default ___CSS_LOADER_EXPORT___;
