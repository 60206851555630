import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import style from './style.less'
import classNames from 'classnames'
import Typography from '../Typography'
import { TypographyTypes } from '../Typography/Typography'
import Button from '../Button'
import { FormattedMessage } from 'react-intl'
import Icon from '@yaak/components/src/Icon'

const TOAST_HEIGHT = 110
const TOAST_PADDING = 28

export const SlideType = {
  left: 'left',
  right: 'right',
  bottom: 'bottom',
  top: 'top',
}

export const PositionType = {
  center: 'center',
  left: 'left',
  end: 'end',
}

interface ToastAnimation {
  position?: string
  slide: string
}

export const ToastTypes = {
  error: 'error',
  success: 'success',
}

type ActionType = {
  text: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export type toastType = {
  text?: string
  textId?: string
  dismissible?: boolean
  action?: ActionType
  onClick?: () => void
  type?: string
}

export interface ToastProps {
  animation?: ToastAnimation
  toast: toastType
  setShowToast?: Dispatch<SetStateAction<toastType | null>>
}

const Toast: React.FunctionComponent<ToastProps> = ({
  setShowToast,
  toast,
  animation = { position: PositionType.center, slide: SlideType.bottom },
}) => {
  const [list, setList] = useState<toastType[]>([])

  useEffect(() => {
    setList([...list, toast])
  }, [toast])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast?.(null)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [toast])

  return (
    <div
      className={classNames(
        style.toastContainer,
        toast.type ? style.toastType : undefined,
        toast.type === ToastTypes.error ? style.toastTypeError : undefined,
        toast.type === ToastTypes.success ? style.toastTypeSuccess : undefined,
        toast.dismissible ? style.toastDismissible : undefined,
        toast.action ? style.toastAction : undefined,
        animation.slide === SlideType.top ? style.toastTop : undefined,
        animation.slide === SlideType.bottom ? style.toastBottom : undefined,
        animation.slide === SlideType.left ? style.toastLeft : undefined,
        animation.slide === SlideType.right ? style.toastRight : undefined,
        animation.position === PositionType.end &&
          animation.slide === SlideType.right
          ? style.toastRightEnd
          : undefined,
        animation.position === PositionType.end &&
          animation.slide === SlideType.left
          ? style.toastLeftEnd
          : undefined,
        animation.position === PositionType.center &&
          animation.slide === SlideType.left
          ? style.toastLeftCenter
          : undefined,
        animation.position === PositionType.center &&
          animation.slide === SlideType.right
          ? style.toastRightCenter
          : undefined,
        animation.position === PositionType.center &&
          animation.slide === SlideType.top
          ? style.toastTopCenter
          : undefined
      )}
    >
      <div className={style.toastWrapper}>
        {list.map((toast, i) => {
          return (
            <div
              key={i}
              className={classNames(
                style.toast,
                animation.position === PositionType.center &&
                  animation.slide === SlideType.bottom
                  ? style.toastBottomCenter
                  : undefined
              )}
              style={{
                transform:
                  animation.position === PositionType.center &&
                  animation.slide === SlideType.bottom
                    ? `translateY(${
                        -i * TOAST_HEIGHT - TOAST_PADDING * (i - 1)
                      }px)`
                    : `translateY(${(-i * TOAST_PADDING) / 2}px)`,
              }}
            >
              {toast.type === ToastTypes.error && (
                <div
                  className={classNames(style.iconContainer, style.errorIcon)}
                >
                  <Icon name={'Error'} />
                </div>
              )}
              {toast.type === ToastTypes.success && (
                <div
                  className={classNames(style.iconContainer, style.successIcon)}
                >
                  <Icon name={'Success'} />
                </div>
              )}
              <Typography type={TypographyTypes.body}>
                {toast.textId ? (
                  <FormattedMessage id={toast.textId} />
                ) : (
                  toast.text
                )}
              </Typography>
              {toast.dismissible && (
                <Icon name={'Close'} onClick={toast.onClick} />
              )}
              {toast.action && (
                <div className={style.toastActionButton}>
                  <hr />
                  <Button
                    onClick={toast.action.onClick}
                    text={toast.action.text}
                    tertiary
                    newButton
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Toast
