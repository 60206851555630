import React, { ReactElement } from 'react';
import { RadarReportResponse } from "../../services/api/elearningapi";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { convertIntMetacogToString } from '../../helpers/metacog';

type chartEntry = {
  subject: string
  score: number
  fullMark: number
}

interface ThemegebietChartProps {
  radarReportData: RadarReportResponse
}

const getThemeNumber = (theme: string) => {
  return theme.split(' ')[1]
}

const prepareChartData = (radarReportData: RadarReportResponse) => {
  const themes: Map<string, chartEntry> = new Map<string, chartEntry>()
  radarReportData.themes.forEach((t) => {
    themes.set(t.theme, { subject: getThemeNumber(t?.alias), score: 0, fullMark: 4 })
  });

  if (radarReportData.report !== null) {
    radarReportData.report.forEach((entry) => {
      if (themes.has(entry.theme)) {
        const t = themes.get(entry.theme) as chartEntry;
        // Add one to match the chart expectation where 0 is No data. In DB and anywhere else 0 is the lowest value.
        t.score = Number(entry.metacog + 1)
      }
    });
  }

  return Array.from(themes.values())
}

const ThemegebietChart: React.FunctionComponent<
  ThemegebietChartProps
> = ({ radarReportData }): ReactElement => {
  const data = React.useMemo(() => {
    return prepareChartData(radarReportData);
  }, [radarReportData]);

  const tooltipFormatter = React.useCallback((_value: unknown, _name: unknown, props: any) => {
    const { payload } = props
    const t = radarReportData?.themes[payload.subject - 1];
    return [convertIntMetacogToString(payload?.score), t?.theme]

  }, [radarReportData]);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        width={500}
        height={600}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="subject" />
        <YAxis
          width={100}
          domain={[0, 4]}
          type="number"
          tickFormatter={(v) => convertIntMetacogToString(v)}
        />
        <Tooltip formatter={tooltipFormatter} />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} payload={[{ value: 'Themegebiet', type: 'line', id: 'ID01' }]} />
        <ReferenceLine y={0} stroke="#000" />
        <Brush dataKey="subject" height={30} stroke="#04aff9" />
        <Bar dataKey="score" fill="#04aff9" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ThemegebietChart
