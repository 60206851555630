// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__simRigGridWrapper--PPZTN {
  height: calc(100% - 5rem);
}
.style__pointer--ApCZT {
  cursor: pointer;
}
.style__overview--YNPjW {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #fff;
}
.style__overviewWithoutHeader--cfzHN {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../components/src/SimulatorRigsOverview/style.less"],"names":[],"mappings":"AAGA;EAEI,yBAAA;AAHJ;AACA;EAMI,eAAA;AAJJ;AAFA;EAUI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AALJ;AATA;EAkBI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AANJ","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .simRigGridWrapper {\n    height: calc(100% - 5rem);\n  }\n\n  .pointer {\n    cursor: pointer;\n  }\n\n  .overview {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n    background: @color-white;\n  }\n\n  .overviewWithoutHeader {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    margin-top: @spacing-100;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simRigGridWrapper": `style__simRigGridWrapper--PPZTN`,
	"pointer": `style__pointer--ApCZT`,
	"overview": `style__overview--YNPjW`,
	"overviewWithoutHeader": `style__overviewWithoutHeader--cfzHN`
};
export default ___CSS_LOADER_EXPORT___;
