import { useState, useEffect } from 'react'

export function useTabIndex(tabsMapping: Record<number, string>, tab?: string) {
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    const tabIndex =
      Object.keys(tabsMapping)
        .map(Number)
        .find((key: number) => tabsMapping[key] === tab) || 0
    tab && setCurrentTab(tabIndex)
  }, [tabsMapping, tab])

  return currentTab
}
