// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__container--_uvnS {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.style__skill--Iii2A {
  width: 3rem;
  height: 3rem;
  position: relative;
}
.style__outer--oE8rx {
  box-sizing: content-box;
  background-color: #4ed4ff;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  padding: 0.25rem;
}
.style__inner--FOFNs {
  background-color: #fff;
  box-sizing: initial;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style__circle--OPpwE {
  fill: none;
  stroke: #4ed4ff;
  stroke-width: 0.25rem;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  animation: style__anim--fMTmQ 1s linear infinite;
}
@keyframes style__anim--fMTmQ {
  from {
    stroke-dashoffset: 130;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.style__svg--Azq1k {
  position: absolute;
  top: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./../components/src/ProgressBar/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAHJ;AAHA;EAUI,WAAA;EACA,YAAA;EACA,kBAAA;AAJJ;AARA;EAgBI,uBAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AALJ;AAhBA;EAyBI,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AANJ;AA1BA;EAoCI,UAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;EACA,sBAAA;EACA,gDAAA;AAPJ;AAUE;EACE;IACE,sBAAA;EARJ;EAUE;IACE,oBAAA;EARJ;AACF;AA1CA;EAsDI,kBAAA;EACA,MAAA;EACA,OAAA;AATJ","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  .skill {\n    width: @spacing-300;\n    height: @spacing-300;\n    position: relative;\n  }\n\n  .outer {\n    box-sizing: content-box;\n    background-color: @color-light-blue-080;\n    height: 36px;\n    width: 36px;\n    border-radius: 50%;\n    padding: @spacing-25;\n  }\n\n  .inner {\n    background-color: @color-white;\n    box-sizing: initial;\n    height: 36px;\n    width: 36px;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .circle {\n    fill: none;\n    stroke: @color-light-blue-080;\n    stroke-width: @spacing-25;\n    stroke-dasharray: 130;\n    stroke-dashoffset: 130;\n    animation: anim 1s linear infinite;\n  }\n\n  @keyframes anim {\n    from {\n      stroke-dashoffset: 130;\n    }\n    to {\n      stroke-dashoffset: 0;\n    }\n  }\n\n  .svg {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style__container--_uvnS`,
	"skill": `style__skill--Iii2A`,
	"outer": `style__outer--oE8rx`,
	"inner": `style__inner--FOFNs`,
	"circle": `style__circle--OPpwE`,
	"anim": `style__anim--fMTmQ`,
	"svg": `style__svg--Azq1k`
};
export default ___CSS_LOADER_EXPORT___;
