import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react'

import {
  getPartners,
  createPartner,
  Partner,
  updatePartner,
  SimRigsData,
} from '@yaak/components/services/api/api'
import appStyle from '../../style.less'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Button from '@yaak/components/src/Button'
import SearchBar from '@yaak/components/src/SearchBar/SearchBar'
import Icon from '@yaak/components/src/Icon'
import FormDialog from '@yaak/components/src/FormDialog/FormDialog'
import style from './style.less'
import { toastType } from '@yaak/components/src/Toast/Toast'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import Grid from '@yaak/components/src/Grid'
import { transpose } from '../../helpers/common'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import PartnerEditDialog, {
  partnerLanguageDialogInput,
  partnerNameDialogInput,
  partnerSimEnabled,
} from './dialogs/PartnerEditDialog/PartnerEditDialog'
import Empty from '@yaak/components/src/Empty'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import {
  GridActiveElement,
  GridCopyActionData,
  GridCopyIconElement,
  GridEditIconElement,
  GridMainContactElement,
  GridPartnerElement,
  GridSuccessCancelIconElement,
} from '@yaak/components/src/Grid/GridElements'
import { useAuth0 } from '@auth0/auth0-react'
import { isSimAdmin } from '../../utils/simAdmin'

const HEADERS = ['Name', 'Main contact', 'Sim', 'Status', '', '']

const sortValue = ['name']

const toPartnerDetailEntries = (result: any) => [
  { icon: <Icon name={'Partner'} />, value: result.name },
  {
    icon: <Icon name={'Language'} />,
    value: result.language === 'en' ? 'English' : 'German',
  },
]

interface PartnerActions {
  edit: (data: Partner | SimRigsData) => void
}

const getRows = (
  partners: Partner[],
  actions: PartnerActions,
  navigate: NavigateFunction,
  matches: boolean,
  setShowToast: Dispatch<SetStateAction<toastType | null>>
) =>
  partners?.map((partner) => {
    const row = []
    row.push(
      GridPartnerElement({
        partner,
        ellipsis: matches,
        navigate,
      })
    )
    row.push(
      GridMainContactElement({
        partner,
        navigate,
      })
    )
    row.push(
      GridSuccessCancelIconElement({
        condition: partner.simEnabled,
        positive: 'Sim-enabled',
        negative: 'Not sim-enabled',
      })
    )
    row.push(
      GridActiveElement({
        condition: true,
      })
    )
    row.push(
      GridCopyIconElement({ data: partner, name: 'partner', setShowToast })
    )
    row.push(
      GridEditIconElement({
        data: partner,
        name: 'partner',
        editAction: actions.edit,
      })
    )
    return row
  })

const getMappedPartners = ({
  partners,
  actions,
  navigate,
  matches,
  setShowToast,
}: {
  partners: Partner[]
  actions: PartnerActions
  navigate: NavigateFunction
  matches: boolean
  setShowToast: Dispatch<SetStateAction<toastType | null>>
}) => {
  const columns = transpose(
    getRows(partners, actions, navigate, matches, setShowToast)
  )
  columns.map((row: any, i: number) =>
    HEADERS.map((header, k) => i === k && row.unshift(header))
  )
  return {
    options: {
      fixedColumns: 2,
    },
    rows: columns,
  }
}

interface PartnerOverviewProps {
  token: string
}

const PartnerOverview: React.FunctionComponent<PartnerOverviewProps> = ({
  token,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [creating, setCreating] = useState(false)
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null)
  const [partners, setPartners] = useState<Partner[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()

  const { user } = useAuth0()
  const simAdmin = isSimAdmin(user)

  const navigate = useNavigate()
  const smallScreenMatches = useSmallScreenMatches()

  const editPartner = useCallback((partner: GridCopyActionData) => {
    setSelectedPartner(partner as Partner)
  }, [])

  const mappedData = useMemo(() => {
    return getMappedPartners({
      partners,
      actions: {
        edit: editPartner,
      },
      navigate,
      matches: smallScreenMatches,
      setShowToast,
    })
  }, [partners])

  const onCreatePartner = async (partner: any) => {
    if (partner) {
      setCreating(false)
      const createdPartner = await createPartner({
        token,
        partner,
        onAlert: setShowToast,
      })
      createdPartner && setPartners(partners.concat(createdPartner))
    }
  }

  useEffect(() => {
    const fetchPartners = async () => {
      const partners = await getPartners({
        token,
        onAlert: setShowToast,
        searchQuery,
        sortOrder,
        sortBy,
      })
      setLoading(false)
      setPartners(partners)
    }

    if (searchQuery !== null && token != null) {
      const timeout = setTimeout(() => {
        token && fetchPartners()
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [token, searchQuery, sortOrder, sortBy])

  const onRowClick = useCallback(
    (rowIndex: number) => {
      navigate(`/partners/${partners[rowIndex].id}/drives`)
    },
    [partners]
  )

  const savePartnerChanges = async (partner: Partner) => {
    if (selectedPartner === null) return

    const partnerToUpdate = {
      ...selectedPartner,
      ...partner,
    }

    setSelectedPartner(null)

    const updatedPartner = await updatePartner({
      token,
      partner: {
        id: partnerToUpdate.id,
        name: partnerToUpdate.name,
        language: partnerToUpdate.language,
        mainContact: null,
        mainContactId: partnerToUpdate.mainContactId,
        simEnabled: partnerToUpdate.simEnabled,
      },
      onAlert: setShowToast,
    })
    updatedPartner &&
      setPartners(
        partners.map((p) => (p.id === partnerToUpdate.id ? updatedPartner : p))
      )
  }

  return (
    <div className={appStyle.page}>
      <Typography type={TypographyTypes.headline}>{'Partners'}</Typography>
      <Divider />
      {loading ? (
        <ProgressBar />
      ) : (
        <>
          <div className={appStyle.actionBar}>
            <div className={style.searchBox}>
              <SearchBar
                setSearchQuery={setSearchQuery}
                placeholder="Search by partner names and IDs"
                clear={true}
              ></SearchBar>
            </div>
            <div className={style.actionBarButtonBox}>
              <Button
                onClick={() => setCreating(true)}
                text={'Add partner'}
              ></Button>
            </div>
          </div>
          {partners?.length > 0 ? (
            <div className={style.partnerPageTableWrapper}>
              <Grid
                headers={HEADERS}
                data={mappedData}
                onRowClick={onRowClick}
                headerSort={['Name']}
                onHeaderClicked={(props) => {
                  setSortBy(sortValue[props.index])
                  setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
                }}
              />
            </div>
          ) : (
            partners?.length === 0 &&
            (searchQuery ? (
              <Empty
                header={'Looks like no partners match your search criteria.'}
                content={'Try changing your search to see more results.'}
              />
            ) : (
              <Empty
                header={'Whoops! Looks like there are no partners yet.'}
                content={
                  'Start adding partners using the ‘Add partner’ button above.'
                }
              />
            ))
          )}
        </>
      )}
      <FormDialog
        titleIcon={<Icon name={'Partner'} size={IconSizes.large} />}
        title={'Add partner'}
        inputs={() =>
          Promise.resolve([
            partnerNameDialogInput,
            partnerLanguageDialogInput,
            {
              ...partnerSimEnabled,
              disabled: simAdmin,
            },
          ])
        }
        open={creating}
        confirmationTitle={'Confirm details'}
        toDetailEntries={toPartnerDetailEntries}
        onCancel={() => setCreating(false)}
        onConfirmed={onCreatePartner}
      />
      <PartnerEditDialog
        simAdmin={simAdmin}
        token={token}
        selectedPartner={selectedPartner}
        onCancel={() => setSelectedPartner(null)}
        onConfirmed={savePartnerChanges}
      />
    </div>
  )
}

export default PartnerOverview
