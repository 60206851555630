import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import style from './style.less'
import classNames from 'classnames'
import Icon from '@yaak/components/src/Icon'

export interface SearchBarProps {
  placeholder?: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  clear?: boolean
  fullWidth?: boolean
}

const SearchBar = ({
  setSearchQuery,
  placeholder,
  clear,
  fullWidth,
}: SearchBarProps) => {
  const [query, setQuery] = useState('')
  const [placeholderText, setPlaceholderText] = useState(placeholder)

  useEffect(() => {
    setPlaceholderText(placeholder)
  }, [placeholder])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement
    const value = element.value
    setQuery(value)
    setSearchQuery(value.trim())
  }
  return (
    <div
      className={classNames(
        style.searchBar,
        fullWidth ? style.searchBarFull : undefined
      )}
    >
      <Icon name={'Search'} styles={{ fontSize: '1.25rem' }} />
      <input
        id={'searchInput'}
        value={query}
        placeholder={placeholderText}
        onChange={handleChange}
        onClick={() => setPlaceholderText('')}
        onBlur={() => setPlaceholderText(placeholder)}
      />
      {clear && query && (
        <Icon
          styles={{ fontSize: '1.25rem' }}
          name={'Close'}
          onClick={() => {
            setQuery('')
            setSearchQuery('')
          }}
          className={style.clearIcon}
        />
      )}
    </div>
  )
}

export default SearchBar
