import React from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '../Typography/Typography'
import classNames from 'classnames'

export const BadgeType = {
  yellow: 'yellow',
  grey: 'grey',
  green: 'green',
  red: 'red',
  blue: 'blue',
}

export interface BadgeProps {
  label: string | React.ReactNode
  type?: string
  icon?: string | React.ReactNode
  withDot?: boolean
  svg?: boolean
}

const Badge: React.FunctionComponent<BadgeProps> = ({
  label,
  type = BadgeType.green,
  withDot = false,
  svg = false,
  icon,
}) => (
  <div
    aria-label="badge"
    className={classNames(
      style.badge,
      svg ? style.svg : undefined,
      type === BadgeType.green ? style.badgeGreen : undefined,
      type === BadgeType.grey ? style.badgeGrey : undefined,
      type === BadgeType.red ? style.badgeRed : undefined,
      type === BadgeType.yellow ? style.badgeYellow : undefined,
      type === BadgeType.blue ? style.badgeBlue : undefined
    )}
  >
    {withDot && <span className={style.dot}></span>}
    {typeof icon === 'string' ? <img src={icon} alt={'icon'} /> : icon}
    <Typography type={TypographyTypes.label}>{label}</Typography>
  </div>
)

export default Badge
