// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__simulatorRigsContainer--S8tnR {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.style__simRigsOverviewContainer--C92PW {
  height: 100%;
  overflow: hidden;
}
.style__container--cO6Tz {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.style__header--sNbIy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.style__headerInfo--ltgZY {
  display: flex;
  gap: 1rem;
  align-items: center;
  align-self: stretch;
}
.style__tags--yChBv {
  display: flex;
  gap: 1rem;
  align-items: center;
  align-self: stretch;
}
.style__icon--gzpaJ {
  color: #7d8287;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SimulatorRigsPage/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;AAHJ;AAFA;EASI,YAAA;EACA,gBAAA;AAJJ;AANA;EAcI,aAAA;EACA,sBAAA;EACA,WAAA;AALJ;AAXA;EAoBI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AANJ;AAjBA;EA2BI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AAPJ;AAvBA;EAkCI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AARJ;AA7BA;EAyCI,cAAA;EACA,eAAA;AATJ","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n\n:local {\n  .simulatorRigsContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 100%;\n  }\n\n  .simRigsOverviewContainer {\n    height: 100%;\n    overflow: hidden;\n  }\n\n  .container {\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-150;\n  }\n\n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n  }\n\n  .headerInfo {\n    display: flex;\n    gap: @spacing-100;\n    align-items: center;\n    align-self: stretch;\n  }\n\n  .tags {\n    display: flex;\n    gap: @spacing-100;\n    align-items: center;\n    align-self: stretch;\n  }\n\n  .icon {\n    color: @color-asphalt-grey-050;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simulatorRigsContainer": `style__simulatorRigsContainer--S8tnR`,
	"simRigsOverviewContainer": `style__simRigsOverviewContainer--C92PW`,
	"container": `style__container--cO6Tz`,
	"header": `style__header--sNbIy`,
	"headerInfo": `style__headerInfo--ltgZY`,
	"tags": `style__tags--yChBv`,
	"icon": `style__icon--gzpaJ`
};
export default ___CSS_LOADER_EXPORT___;
