// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__divider--Hre16 {
  background-color: #d7d9da;
  height: 0.125rem;
  border: 0;
  width: 100%;
}
.style__small--KhmVh {
  height: 1px;
  background: #d7d9da;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../components/src/Divider/style.less"],"names":[],"mappings":"AAGA;EAEI,yBAAA;EACA,gBAAA;EACA,SAAA;EACA,WAAA;AAHJ;AAFA;EASI,WAAA;EACA,mBAAA;EACA,WAAA;AAJJ","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .divider {\n    background-color: @color-asphalt-grey-085;\n    height: @spacing-15;\n    border: 0;\n    width: 100%;\n  }\n\n  .small {\n    height: 1px;\n    background: @color-asphalt-grey-085;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `style__divider--Hre16`,
	"small": `style__small--KhmVh`
};
export default ___CSS_LOADER_EXPORT___;
