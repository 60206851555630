import React, { ReactElement, useState } from 'react'
import { ExamResult, LessonTopic, LessonTopics, User, UserStats, } from '@yaak/components/services/api/api'
import style from './style.less'
import Typography from '../Typography'
import { TypographyStyles, TypographyTypes } from '../Typography/Typography'
import Card from '../Card'
import { toHoursAndMinutes } from '@yaak/ds-admin/src/helpers/helpers'
import Divider from '../Divider'
import Badge from '../Badge'
import { BadgeType } from '../Badge/Badge'
import Icon from '../Icon'
import Progression from '../Progression'
import { FormattedMessage } from 'react-intl'
import ThemegebietChart from "./ThemegebietChart";
import TimeseriesChart from "./TimeseriesChart";
import { RadarReportResponse } from "../../services/api/elearningapi";
import { Alert } from "@mui/material";

export interface StudentsPerformanceProps {
  user: User
  token: string
  userStats: UserStats
  lessonTopics: LessonTopics
  radarReportData: RadarReportResponse
  dsAdmin?: boolean
}

interface ExamResultProps {
  examResult: ExamResult
  dsAdmin?: boolean
}

interface TopicRowProps {
  category?: string
  title: string | React.ReactNode
  topic: any
  lessonTopics?: LessonTopic[]
}

const getMandatoryDrivingMinutes = (
  category: string,
  lessonTopics: LessonTopic[]
) =>
  lessonTopics.reduce(
    (pre, curr) =>
      curr.category === category ? pre + curr.mandatoryDrivingMinutes : pre,
    0
  )


const TopicRow: React.FunctionComponent<TopicRowProps> = ({
  lessonTopics,
  title,
  topic,
  category,
}): ReactElement => {
  const percentage =
    lessonTopics && category
      ? (topic?.durationMinutes /
        getMandatoryDrivingMinutes(category, lessonTopics)) *
      100
      : 0
  const rating = topic?.rating?.toFixed(1)
  return (

    <div className={style.topicContainer}>
      <div className={style.topic}>
        <Typography type={TypographyTypes.body}>{title}</Typography>
        {topic && (
          <div className={style.topicData}>
            <Badge
              type={BadgeType.grey}
              label={toHoursAndMinutes(topic?.durationMinutes) || ''}
              icon={<Icon name={'Time'} />}
            ></Badge>
            {topic?.rating && (
              <Badge
                label={rating}
                icon={<Icon name={'Star'} />}
                type={
                  rating >= 4
                    ? BadgeType.green
                    : rating >= 3 && rating < 4
                      ? BadgeType.yellow
                      : BadgeType.red
                }
              ></Badge>
            )}
          </div>
        )}
      </div>
      {percentage !== undefined && (
        <Progression percent={percentage > 100 ? 100 : percentage} />
      )}
    </div>
  )
}

const ExamResultComponent: React.FunctionComponent<ExamResultProps> = ({
  examResult,
  dsAdmin = false,
}): ReactElement => {
  const parsedDate = new Date(Date.parse(examResult.timestamp))
  return (
    <div className={style.ratingContainer}>
      <Typography type={TypographyTypes.body}>
        {parsedDate.toLocaleDateString('en', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </Typography>
      {examResult.passed ? (
        <Badge
          label={
            dsAdmin ? (
              <FormattedMessage id={'add_exam_dialog.passed'} />
            ) : (
              'Passed'
            )
          }
        />
      ) : (
        <Badge
          type={BadgeType.red}
          label={
            dsAdmin ? (
              <FormattedMessage id={'add_exam_dialog.failed'} />
            ) : (
              'Failed'
            )
          }
        />
      )}
    </div>
  )
}

const StudentsPerformance: React.FunctionComponent<
  StudentsPerformanceProps
> = ({ user, token, userStats, lessonTopics, radarReportData, dsAdmin = false }): ReactElement => {
  const [theme, setTheme] = useState("")
  const onClickTheme = (theme: string) => {
    setTheme(theme)
  }


  return (
    <div className={style.performance}>
      <Typography type={TypographyTypes.label} styles={TypographyStyles.bold}>
        {dsAdmin ? (
          <FormattedMessage id={'student_performance'} />
        ) : (
          'Student performance'
        )}
      </Typography>
      <div className={style.cards}>
        <Card
          number={user?.rating?.toFixed(1)}
          label={dsAdmin ? <FormattedMessage id={'score'} /> : 'Score'}
          size={'small'}
        />
        <Card
          number={toHoursAndMinutes(user?.durationMinutes)}
          label={dsAdmin ? <FormattedMessage id={'hours'} /> : 'Hours'}
          size={'small'}
        />
      </div>
      <Divider small={true} />
      <Typography className={style.label} type={TypographyTypes.label}>
        {dsAdmin ? (
          <FormattedMessage id={'topic_progress'} />
        ) : (
          'Topic progress'
        )}
      </Typography>
      <div className={style.smallBox}>
        <TopicRow
          title={
            dsAdmin ? (
              <FormattedMessage id={'lesson_category_basic'} />
            ) : (
              'Basic'
            )
          }
          topic={userStats?.lessonTopicCategoryRatings?.BASIC}
        />
        <Divider small={true} />
        <TopicRow
          title={
            dsAdmin ? (
              <FormattedMessage id={'lesson_category_intermediate'} />
            ) : (
              'Intermediate'
            )
          }
          topic={userStats?.lessonTopicCategoryRatings?.INTERMEDIATE}
        />
        <Divider small={true} />
        <TopicRow
          title={
            dsAdmin ? (
              <FormattedMessage id={'lesson_category_advanced'} />
            ) : (
              'Advanced'
            )
          }
          topic={userStats?.lessonTopicCategoryRatings?.ADVANCED}
          lessonTopics={lessonTopics?.data}
          category={'ADVANCED'}
        />
        <Divider small={true} />
        <TopicRow
          title={
            dsAdmin ? (
              <FormattedMessage id={'lesson_category_other'} />
            ) : (
              'Stick shift'
            )
          }
          topic={userStats?.lessonTopicCategoryRatings?.OTHER}
          lessonTopics={lessonTopics?.data}
          category={'OTHER'}
        />
      </div>

      {user.examResults && user.examResults.length > 0 && (
        <>
          <Divider small={true} />
          <Typography className={style.label} type={TypographyTypes.label}>
            {dsAdmin ? (
              <FormattedMessage id={'exam_results'} />
            ) : (
              'Exam results'
            )}
          </Typography>
          <div className={style.smallBox}>
            {user.examResults.map((examResult) => (
              <ExamResultComponent
                examResult={examResult}
                dsAdmin={dsAdmin}
                key={examResult.timestamp}
              />
            ))}
          </div>
        </>
      )}

      <Divider small={true} />

      <Typography className={style.label} type={TypographyTypes.label}>
        {dsAdmin ? (
          <FormattedMessage id={'theory_progress'} />
        ) : (
          'Theory progress'
        )}
      </Typography>
      {(radarReportData !== undefined) &&
        <div className={style.smallBox}>
          <Alert style={{ width: "100%", maxWidth: "none" }} severity={"info"}><FormattedMessage id={'bar_chart_instructions_hover'} /></Alert>
          <ThemegebietChart radarReportData={radarReportData} />
          <Alert style={{ width: "100%", maxWidth: "none", marginTop: "20px" }} severity={"info"}><FormattedMessage id={'bar_chart_instructions_slide'} /></Alert>
          <TimeseriesChart userId={user.id} token={token} themes={radarReportData.themes} theme={theme} />
        </div>
      }

    </div>
  )
}

export default StudentsPerformance
