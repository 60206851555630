import React from 'react'
import Typography from '../Typography'
import { TypographyTypes } from '../Typography/Typography'
import classNames from 'classnames'
import style from './style.less'

interface GridCellTextProps {
  column: any
}

const GridCellText = ({ column }: GridCellTextProps) => {
  return (
    <Typography
      type={TypographyTypes.body}
      className={classNames(
        column.hidden ? style.hidden : undefined,
        column.ellipsis ? style.ellipsis : undefined,
        column.tooltip && !column.icon ? style.ellipsisTooltip : undefined,
        column.click ? style.click : undefined
      )}
      onClick={column.click}
    >
      {column.text || column}
    </Typography>
  )
}

export default GridCellText
