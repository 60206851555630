import React, { ReactElement } from 'react'
import UserOverview from '../UserOverview'
import style from './style.less'
import { TEAM_MAIN_HEADERS } from '../../helpers/users'

interface TeamProps {
  token: string
}

const Team: React.FunctionComponent<TeamProps> = ({ token }): ReactElement => {
  return (
    <div className={style.teamTab}>
      <UserOverview
        headers={TEAM_MAIN_HEADERS}
        token={token}
        role={'synpli-admin'}
        options={{
          fixedColumns: 3,
          withHeader: false,
          yaakAdmin: true,
          withPartner: false,
          withToS: false,
          searchPlaceholder:
            'Search team member names, partners, emails, mobile numbers and IDs',
        }}
      />
    </div>
  )
}

export default Team
