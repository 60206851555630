// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__score--e6j4p {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 30px;
  line-height: 2.2rem;
  margin-right: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.style__scoreInactive--A22wp {
  color: #1aa6da;
  background-color: #4ed4ff;
}
.style__scoreHigh--PI6ly {
  background-color: #eaffe9;
  color: #00a751;
}
.style__scoreMedium--dZc0a {
  color: #e5b300;
  background-color: #fffae5;
}
.style__scoreLow--k2pE8 {
  color: #d72a1d;
  background-color: #fff3f0;
}
`, "",{"version":3,"sources":["webpack://./../ds-admin/src/components/Score/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAHJ;AARA;EAeI,cAAA;EACA,yBAAA;AAJJ;AAZA;EAoBI,yBAAA;EACA,cAAA;AALJ;AAhBA;EAyBI,cAAA;EACA,yBAAA;AANJ;AApBA;EA8BI,cAAA;EACA,yBAAA;AAPJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .score {\n    width: 2.2rem;\n    height: 2.2rem;\n    border-radius: 30px;\n    line-height: 2.2rem;\n    margin-right: @spacing-100;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: @color-black;\n  }\n\n  .scoreInactive {\n    color: @color-light-blue-060;\n    background-color: @color-light-blue-080;\n  }\n\n  .scoreHigh {\n    background-color: @color-green-099;\n    color: @color-green-060;\n  }\n\n  .scoreMedium {\n    color: @color-yellow-050;\n    background-color: @color-yellow-099;\n  }\n\n  .scoreLow {\n    color: @color-red-050;\n    background-color: @color-red-099;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"score": `style__score--e6j4p`,
	"scoreInactive": `style__scoreInactive--A22wp`,
	"scoreHigh": `style__scoreHigh--PI6ly`,
	"scoreMedium": `style__scoreMedium--dZc0a`,
	"scoreLow": `style__scoreLow--k2pE8`
};
export default ___CSS_LOADER_EXPORT___;
