import React from 'react'
import style from './style.less'
import Typography from '../Typography'
import { TypographyTypes } from '../Typography/Typography'
import Divider from '../Divider'
import Tooltip from '../Tooltip'

export interface InfoItemProps {
  key: string
  icon: React.ReactNode
  label: string
  text: string | null
  click?: () => void
  tooltip?: string
}

export interface InfoBoxProps {
  label: string
  items: InfoItemProps[]
  headerIcon?: React.ReactNode
}

const InfoItem: React.FunctionComponent<InfoItemProps> = ({
  label,
  text,
  icon,
  click,
  tooltip,
}) => (
  <div className={style.infoItem}>
    <div className={style.infoIcon}>{icon}</div>
    <div className={style.infoItemContent}>
      <div className={style.infoItemContentHeader}>
        <Typography type={TypographyTypes.label} size="small">
          {label}
        </Typography>
      </div>
      {tooltip ? (
        <Tooltip text={tooltip} position={'top'}>
          <Typography type={TypographyTypes.body} onClick={click}>
            {text}
          </Typography>
        </Tooltip>
      ) : (
        <Typography type={TypographyTypes.body} onClick={click}>
          {text}
        </Typography>
      )}
    </div>
  </div>
)

const InfoBox: React.FunctionComponent<InfoBoxProps> = ({
  items,
  label,
  headerIcon,
}) => (
  <div className={style.infoBox}>
    <div className={style.infoBoxHeader}>
      <Typography type={TypographyTypes.label}>{label}</Typography>
      {headerIcon}
    </div>
    <div className={style.infoBoxItems}>
      {items
        .map((item, index) => {
          const infoItem = (
            <InfoItem
              key={item.key}
              label={item.label}
              text={item.text}
              icon={item.icon}
              click={item.click}
              tooltip={item.tooltip}
            />
          )
          return index < items.length - 1
            ? [infoItem, <Divider key={index} small />]
            : infoItem
        })
        .flat()}
    </div>
  </div>
)

export default InfoBox
