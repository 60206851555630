import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import React from 'react'

const SIM_STATUS = {
  OFFLINE: 'OFFLINE',
  ERROR: 'ERROR',
  SIM_RUNNING: 'SIM_RUNNING',
  SIM_INACTIVE: 'SIM_INACTIVE',
  SIM_STARTING: 'SIM_STARTING',
  READY: 'READY',
  WAITING_FOR_RESULT: 'WAITING_FOR_RESULT',
  RUNNING: 'RUNNING',
  PENDING: 'PENDING',
  INACTIVE: 'INACTIVE',
}

export const getStatus = (status: string) => {
  return status === SIM_STATUS.OFFLINE ? (
    <Badge type={BadgeType.grey} label={'Offline'} withDot />
  ) : status === SIM_STATUS.ERROR ? (
    <Badge type={BadgeType.red} label={'Error'} withDot />
  ) : status === SIM_STATUS.SIM_RUNNING ? (
    <Badge type={BadgeType.blue} label={'Sim running'} withDot />
  ) : status === SIM_STATUS.SIM_INACTIVE ? (
    <Badge type={BadgeType.grey} label={'Sim inactive'} withDot />
  ) : status === SIM_STATUS.SIM_STARTING ? (
    <Badge type={BadgeType.yellow} label={'Sim starting'} withDot />
  ) : status === SIM_STATUS.READY ? (
    <Badge label={'Ready'} withDot />
  ) : status === SIM_STATUS.WAITING_FOR_RESULT ? (
    <Badge type={BadgeType.blue} label={'Waiting for result'} withDot />
  ) : status === SIM_STATUS.RUNNING ? (
    <Badge label={'Running'} withDot />
  ) : status === SIM_STATUS.INACTIVE ? (
    <Badge type={BadgeType.red} label={'Inactive'} withDot />
  ) : status === SIM_STATUS.PENDING ? (
    <Badge type={BadgeType.grey} label={'Pending'} withDot />
  ) : (
    <></>
  )
}
