import { createContext, Dispatch, SetStateAction } from 'react'
import { toastType } from '@yaak/components/src/Toast/Toast'

export interface ToastContextType {
  toast: toastType | null
  setShowToast: Dispatch<SetStateAction<toastType | null>>
}

export const ToastContext = createContext<ToastContextType | null>({
  toast: null,
  setShowToast: () => {},
})
