import React from 'react'
import style from './style.less'
import classNames from 'classnames'
import Typography, {
  TypographySize,
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '../Typography/Typography'
import Icon from '@yaak/components/src/Icon'
import Tooltip from '../Tooltip'

export interface CardProps {
  color?: string
  size?: keyof TypographySize
  number?: number | string
  label: string | React.ReactNode
  trend?: number
  increment?: number | string
  stretch?: boolean
  tooltip?: string
}

const Card: React.FunctionComponent<CardProps> = ({
  color = '#FFF',
  size = 'large',
  number,
  label,
  trend,
  increment,
  stretch,
  tooltip,
}) => (
  <div
    className={classNames(
      style.card,
      stretch ? style.cardStretch : style.cardNormal
    )}
    style={{ backgroundColor: color }}
  >
    <div className={style.flex}>
      <Typography
        type={TypographyTypes.headline}
        size={TypographySizes[size as keyof TypographySize]}
        styles={TypographyStyles.bold}
      >
        {number}
      </Typography>
      {tooltip && (
        <Tooltip text={tooltip} position={'top'}>
          <Icon name={'Help'} />
        </Tooltip>
      )}
    </div>
    <div className={style.bottom}>
      <Typography
        type={TypographyTypes.label}
        size={TypographySizes.large}
        styles={TypographyStyles.bold}
      >
        {label}
      </Typography>
      {trend !== undefined && (
        <p
          className={classNames(
            style.diff,
            style[`diff_${trend > 0 ? 'up' : trend < 0 ? 'down' : 'stable'}`]
          )}
        >
          <Icon name={'ArrowForward'} />
          {Math.abs(trend)}
        </p>
      )}
      {increment !== undefined && (
        <p className={style.diff}>
          <Icon name={'Add'} /> {increment}
        </p>
      )}
    </div>
  </div>
)

export default Card
