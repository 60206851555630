import { DESmallIcon, DKSmallIcon, ENSmallIcon } from '@yaak/components/assets'
import React from 'react'

const flagsMap: any = {
  en: ENSmallIcon,
  de: DESmallIcon,
  dk: DKSmallIcon,
}

export const getLanguageComponent = (lang?: string | null | undefined) => {
  const FlagComponent = lang ? flagsMap[lang] : flagsMap.en
  return <FlagComponent />
}

export const getLanguageFullName = (lang?: string | null | undefined) => {
  return lang === 'en' ? 'English' : lang === 'dk' ? 'Danish' : 'German'
}
