import React from 'react'
import style from './style.less'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

export interface TooltipProps {
  children: React.ReactNode
  text: any
  position?: string
  className?: string
  withArrow?: boolean
  positionX?: string
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  position = 'bottom',
  text,
  withArrow = true,
  className,
  positionX,
}) => {
  const intl = useIntl()

  return (
    <div className={classNames(className, style.tooltipContainer)}>
      {text ? (
        <abbr
          className={classNames(
            style.tooltip,
            !withArrow ? style.noArrow : undefined,
            position === 'top' ? style.tooltipTop : undefined,
            position === 'bottom' ? style.tooltipBottom : undefined,
            position === 'right' ? style.tooltipRight : undefined,
            position === 'left' ? style.tooltipLeft : undefined,
            positionX === 'left'
              ? style.tooltipLeftCorner
              : positionX === 'right'
              ? style.tooltipRightCorner
              : undefined
          )}
          data-tooltip={
            typeof text === 'string'
              ? text
              : text?.props?.id
              ? intl.formatMessage({ id: text?.props?.id })
              : text
          }
        >
          {children}
        </abbr>
      ) : (
        children
      )}
    </div>
  )
}

export default Tooltip
