import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import {
  getSimDevices,
  SimRigs,
  SimRigsData,
  updateSimRig,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Grid from '@yaak/components/src/Grid'
import Empty from '@yaak/components/src/Empty'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { toastType } from '@yaak/components/src/Toast/Toast'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'
import style from './style.less'
import { SimulatorEditDialog } from './SimulatorRigsDialogs'
import { transpose } from '../../helpers/transpose'
import {
  GridCopyIconElement,
  GridEditIconElement,
  GridNameElement,
  GridPartnerElement,
  GridTextElement,
} from '../Grid/GridElements'
import { getStatus } from '@yaak/admin/src/helpers/simulation'

const HEADERS = ['Name', 'Partner', 'Status', '', '']
const HEADERS_WITHOUT_PARTNER = ['Name', 'Status', '', '']

const sortValue = ['name', 'partnerName', 'status']

interface SimulatorRigsOverviewProps {
  partnerId?: string
  token: string
  withHeader?: boolean
  withPartner?: boolean
}

interface SimRigActions {
  edit: (simRig: SimRigsData) => void
}

interface SimRigOptions {
  withPartner: boolean
}

const getRows = (
  simRigs: SimRigsData[],
  navigate: NavigateFunction,
  matches: boolean,
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  actions: SimRigActions,
  options: SimRigOptions
) =>
  simRigs?.map((simRig) => {
    const row = []
    row.push(
      GridNameElement({
        data: simRig,
        ellipsis: matches,
        tooltip: 'View simulator rig profile',
        navigate,
      })
    )
    options.withPartner &&
      row.push(
        GridPartnerElement({
          partner: simRig.partner,
          ellipsis: matches,
          navigate,
        })
      )
    row.push(
      GridTextElement({
        text: getStatus(simRig.status),
      })
    )
    row.push(
      GridCopyIconElement({
        data: simRig,
        name: 'simulator rig',
        setShowToast,
      })
    )
    row.push(
      GridEditIconElement({
        data: simRig,
        editAction: actions.edit,
        name: 'simulator rig',
      })
    )
    return row
  })

const getMappedSimRigs = ({
  simRigs,
  navigate,
  matches,
  setShowToast,
  actions,
  options,
}: {
  simRigs: SimRigsData[]
  navigate: NavigateFunction
  matches: boolean
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  actions: SimRigActions
  options: SimRigOptions
}) => {
  const columns = transpose(
    getRows(simRigs, navigate, matches, setShowToast, actions, options)
  )
  columns.map((row: any, i: number) =>
    (options.withPartner ? HEADERS : HEADERS_WITHOUT_PARTNER).map(
      (header, k) => i === k && row.unshift(header)
    )
  )
  return {
    options: {
      fixedColumns: 2,
    },
    rows: columns,
  }
}

const SimulatorRigsOverview: React.FunctionComponent<
  SimulatorRigsOverviewProps
> = ({
  partnerId,
  token,
  withHeader = true,
  withPartner = true,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [simRigs, setSimRigs] = useState<SimRigs>()
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [simRigToEdit, setSimRigToEdit] = useState<SimRigsData | null>(null)

  const smallScreenMatches = useSmallScreenMatches()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchSimDevices = async () => {
      const simRigs = await getSimDevices({
        token,
        onAlert: setShowToast,
        sortOrder,
        sortBy,
        partnerId,
      })
      setLoading(false)
      setSimRigs(simRigs)
    }

    token && fetchSimDevices()
  }, [token, sortBy, sortOrder])

  const fetchData2 = async () => {
    const morePages =
      simRigs?.data.length &&
      simRigs?.data.length < simRigs?.metaData.totalCount

    if (morePages) {
      const newSimRigs = await getSimDevices({
        token,
        onAlert: setShowToast,
        offset: simRigs?.data.length,
        sortBy,
        sortOrder,
        partnerId,
      })
      if (newSimRigs) {
        setSimRigs({
          ...simRigs,
          data: [...simRigs.data, ...newSimRigs.data],
        })
      }
    }
  }

  useEffect(() => {
    fetchMoreData && fetchData2()
    setFetchMoreData(false)
  }, [fetchMoreData])

  const fetchData = () => {
    setFetchMoreData(true)
  }

  const editSimRig = async (simRig: SimRigsData) => {
    const simRigToUpdate = {
      ...simRigToEdit,
      ...simRig,
    }

    const updatedSimRig = await updateSimRig({
      token,
      simRig: simRigToUpdate,
      onAlert: setShowToast,
    })
    if (updatedSimRig) {
      setSimRigToEdit(null)
      simRigs &&
        setSimRigs({
          ...simRigs,
          data: simRigs.data.map((simRig) =>
            simRig.id === updatedSimRig.id ? updatedSimRig : simRig
          ),
        })
    }
  }

  return (
    <div className={withHeader ? style.overview : style.overviewWithoutHeader}>
      {withHeader && (
        <>
          <Typography type={TypographyTypes.headline}>
            {'Simulator rigs'}
          </Typography>
          <Divider />
        </>
      )}
      {loading ? (
        <ProgressBar />
      ) : simRigs && simRigs.data.length > 0 ? (
        <div className={style.simRigGridWrapper}>
          <Grid
            headers={withPartner ? HEADERS : HEADERS_WITHOUT_PARTNER}
            data={getMappedSimRigs({
              simRigs: simRigs.data,
              navigate,
              matches: smallScreenMatches,
              setShowToast,
              actions: {
                edit: (simRig) => setSimRigToEdit(simRig),
              },
              options: {
                withPartner,
              },
            })}
            fetchData={fetchData}
            onRowClick={() => {}}
            headerSort={[]}
            onHeaderClicked={(props) => {
              setSortBy(sortValue[props.index])
              setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
            }}
          ></Grid>
        </div>
      ) : (
        simRigs?.data.length === 0 && (
          <Empty
            header={'Whoops! Looks like there are no simulator rigs yet.'}
            content={''}
          />
        )
      )}
      <SimulatorEditDialog
        token={token}
        simRig={simRigToEdit}
        isOpen={simRigToEdit !== null}
        onClose={() => setSimRigToEdit(null)}
        onCreate={editSimRig}
      />
    </div>
  )
}

export default SimulatorRigsOverview
