import React, { ReactElement } from 'react'
import appStyle from '../../style.less'
import DrivesOverview from '@yaak/components/src/DrivesOverview'

interface DrivesPageProps {
  token: string
}

const DrivesPage: React.FunctionComponent<DrivesPageProps> = ({
  token,
}): ReactElement => (
  <div className={appStyle.page}>
    <DrivesOverview token={token} />
  </div>
)

export default DrivesPage
