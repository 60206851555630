import React from 'react'
import style from './style.less'
import classNames from 'classnames'

interface DividerProps {
  className?: string
  small?: boolean
}

const Divider: React.FunctionComponent<DividerProps> = ({
  className,
  small,
}) => (
  <hr
    className={classNames(
      style.divider,
      className,
      small ? style.small : undefined
    )}
  />
)

export default Divider
