import React, { ReactElement } from 'react'
import appStyle from '../../style.less'
import SimulatorRigsOverview from '@yaak/components/src/SimulatorRigsOverview'

interface SimulatorRigsProps {
  token: string
}
const SimulatorRigs: React.FunctionComponent<SimulatorRigsProps> = ({
  token,
}): ReactElement => (
  <div className={appStyle.page}>
    <SimulatorRigsOverview token={token} />
  </div>
)

export default SimulatorRigs
