import React from 'react'
import style from './style.less'
import classNames from 'classnames'
import Typography from '../Typography'
import { TypographyTypes } from '../Typography/Typography'

export interface PopoverProps {
  header?: string | React.ReactNode
  content: React.ReactNode
  position?: string
  className?: string
  withArrow?: boolean
  open: boolean
}

const Popover: React.FunctionComponent<PopoverProps> = ({
  content,
  header,
  position = 'bottom',
  withArrow = true,
  className,
  open = false,
}) => {
  return open ? (
    <div
      className={classNames(
        className,
        style.popover,
        position === 'top' ? style.popoverTop : undefined,
        position === 'bottom' ? style.popoverBottom : undefined,
        position === 'left' ? style.popoverLeft : undefined,
        !withArrow ? style.noArrow : undefined
      )}
    >
      {header && (
        <Typography className={style.popoverTitle} type={TypographyTypes.label}>
          {header}
        </Typography>
      )}
      <div>{content}</div>
    </div>
  ) : (
    <></>
  )
}

export default Popover
