import React from 'react'
import style from './style.less'
import classNames from 'classnames'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'

interface ScoreProps {
  active?: boolean
  score: number
}

const Score: React.FunctionComponent<ScoreProps> = ({
  active = true,
  score,
}) => {
  const cellDiv = (className: string, text: string) => (
    <div className={classNames(style.score, className)}>
      <Typography type={TypographyTypes.label}>{text}</Typography>
    </div>
  )

  if (score == null) return cellDiv(style.scoreInactive, '—')

  const fixedScore = score.toFixed(1).toString()
  if (!active) return cellDiv(style.scoreInactive, fixedScore)
  if (score >= 4) return cellDiv(style.scoreHigh, fixedScore)
  if (score >= 3) return cellDiv(style.scoreMedium, fixedScore)
  return cellDiv(style.scoreLow, fixedScore)
}

export default Score
