import React, { memo } from 'react'
import style from './style.less'
import classNames from 'classnames'

export interface AvatarNamePlateProps {
  name: string
  gravatarURL: string
  click?: () => void
}

const AvatarNamePlate: React.FunctionComponent<AvatarNamePlateProps> = ({
  gravatarURL,
  name,
  click,
}) => {
  return (
    <div
      className={classNames(
        style.namePlate,
        click ? style.namePlateHover : undefined
      )}
    >
      <img src={gravatarURL} alt={'gravatar'} />
      <div className={style.ellipsis} onClick={click}>
        {name}
      </div>
    </div>
  )
}

export default memo(AvatarNamePlate)
