import React, {createContext, useCallback, useContext, useState,} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {getFeatureFlags as apiGetFeatureFlags} from "../../services/api/api";

export type FeatureFlags = {
  [flag: string]: boolean
};

interface FeatureFlagsContextValue {
  flags: FeatureFlags
  getFeatureFlag: (flagName: string) => Promise<boolean>
  getFeatureFlags: () => Promise<FeatureFlags>
}

interface StoredFlagsData {
  flags: FeatureFlags
  timestamp: number
}

const TTL_SECONDS = parseInt(process.env.FEATURE_FLAG_CACHE_TTL || "600");


const FeatureFlagsContext = createContext<FeatureFlagsContextValue>({
  flags: {},
  getFeatureFlag: async () => {
    throw new Error("FeatureFlagProvider not initialized");
  },
  getFeatureFlags: async () => {
    throw new Error("FeatureFlagProvider not initialized");
  }
});

/**
 * This is a provider that fetches and caches feature flags from the API.
 * There are two usages: through `flags` or `getFeatureFlag`.
 * E.g.: `const { flags, getFeatureFlag } = useFeatureFlags();`
 *
 * `flags` is an object with all the flags fetched from the API.
 * `getFeatureFlag` is a function that checks if the local cache has the flag. If it doesn't,
 * then it goes to the API and update the flags.
 * this is done so, to avoid having to ask users to clear their cache when a new flag is added.
 *
 * All in all, `getFeatureFlag` should always be the one to be used as it's most up-to-date value and reliable.
 * @param children
 * @constructor
 */
export function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();

  const [flags, setFlags] = useState<FeatureFlags>({});

  const getFeatureFlagsFromLocalStorage = () => {
    return localStorage.getItem("featureFlags");
  }

  const readStoredFlags = () => {
    const storedDataStr = getFeatureFlagsFromLocalStorage();
    if (storedDataStr) {
      const storedData: StoredFlagsData = JSON.parse(storedDataStr);
      const ageSeconds = (Date.now() - storedData.timestamp) / 1000;
      const isExpired = ageSeconds > TTL_SECONDS;

      // If localStorage is valid AND it has this flag, we can immediately use it
      if (!isExpired) {
        // Update in-memory so the rest of the app is in sync
        setFlags(storedData.flags);
        return storedData.flags
      }
    }
    return null;
  }

  const getFeatureFlags = useCallback(
    async (): Promise<FeatureFlags> => {
      try {
        const storedFlags = readStoredFlags();
        if (storedFlags) {
          return storedFlags;
        }
      } catch (err) {
        console.error("[FeatureFlagProvider] Error reading stored flags:", err);
        throw err
      }


      try {
        const token = await getAccessTokenSilently();
        const fetchedFlags = await apiGetFeatureFlags({ token });

        setFlags(fetchedFlags);

        const newData: StoredFlagsData = {
          flags: fetchedFlags,
          timestamp: Date.now(),
        };
        localStorage.setItem("featureFlags", JSON.stringify(newData));

        return {};
      } catch (err) {
        console.error("[FeatureFlagProvider] Error fetching new flags from API:", err);
        throw err;
      }
    },
    [getAccessTokenSilently]
  );

  const getFeatureFlag = useCallback(
    async (flagName: string): Promise<boolean> => {
      if (flagName in flags) {
        return flags[flagName];
      }

      try {
        const storedFlags = readStoredFlags();
        if (storedFlags) {
          return storedFlags[flagName];
        }
      } catch (err) {
        console.error("[FeatureFlagProvider] Error reading stored flags:", err);
        throw err
      }

      try {
        const token = await getAccessTokenSilently();
        const fetchedFlags = await apiGetFeatureFlags({ token });

        setFlags(fetchedFlags);

        const newData: StoredFlagsData = {
          flags: fetchedFlags,
          timestamp: Date.now(),
        };
        localStorage.setItem("featureFlags", JSON.stringify(newData));


        if (!(flagName in fetchedFlags)) {
          console.error(`[FeatureFlagProvider] Flag "${flagName}" not found after API fetch. Check for typos or server config.`);
          return false;
        }

        return fetchedFlags[flagName];
      } catch (err) {
        console.error("[FeatureFlagProvider] Error fetching new flags from API:", err);
        throw err;
      }
    },
    [getAccessTokenSilently]
  );

  return (
    <FeatureFlagsContext.Provider value={{ flags, getFeatureFlag, getFeatureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}
