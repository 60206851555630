// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__activeStudentsBox--tKuWn {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  border: 1px solid #e3e8e5;
  border-radius: 8px;
  color: #7d8287;
  height: min-content;
}
.style__studentsBox--dH1kt {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: #0c0d0d;
}
`, "",{"version":3,"sources":["webpack://./../components/src/ActiveStudents/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAHJ;AANA;EAaI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,cAAA;AAJJ","sourcesContent":["@import '../spacing';\n@import '../colors';\n\n:local {\n  .activeStudentsBox {\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-150;\n    padding: @spacing-100;\n    border: 1px solid #e3e8e5;\n    border-radius: 8px;\n    color: @color-asphalt-grey-050;\n    height: min-content;\n  }\n\n  .studentsBox {\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-150;\n    color: @color-asphalt-grey-005;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeStudentsBox": `style__activeStudentsBox--tKuWn`,
	"studentsBox": `style__studentsBox--dH1kt`
};
export default ___CSS_LOADER_EXPORT___;
