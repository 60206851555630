// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__switch--pYPvA {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 14px;
}
.style__switch--pYPvA input {
  opacity: 0;
  width: 0;
  height: 0;
}
.style__round--Lr_oz {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #afb2b6;
  transition: 0.4s;
}
.style__round--Lr_oz:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -0.125rem;
  bottom: -3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  transition: 0.4s;
}
input:checked + .style__round--Lr_oz:before {
  background-color: #00803e;
}
input:checked + .style__round--Lr_oz {
  background-color: #00ee77;
}
input:checked + .style__round--Lr_oz:before {
  transform: translateX(14px);
}
.style__round--Lr_oz {
  border-radius: 34px;
}
.style__round--Lr_oz:before {
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./../components/src/Switch/style.less"],"names":[],"mappings":"AAGA;EAEI,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AAHJ;AAKI;EACE,UAAA;EACA,QAAA;EACA,SAAA;AAHN;AAPA;EAeI,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,yBAAA;EACA,gBAAA;AALJ;AAOI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,wCAAA;EACA,sBAAA;EACA,gBAAA;AALN;AA5BA;EAsCI,yBAAA;AAPJ;AA/BA;EA0CI,yBAAA;AARJ;AAlCA;EA8CI,2BAAA;AATJ;AArCA;EAkDI,mBAAA;AAVJ;AAYI;EACE,kBAAA;AAVN","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .switch {\n    position: relative;\n    display: inline-block;\n    width: @spacing-200;\n    height: 14px;\n\n    & input {\n      opacity: 0;\n      width: 0;\n      height: 0;\n    }\n  }\n\n  .round {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: @color-asphalt-grey-070;\n    transition: 0.4s;\n\n    &:before {\n      position: absolute;\n      content: '';\n      height: 20px;\n      width: 20px;\n      left: -@spacing-15;\n      bottom: -3px;\n      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);\n      background-color: @color-white;\n      transition: 0.4s;\n    }\n  }\n\n  input:checked + .round:before {\n    background-color: @color-green-050;\n  }\n\n  input:checked + .round {\n    background-color: @color-green-neon;\n  }\n\n  input:checked + .round:before {\n    transform: translateX(14px);\n  }\n\n  .round {\n    border-radius: 34px;\n\n    &:before {\n      border-radius: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `style__switch--pYPvA`,
	"round": `style__round--Lr_oz`
};
export default ___CSS_LOADER_EXPORT___;
