import React, {ReactElement} from 'react';
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import {RadarReportResponse} from "../../services/api/elearningapi";
import {convertIntMetacogToString} from "../../helpers/metacog";

type chartEntry = {
  subject: string
  A: number
  fullMark: number
}

interface ThemegebietChartProps {
  radarReportData: RadarReportResponse
  onThemeChange: (theme: string) => void
}

const prepareChartData = (radarReportData: RadarReportResponse) => {
  const themes: Map<string, chartEntry> = new Map<string, chartEntry>()
  radarReportData.themes.forEach((theme) => {
    themes.set(theme, {subject: theme, A: 0, fullMark: 4})
  });

  if (radarReportData.report !== null) {
    radarReportData.report.forEach((entry) => {
      if (themes.has(entry.theme)) {
        const t = themes.get(entry.theme) as chartEntry;
        // Add one to match the chart expectation where 0 is No data. In DB and anywhere else 0 is the lowest value.
        t.A = Number(entry.metacog + 1)
      }
    });
  }

  return Array.from(themes.values())
}

// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const convertedLabel = convertIntMetacogToString(payload[0].value);

    return (
      <div style={{backgroundColor: "white", padding: "20px"}}>
        <p>{`${label} : ${convertedLabel}`}</p>
      </div>
    );
  }

  return null;
};

const ThemegebietChart: React.FunctionComponent<
  ThemegebietChartProps
> = ({radarReportData, onThemeChange}): ReactElement => {
  return (
    <ResponsiveContainer height={600} width={'100%'}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={prepareChartData(radarReportData)}>
        <PolarGrid gridType={'polygon'}/>
        <PolarAngleAxis
          dataKey="subject"
          onClick={(el) => onThemeChange(el.value)}
          tick={{cursor: 'pointer'}}
        />
        <PolarRadiusAxis domain={[0, 4]} tick={false} axisLine={false} tickFormatter={convertIntMetacogToString} />
        <Tooltip content={<CustomTooltip />} />
        <Legend/>
        <Radar name="Competency" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
      </RadarChart>
    </ResponsiveContainer>
  );
}

export default ThemegebietChart
