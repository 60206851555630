import React from 'react'
import style from './style.less'
import classNames from 'classnames'

export const TypographyTypes = {
  body: 'body',
  display: 'display',
  headline: 'headline',
  label: 'label',
  link: 'link',
  title: 'title',
}

export interface TypographySize {
  small: string
  large: string
}

export const TypographySizes = {
  small: 'small',
  large: 'large',
}

export const TypographyStyles = {
  bold: 'bold',
  light: 'light',
}

export interface TypographyProps {
  className?: string
  size?: string
  styles?: string
  children: React.ReactNode
  type: string
  url?: string
  self?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Typography: React.FunctionComponent<TypographyProps> = ({
  size,
  type,
  children,
  className,
  styles,
  url,
  self,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        className,
        style[type],
        styles === TypographyStyles.bold ? style.bold : undefined,
        styles === TypographyStyles.light ? style.light : undefined,
        size === TypographySizes.large ? style[`${type}Large`] : undefined,
        size === TypographySizes.small ? style[`${type}Small`] : undefined,
        url ? style.underscore : undefined,
        onClick ? style.action : undefined
      )}
      onClick={onClick}
    >
      {url ? <a href={url}>{children}</a> : children}
    </div>
  )
}

export default Typography
