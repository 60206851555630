import React, { ReactElement, useContext, useEffect, useState } from 'react'
import appStyle from '../../style.less'
import style from './style.less'
import {
  CreateVehicle,
  getPairingRequests,
  getPartners,
  getVehicle,
  PairingRequest,
  Partner,
  updatePairingRequest,
  updateVehicle,
  Vehicle,
} from '@yaak/components/services/api/api'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Icon from '@yaak/components/src/Icon'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import Button from '@yaak/components/src/Button'
import Divider from '@yaak/components/src/Divider'
import { EditVehicleDialog } from '../VehicleOverview/VehicleDialogs'
import WarningDialog from '@yaak/components/src/WarningDialog'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'

interface VehiclePageProps {
  token: string
}

interface InfoBoxProps {
  vehicle: Vehicle
  click?: () => void
}

const KIT_STATUS = {
  OFFLINE: 'OFFLINE',
  ERROR: 'ERROR',
  RECORDING: 'RECORDING',
  UPLOADING: 'UPLOADING',
  SIMULATION: 'SIMULATION',
  READY: 'READY',
  OTA_UPDATE: 'OTA_UPDATE',
  INITIALIZING: 'INITIALIZING',
  UPDATING: 'UPDATING',
  SHUTDOWN_PENDING: 'SHUTDOWN_PENDING',
  SHUTDOWN: 'SHUTDOWN',
}

const getStatus = (status: string) => {
  return status === KIT_STATUS.OFFLINE ? (
    <Badge type={BadgeType.grey} label={'Offline'} withDot />
  ) : status === KIT_STATUS.ERROR ? (
    <Badge type={BadgeType.red} label={'Error'} withDot />
  ) : status === KIT_STATUS.RECORDING ? (
    <Badge type={BadgeType.blue} label={'Recording'} withDot />
  ) : status === KIT_STATUS.UPLOADING ? (
    <Badge type={BadgeType.blue} label={'Uploading'} withDot />
  ) : status === KIT_STATUS.SIMULATION ? (
    <Badge type={BadgeType.yellow} label={'Simulation'} withDot />
  ) : status === KIT_STATUS.READY ? (
    <Badge label={'Ready'} withDot />
  ) : status === KIT_STATUS.OTA_UPDATE ? (
    <Badge type={BadgeType.blue} label={'OTA update'} withDot />
  ) : status === KIT_STATUS.INITIALIZING ? (
    <Badge label={'Initializing'} withDot />
  ) : status === KIT_STATUS.UPDATING ? (
    <Badge label={'Updating'} withDot />
  ) : status === KIT_STATUS.SHUTDOWN_PENDING ? (
    <Badge type={BadgeType.grey} label={'Shutdown pending'} withDot />
  ) : status === KIT_STATUS.SHUTDOWN ? (
    <Badge type={BadgeType.grey} label={'Shutdown'} withDot />
  ) : (
    <></>
  )
}

const InfoBox: React.FunctionComponent<InfoBoxProps> = ({ vehicle, click }) => (
  <div className={style.infoBox}>
    <div className={style.infoBoxHeader}>
      <Typography type={TypographyTypes.label}>Kit ID</Typography>
    </div>
    <div className={style.infoBoxItems}>
      {vehicle.kit?.id && (
        <div className={style.infoBoxItem}>
          <Icon name={'Device'} />
          <Typography type={TypographyTypes.body} onClick={click}>
            {vehicle.kit?.id}
          </Typography>
        </div>
      )}
      {vehicle.kit?.status && getStatus(vehicle.kit?.status)}
    </div>
  </div>
)

const VehiclePage: React.FunctionComponent<VehiclePageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [vehicle, setVehicle] = useState<Vehicle>()
  const [selectedVehicle, setSelectedVehicle] = useState<CreateVehicle>()
  const [partners, setPartners] = useState<Partner[]>([])
  const [unpairing, setUnpairing] = useState<Vehicle | null>(null)
  const [pairingRequest, setPairingRequest] = useState<PairingRequest>()

  const { vin } = useParams()

  const navigate = useNavigate()

  const fetchVehicle = async () => {
    const vehicle = await getVehicle({ token, vin, onAlert: setShowToast })
    setVehicle(vehicle)
  }

  useEffect(() => {
    token && vin && fetchVehicle()
  }, [token, vin])

  useEffect(() => {
    const getPartnersData = async () => {
      const partners = await getPartners({ token, onAlert: setShowToast })
      setPartners(
        partners?.reduce(
          (pre, curr) => Object.assign(pre, { [curr.id]: curr }),
          []
        )
      )
    }
    token && getPartnersData()
  }, [token])

  useEffect(() => {
    const fetchPairingRequests = async () => {
      const result = await getPairingRequests({
        limit: 1000,
        token,
        onAlert: setShowToast,
        status: 'PAIRED',
      })

      setPairingRequest(
        result.data.filter(
          (pairingRequest) => pairingRequest.kitId === vehicle?.kit?.id
        )[0]
      )
    }
    token && fetchPairingRequests()
  }, [vehicle, token])

  const onCopy = () => {
    navigator.clipboard.writeText(vehicle!.licensePlate)
    setShowToast({ text: `License plate copied`, type: ToastTypes.success })
  }

  const editAction = () => {
    vehicle &&
      setSelectedVehicle({
        vin: vehicle.vin,
        licensePlate: vehicle.licensePlate,
        partnerID: vehicle.partner?.id || null,
        friendlyName: vehicle.friendlyName,
      })
  }

  const onUpdateVehicle = async (vehicle?: CreateVehicle) => {
    vehicle &&
      (await updateVehicle({
        token,
        vehicle,
        onAlert: setShowToast,
      }))
    await fetchVehicle()
    setSelectedVehicle(undefined)
  }

  const tags = (vehicle: Vehicle) => {
    return (
      <div className={style.tagContainer}>
        {vehicle.friendlyName && (
          <Badge
            type={BadgeType.grey}
            label={vehicle.friendlyName}
            icon={<Icon name={'Car'} />}
          />
        )}
        <Badge
          type={BadgeType.grey}
          label={vehicle.vin}
          icon={<Icon name={'Car'} />}
        />
        {vehicle.partner && (
          <Badge
            type={BadgeType.grey}
            label={vehicle.partner.name}
            icon={<Icon name={'Partner'} />}
          />
        )}
      </div>
    )
  }

  const updatePairing = async (newStatus: string, id: string) => {
    await updatePairingRequest({
      token,
      id,
      status: newStatus,
      onAlert: setShowToast,
    })
    setUnpairing(null)
  }

  return (
    <div className={appStyle.page}>
      {vehicle && (
        <>
          <Breadcrumbs
            selfLink
            first={{
              text: 'Vehicles',
              url: '/vehicles',
            }}
            second={{
              text: vehicle.licensePlate,
            }}
          />
          <div className={style.header}>
            <div className={style.headerName}>
              <Typography type={TypographyTypes.headline} size="small">
                {vehicle.licensePlate}
              </Typography>
              <Icon
                name={'Copy'}
                size={IconSizes.large}
                onClick={onCopy}
                className={appStyle.pointer}
              />
            </div>
            <div className={style.infoBoxItem}>
              <Button
                onClick={editAction}
                icon={<Icon name={'Edit'} />}
                text={'Edit'}
                secondary
              />
              {vehicle.kit && (
                <Button
                  onClick={() => {
                    setUnpairing(vehicle)
                  }}
                  icon={<Icon name={'LinkOff'} />}
                  text={'Unpair'}
                  secondary
                />
              )}
            </div>
          </div>
          {tags(vehicle)}
          <Divider />
          <div className={style.content}>
            <DrivesOverview
              token={token}
              withHeader={false}
              filter={{ vin: vehicle.vin }}
            />
            <InfoBox
              vehicle={vehicle}
              click={() => navigate(`/kits/details/${vehicle.kit?.id}`)}
            />
          </div>
        </>
      )}
      <EditVehicleDialog
        isOpen={!!selectedVehicle}
        vehicle={selectedVehicle}
        partners={partners && Object.values(partners)}
        onCancel={() => {
          setSelectedVehicle(undefined)
        }}
        onSave={onUpdateVehicle}
      />
      <WarningDialog
        isOpen={unpairing !== null}
        dialogContentText={`Are you sure you want to unpair kit ${unpairing?.kit?.id}?`}
        dialogTitle={''}
        onSubmit={async () => {
          pairingRequest &&
            unpairing?.kit?.id &&
            (await updatePairing('UNPAIRED', pairingRequest?.id))
          await fetchVehicle()
        }}
        onCancel={() => setUnpairing(null)}
        buttons={{
          cancel: 'Cancel',
          submit: 'Unpair kit',
        }}
      />
    </div>
  )
}

export default VehiclePage
