import React, { ReactElement, useContext, useEffect, useState } from 'react'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import Divider from '@yaak/components/src/Divider/Divider'
import Tabs from '@yaak/components/src/Tabs'
import SearchBar from '@yaak/components/src/SearchBar'
import Grid from '@yaak/components/src/Grid'
import Icon from '@yaak/components/src/Icon'
import Button from '@yaak/components/src/Button'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Badge from '@yaak/components/src/Badge'
import {
  getDevices,
  getPairingRequests,
  User,
  updatePairingRequest,
  createPairingRequest,
  startDevice,
  PairingResponse,
} from '@yaak/components/services/api/api'
import { userRow } from '../../helpers/users'
import appStyle from '../../style.less'
import WarningDialog from '@yaak/components/src/WarningDialog/WarningDialog'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import { CreatePairingDialog } from './KitsDialogs'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { displayWaitingPairingRequests } from '../../helpers/pairingRequests'
import Empty from '@yaak/components/src/Empty'
import { useTabIndex } from '@yaak/components/customHooks/useTabIndex'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { BadgeType } from '@yaak/components/src/Badge/Badge'

interface KitsPageProps {
  token: string
}

interface GridData {
  data: any[]
  total: number
}

interface ContentProps {
  token: string
  searchbarPlaceHolder: string
  sortableHeaders: Object
  data: GridData
  fetchData: (
    orderBy: string,
    sortBy: string | undefined,
    searchQuery: string | undefined,
    offset: number
  ) => Promise<any>
  mapData: (data: any) => {
    rows: any[]
    options: any
  }
  headers: string[]
  keyId: string
  update?: () => any
}

const pairedHeader = [
  'Kit ID',
  'VIN',
  'License plate',
  'IP address',
  'MQTT',
  'Status',
  '',
  '',
]
const pairedSortableHeader = {
  'Kit ID': 'dongleId',
  VIN: 'vin',
  '': '',
  a: '',
  b: '',
  Status: 'status',
  c: '',
  d: '',
}

const unpairedHeader = ['Kit ID', 'IP address', 'MQTT', '']
const unpairedSortableHeader = { 'Kit ID': 'dongleId' }

const pairingRequestHeader = [
  'Kit ID',
  'Created',
  'Paired',
  'Paired by',
  'VIN',
  'Calibration data',
  'Status',
  '',
]
const pairingRequestSortableHeader = {
  'Kit ID': 'kitId',
  Created: 'createdAt',
  Paired: 'pairedAt',
  'Paired by': 'pairedBy',
  '': '',
  a: '',
  Status: 'status',
}

const tabsMapping: Record<number, string> = {
  0: 'paired',
  1: 'unpaired',
  2: 'pairingrequests',
}

const mapPairedDeviceRows = (
  data: any[],
  onCopy: (id: string) => void,
  setPairing: (props: any) => void,
  setUnpairing: (props: any) => void,
  setPowerOn: (props: any) => void,
  matches: boolean,
  navigate: NavigateFunction
) => {
  const rows = data.map((data) => {
    return [
      {
        text: data.dongleId,
        tooltip: 'View kit profile',
        ellipsis: matches,
        click: (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          navigate?.(`/kits/details/${data.dongleId}`)
        },
      },
      {
        text: data.pairedVehicleVIN,
        tooltip: matches ? data.pairedVehicleVIN : 'View vehicle profile',
        ellipsis: matches,
        click: (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          navigate?.(`/vehicles/${data.pairedVehicleVIN}`)
        },
      },
      {
        text: data.licensePlate || '-',
        tooltip: matches ? data.licensePlate : 'View vehicle profile',
        ellipsis: matches,
        click: (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          navigate?.(`/vehicles/${data.pairedVehicleVIN}`)
        },
      },
      { text: data.ipAddress || '-' },
      {
        icon: true,
        tooltip: data.mqttEnabled ? 'Enabled' : 'Not enabled',
        text: data.mqttEnabled ? (
          <Icon
            className={style.success}
            name={'Success'}
            size={IconSizes.medium}
          />
        ) : (
          <Icon
            className={style.cancel}
            name={'Cancel'}
            size={IconSizes.medium}
          />
        ),
      },
      { text: data.status ? toStatusBadge(data.status) : '-' },
      {
        icon: true,
        tooltip: 'Copy Kit ID',
        text: (
          <Icon
            name={'Copy'}
            size={IconSizes.medium}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              onCopy(data.dongleId)
            }}
          />
        ),
      },
      toPowerOnRow(data.mqttEnabled, () => setPowerOn?.(data.dongleId)),
    ]
  })
  return mergeHeaderWithDataToGridColumn(pairedHeader, rows, 2)
}

const mapUnpairedDeviceRows = (
  data: any[],
  onCopy: (id: string) => void,
  matches: boolean,
  navigate: NavigateFunction
) => {
  const rows = data.map((data) => {
    return [
      {
        text: data.dongleId,
        tooltip: 'View kit profile',
        ellipsis: matches,
        click: (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          navigate?.(`/kits/details/${data.dongleId}`)
        },
      },
      { text: data.ipAddress || '-' },
      {
        icon: true,
        tooltip: data.mqttEnabled ? 'Enabled' : 'Not enabled',
        text: data.mqttEnabled ? (
          <Icon
            className={style.success}
            name={'Success'}
            size={IconSizes.medium}
          />
        ) : (
          <Icon
            className={style.cancel}
            name={'Cancel'}
            size={IconSizes.medium}
          />
        ),
      },
      {
        icon: true,
        tooltip: 'Copy Kit ID',
        text: (
          <Icon
            name={'Copy'}
            size={IconSizes.medium}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              onCopy(data.dongleId)
            }}
          />
        ),
      },
    ]
  })
  return mergeHeaderWithDataToGridColumn(unpairedHeader, rows, 0)
}

const mapPairingRequestRows = (
  data: any[],
  setPairing: (props: any) => void,
  setUnpairing: (props: any) => void,
  matches: boolean,
  navigate: NavigateFunction
) => {
  const rows = data.map((data) => {
    return [
      {
        text: data.kitId,
        tooltip: 'View kit profile',
        ellipsis: matches,
        click: (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          navigate?.(`/kits/details/${data.kitId}`)
        },
      },
      {
        text: data.createdAt ? new Date(data.createdAt).toLocaleString() : '-',
        ellipsis: matches,
        tooltip: matches && new Date(data.createdAt).toLocaleString(),
      },
      {
        text: data.pairedAt ? new Date(data.pairedAt).toLocaleString() : '-',
        ellipsis: matches,
        tooltip: matches && new Date(data.pairedAt).toLocaleString(),
      },
      { text: data.grantedBy ? toUserAvatarRow(data.grantedBy) : '-' },
      {
        text: data.vin,
        ellipsis: matches,
        tooltip: data.vin,
        click: (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          navigate?.(`/vehicles/${data.vin}`)
        },
      },
      {
        icon: true,
        text: data.calibrationAvailable ? (
          <Icon className={style.success} name={'Success'} />
        ) : (
          <Icon className={style.cancel} name={'Cancel'} />
        ),
      },
      { text: toPairingStatusBadge(data.status) },
      toPairingActionRow(
        data.status,
        () => setPairing({ id: data.id, kitId: data.kitId }),
        () => setUnpairing({ id: data.id, kitId: data.kitId })
      ),
    ]
  })
  return mergeHeaderWithDataToGridColumn(pairingRequestHeader, rows, 1)
}

const toUserAvatarRow = (data: any) => {
  const { profileImageURL, firstname, lastname } = data
  return userRow({
    profileImageURL,
    firstName: firstname,
    lastName: lastname,
  } as User)
}

const mergeHeaderWithDataToGridColumn = (
  header: string[],
  rows: any[],
  fixedColumns: number
) => {
  const matrix: any[] = header.reduce((pre, curr) => {
    pre.push([curr])
    return pre
  }, [] as any[])

  rows.map((row) =>
    row.forEach((value: any, index: number) => matrix[index].push(value))
  )

  return { rows: matrix, options: { fixedColumns } }
}

const toStatusBadge = (status: string) => {
  switch (status) {
    case 'OFFLINE':
      return <Badge withDot label={'Offline'} type={BadgeType.grey} />
    case 'READY':
      return <Badge withDot label={'Ready'} />
    case 'RECORDING':
      return <Badge withDot label={'Recording'} type={BadgeType.yellow} />
    case 'UPLOADING':
      return <Badge withDot label={'Uploading'} type={BadgeType.blue} />
    case 'ERROR':
      return <Badge withDot label={'Error'} type={BadgeType.red} />
    case 'OTA_UPDATE':
      return <Badge withDot label={'OTA update'} type={BadgeType.grey} />
    case 'INITIALIZING':
      return <Badge withDot label={'Initializing'} type={BadgeType.grey} />
    case 'UPDATING':
      return <Badge withDot label={'Updating'} type={BadgeType.grey} />
    case 'SHUTDOWN':
      return <Badge withDot label={'Shutdown'} type={BadgeType.grey} />
    default:
      return <></>
  }
}

const toPairingStatusBadge = (status: string) => {
  switch (status) {
    case 'WAITING':
      return <Badge withDot label={'Waiting to pair'} type={BadgeType.grey} />
    case 'PAIRED':
      return <Badge withDot label={'Paired'} />
    case 'UNPAIRED':
      return <Badge withDot label={'Unpaired'} type={BadgeType.red} />
  }
}

const toPairingActionRow = (
  status: string,
  setPairing: () => void,
  setUnpairing: () => void
) => {
  switch (status) {
    case 'WAITING':
      return {
        icon: true,
        tooltip: 'Pair kit',
        text: (
          <Icon
            name={'Link'}
            className={appStyle.pointer}
            onClick={(event: React.MouseEvent<SVGSVGElement>) => {
              event.stopPropagation()
              setPairing()
            }}
          />
        ),
      }
    case 'PAIRED':
      return {
        icon: true,
        tooltip: 'Unpair kit',
        text: (
          <Icon
            name={'LinkOff'}
            className={appStyle.pointer}
            onClick={(event: React.MouseEvent<SVGSVGElement>) => {
              event.stopPropagation()
              setUnpairing()
            }}
          />
        ),
      }
    case 'UNPAIRED':
    default:
      return <></>
  }
}

const toPowerOnRow = (mqttEnabled: boolean, setPowerOn: () => void) => {
  return mqttEnabled ? (
    {
      icon: true,
      hidden: true,
      text: (
        <Icon
          name={'PowerOnOff'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            setPowerOn()
          }}
          size={IconSizes.large}
        />
      ),
      tooltip: 'Turn on kit',
    }
  ) : (
    <></>
  )
}

const KitsPage: React.FunctionComponent<KitsPageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [unpairing, setUnpairing] = useState<any | null>(null)
  const [pairing, setPairing] = useState<any | null>(null)
  const [powerON, setPowerOn] = useState<string | null>(null)
  const [pairedKits, setPairedKits] = useState<GridData>({ data: [], total: 0 })
  const [unpairedKits, setUnpairedKits] = useState<GridData>({
    data: [],
    total: 0,
  })
  const [pairingRequests, setPairingRequests] = useState<GridData>({
    data: [],
    total: 0,
  })
  const [waitingPairingRequests, setWaitingPairingRequests] =
    useState<PairingResponse>()

  const navigate = useNavigate()

  const { tab } = useParams()

  const currentTab = useTabIndex(tabsMapping, tab)

  const fetchWaitingPairingRequests = async (token: string) => {
    const result = await getPairingRequests({
      token,
      status: 'WAITING',
      onAlert: setShowToast,
    })
    setWaitingPairingRequests(result)
  }

  useEffect(() => {
    token && fetchWaitingPairingRequests(token)
  }, [token])

  const smallScreenMatches = useSmallScreenMatches()

  const fetchDevices = async (
    paired?: boolean,
    orderBy?: string,
    sortBy?: string,
    searchQuery?: string
  ) => {
    const result = await getDevices({
      token,
      paired,
      orderBy,
      sortBy,
      searchQuery,
      onAlert: setShowToast,
    })
    const data = { data: result, total: result.length }
    paired ? setPairedKits(data) : setUnpairedKits(data)
  }

  const fetchPairingRequests = async (
    orderBy?: string,
    sortBy?: string,
    searchQuery?: string,
    offset?: number
  ) => {
    const result = await getPairingRequests({
      token,
      searchQuery,
      orderBy,
      sortBy,
      offset,
      onAlert: setShowToast,
    })
    document.querySelector('#gridContainer')?.scrollTo(0, 0)
    setPairingRequests({
      data: offset ? pairingRequests.data.concat(result?.data) : result?.data,
      total: result.metaData.totalCount,
    })
  }

  const updatePairing = async (newStatus: string, id: string) => {
    await updatePairingRequest({
      token,
      id,
      status: newStatus,
      onAlert: setShowToast,
    })
    setUnpairing(null)
    setPairing(null)
    await fetchPairingRequests()
  }

  const powerONKit = async (dongleId: string | null) => {
    setPowerOn(null)
    dongleId && (await startDevice({ token, dongleId, onAlert: setShowToast }))
  }

  const onKitIdCopy = (id: string) => {
    navigator.clipboard.writeText(id)
    setShowToast({
      text: `Kit ID copied to clipboard`,
    })
  }

  const getTabPageByIndex = (index: number) => {
    if (index === 0) {
      return (
        <ContentGrid
          key={'paired'}
          keyId={'paired'}
          searchbarPlaceHolder="Search kits and vehicle VINs"
          sortableHeaders={pairedSortableHeader}
          mapData={(data) =>
            mapPairedDeviceRows(
              data,
              onKitIdCopy,
              setPairing,
              setUnpairing,
              setPowerOn,
              smallScreenMatches,
              navigate
            )
          }
          fetchData={(orderBy, sortBy, searchQuery) =>
            fetchDevices(true, orderBy, sortBy, searchQuery)
          }
          data={pairedKits}
          headers={pairedHeader}
          token={token}
        />
      )
    }
    if (index === 1) {
      return (
        <ContentGrid
          key={'unpaired'}
          keyId={'unpaired'}
          searchbarPlaceHolder="Search kits"
          sortableHeaders={unpairedSortableHeader}
          mapData={(data) =>
            mapUnpairedDeviceRows(
              data,
              onKitIdCopy,
              smallScreenMatches,
              navigate
            )
          }
          fetchData={(orderBy, sortBy, searchQuery) =>
            fetchDevices(false, orderBy, sortBy, searchQuery)
          }
          headers={unpairedHeader}
          data={unpairedKits}
          token={token}
        />
      )
    } else if (index === 2) {
      return (
        <ContentGrid
          key={'pairing_requests'}
          keyId={'pairing_requests'}
          searchbarPlaceHolder="Search kits and vehicle VINs"
          sortableHeaders={pairingRequestSortableHeader}
          mapData={(data) =>
            mapPairingRequestRows(
              data,
              setPairing,
              setUnpairing,
              smallScreenMatches,
              navigate
            )
          }
          fetchData={fetchPairingRequests}
          data={pairingRequests}
          headers={pairingRequestHeader}
          token={token}
          update={fetchPairingRequests}
        />
      )
    }
    return <div></div>
  }

  return (
    <div className={appStyle.page}>
      <div className={style.header}>
        <Typography type={TypographyTypes.headline}>Kits</Typography>
        <Tabs
          initial
          currentTab={currentTab}
          setCurrentTab={(tab) => {
            navigate(`/kits/${tabsMapping[tab]}`)
          }}
          tabs={[
            {
              index: 0,
              text: 'Paired',
              tooltip: 'Kits paired with a vehicle',
            },
            {
              index: 1,
              text: 'Unpaired',
              tooltip: 'Kits not paired with a vehicle',
            },
            {
              index: 2,
              text: 'Pairing requests',
              tooltip: 'Kits waiting to be paired with a vehicle',
              badge: displayWaitingPairingRequests(waitingPairingRequests),
            },
          ]}
        />
      </div>
      <Divider />
      {token ? getTabPageByIndex(currentTab || 0) : <div />}
      <WarningDialog
        isOpen={pairing !== null}
        dialogContentText={`Are you sure you want to pair kit ${pairing?.kitId}?`}
        dialogTitle={''}
        onSubmit={() => updatePairing('PAIRED', pairing?.id)}
        onCancel={() => setPairing(null)}
        buttons={{
          cancel: 'Cancel',
          submit: 'Pair kit',
        }}
      />
      <WarningDialog
        isOpen={unpairing !== null}
        dialogContentText={`Are you sure you want to unpair kit ${unpairing?.kitId}?`}
        dialogTitle={''}
        onSubmit={() => updatePairing('UNPAIRED', unpairing?.id)}
        onCancel={() => setUnpairing(null)}
        buttons={{
          cancel: 'Cancel',
          submit: 'Unpair kit',
        }}
      />
      <WarningDialog
        isOpen={powerON !== null}
        dialogContentText={`Are you sure you want to turn on kit ${powerON}?`}
        dialogTitle={''}
        onSubmit={() => powerONKit(powerON)}
        onCancel={() => setPowerOn(null)}
        buttons={{
          cancel: 'Cancel',
          submit: 'Turn on kit',
        }}
      />
    </div>
  )
}

const ContentGrid: React.FunctionComponent<ContentProps> = ({
  searchbarPlaceHolder,
  sortableHeaders,
  fetchData,
  mapData,
  data,
  headers,
  keyId,
  token,
  update,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [mappedData, setMappedData] = useState<any>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [creatingPairing, setCreatingPairing] = useState<boolean>(false)

  const navigate = useNavigate()

  const fetch = async (offset: number) => {
    await fetchData(sortOrder, sortBy, searchQuery, offset)
    setLoading(false)
  }

  useEffect(() => {
    fetch(0).then(() => setLoading(false))
  }, [sortBy, sortOrder])

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetch(0)
    }, 500)
    return () => clearTimeout(timeout)
  }, [searchQuery])

  useEffect(() => {
    const hasMore = data.data.length && data.data.length < data.total
    if (hasMore && fetchMoreData) {
      fetch(data.data.length)
    }
    setFetchMoreData(false)
  }, [fetchMoreData])

  useEffect(() => {
    setMappedData(mapData(data.data))
  }, [data])

  const onCreatePairing = async (pairingRequest: any) => {
    setCreatingPairing(false)
    await createPairingRequest({
      pairingRequest: {
        vin: pairingRequest.vin,
        kitId: pairingRequest.dongleId,
      },
      token,
      onAlert: setShowToast,
    })
    await update?.()
  }

  const onRowClick = (rowIndex: number) => {
    const dongleId = data.data[rowIndex].dongleId
    dongleId && navigate(`/kits/details/${dongleId}`)
  }

  return (
    <>
      <div className={style.searchBar}>
        <SearchBar
          setSearchQuery={setSearchQuery}
          placeholder={searchbarPlaceHolder}
        ></SearchBar>
        {keyId === 'pairing_requests' && (
          <Button
            className={style.button}
            text={'Create request'}
            onClick={() => {
              setCreatingPairing(true)
            }}
          />
        )}
      </div>
      {loading ? (
        <ProgressBar />
      ) : (
        <div className={style.gridContainer}>
          <Grid
            key={data.total}
            data={mappedData}
            fetchData={() => setFetchMoreData(true)}
            headerSort={Object.keys(sortableHeaders)}
            onHeaderClicked={(props) => {
              setSortBy(Object.values(sortableHeaders)[props.index])
              setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
            }}
            onRowClick={onRowClick}
            headers={headers}
          />
          {data.total === 0 &&
            (searchQuery ? (
              <Empty
                header={'Looks like no kits match your search criteria.'}
                content={'Try changing your search to see more results.'}
              />
            ) : (
              <Empty
                header={
                  keyId === 'paired'
                    ? 'Whoops! Looks like there are no paired kits yet.'
                    : keyId === 'unpaired'
                    ? 'Whoops! Looks like there are no unpaired kits yet.'
                    : 'Whoops! Looks like there are no pairing requests yet.'
                }
                content={
                  keyId === 'paired'
                    ? "Start pairing kits with vehicles by visiting the 'Pairing requests' tab."
                    : keyId === 'unpaired'
                    ? "If you wish to unpair a kit, please visit the 'Paired' kits tab."
                    : "To create a new pairing request, please click on the 'Create request' button in the top right."
                }
              />
            ))}
        </div>
      )}
      <CreatePairingDialog
        token={token}
        isOpen={creatingPairing}
        onClose={() => setCreatingPairing(false)}
        onCreate={onCreatePairing}
      />
    </>
  )
}

export default KitsPage
