export const MetacognitiveState = {
  Unaware: { value: 1, label: "Unaware" },
  Aware: { value: 2, label: "Aware" },
  Instinctive: { value: 3, label: "Instinctive" },
  Skillful: { value: 4, label: "Skillful" },
} as const;

export const convertIntMetacogToString = (value: number): string => {
  const state = Object.values(MetacognitiveState).find((state) => state.value === value);
  return state?.label || "No data";
};

export const convertStringMetacogToInt = (label: string): number | undefined => {
  const state = Object.values(MetacognitiveState).find((state) => state.label === label);
  return state?.value;
};

export const getAllStringValues = (): string[] => {
  return Object.values(MetacognitiveState).map((state) => state.label);
};

export const getAllIntValues = (): number[] => {
  return Object.values(MetacognitiveState).map((state) => state.value);
};
