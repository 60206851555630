import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import { YaakLogoFull } from '../../assets'
import style from './style.less'
import Divider from '../Divider'
import Icon from '../Icon'
import Typography, {
  TypographyStyles,
  TypographyTypes,
} from '../Typography/Typography'
import { useAuth0, User } from '@auth0/auth0-react'
import Tooltip from '../Tooltip'
import { useIntl } from 'react-intl'
import { IconSizes } from '../Icon/Icon'

type subLabelsType = {
  text: string
}

type labelsType = {
  icon: React.ReactNode
  text: string | React.ReactNode | any
  onClick?: () => void
  badge?: string
  subItems?: Array<subLabelsType>
}

interface SideNavigationProps {
  admin?: boolean
  labels: Array<Array<labelsType>>
  tooltips?: any
  currentPage: number
  onPageChange: (page: number) => void
  onSidebarOpen?: (toggle: boolean) => void
  additionalItems?: any
}

interface ListItemProps {
  idx: number
  currentPage?: number
  i: number
  label: {
    icon?: React.ReactNode
    text: string
    onClick?: () => void
    badge?: string
  }
  onPageChange?: (pageNumber: number) => void
  open: boolean
}

interface KnowledgeBaseProps {
  user?: User
  open: boolean
}

interface SupportType {
  text: string
  click: () => void
  icon: React.ReactNode
}

interface SupportItemProps {
  open: boolean
  support: SupportType
}

const getKnowledgeBaseUrl = (user?: User) =>
  user?.preferredLanguage
    ? `https://help.yaak.ai/${user?.preferredLanguage}`
    : 'https://help.yaak.ai/'

const KnowledgeBaseItem: React.FunctionComponent<KnowledgeBaseProps> = ({
  user,
  open,
}) => (
  <ListItemButton
    className={classNames(
      style.sideBarItemButton,
      !open && style.sideBarItemClosed
    )}
    onClick={() => {
      window.open(getKnowledgeBaseUrl(user), '_blank')
    }}
  >
    <ListItemIcon className={!open ? style.sideBarItemClosed : undefined}>
      <Icon name={'Book'} />
      <div className={style.sideBarItem}>
        <Typography type={TypographyTypes.body}>{'Knowledge base'}</Typography>
      </div>
    </ListItemIcon>
  </ListItemButton>
)

const SupportItem: React.FunctionComponent<SupportItemProps> = ({
  support,
  open,
}) => (
  <ListItemButton
    className={classNames(
      style.sideBarItemButton,
      !open && style.sideBarItemClosed
    )}
    onClick={support.click}
  >
    <ListItemIcon className={!open ? style.sideBarItemClosed : undefined}>
      {support.icon}
      <div className={style.sideBarItem}>
        <Typography type={TypographyTypes.body}>{support.text}</Typography>
      </div>
    </ListItemIcon>
  </ListItemButton>
)

const ListItem: React.FunctionComponent<ListItemProps> = ({
  idx,
  currentPage,
  i,
  label,
  onPageChange,
  open,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref?.current?.blur()
  }, [ref, currentPage])

  return (
    <ListItemButton
      ref={ref}
      className={classNames(
        style.sideBarItemButton,
        !open && style.sideBarItemClosed,
        idx === 0 && currentPage === i && style.sideBarItemSelected
      )}
      onClick={() => label.onClick?.() || onPageChange?.(i)}
    >
      <ListItemIcon className={!open ? style.sideBarItemClosed : undefined}>
        {label.icon}
        <div className={style.sideBarItem}>
          {open && idx === 0 && currentPage === i ? (
            <>
              <Typography type={TypographyTypes.title}>{label.text}</Typography>
              {label.badge && <div className={style.badge}>{label.badge}</div>}
            </>
          ) : (
            <>
              <Typography
                type={TypographyTypes.body}
                styles={
                  idx === 0 && currentPage === i
                    ? TypographyStyles.bold
                    : undefined
                }
              >
                {label.text}
              </Typography>
              {label.badge && <div className={style.badge}>{label.badge}</div>}
            </>
          )}
        </div>
      </ListItemIcon>
    </ListItemButton>
  )
}

const SideNavigation: React.FunctionComponent<SideNavigationProps> = ({
  admin,
  labels,
  tooltips,
  currentPage,
  onPageChange,
  onSidebarOpen,
  additionalItems,
}) => {
  const [open, setOpen] = useState(true)

  const { user } = useAuth0()
  const intl = useIntl()

  return (
    <div className={style.sideBarContainer}>
      <div className={classNames(open && style.sideBarOpen, style.sideBar)}>
        <div className={style.logo}>
          <>
            {open && <YaakLogoFull />}
            <Tooltip
              text={open ? tooltips?.collapse : tooltips?.expand}
              position={'right'}
            >
              <div
                className={style.menuIcon}
                onClick={() => {
                  setOpen(!open)
                  onSidebarOpen?.(!open)
                }}
              >
                {open && <Icon name={'MenuCollapse'} size={IconSizes.large} />}
                {!open && <Icon name={'Menu'} size={IconSizes.large} />}
              </div>
            </Tooltip>
          </>
        </div>
        <div className={style.navigationContainer}>
          <div>
            {labels.map((labelGroup, idx) => (
              <div key={idx}>
                <List className={style.navigationList}>
                  {labelGroup.map((label, i) =>
                    open ? (
                      <>
                        <ListItem
                          label={label}
                          currentPage={currentPage}
                          onPageChange={onPageChange}
                          i={i}
                          idx={idx}
                          open={open}
                          key={i}
                        />
                        {label.subItems?.map((subItem, index) => (
                          <div key={index} className={style.subItems}>
                            <ListItem
                              label={{
                                text: subItem.text,
                              }}
                              currentPage={currentPage}
                              onPageChange={onPageChange}
                              i={1 + 0.1 * (index + 1)}
                              idx={idx}
                              open={open}
                              key={index}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <Tooltip
                        key={i}
                        text={
                          typeof label.text === 'string'
                            ? label.text
                            : intl.formatMessage({ id: label.text?.props?.id })
                        }
                        position={'right'}
                      >
                        <ListItem
                          label={label}
                          currentPage={currentPage}
                          onPageChange={onPageChange}
                          i={label.subItems ? 1.1 : i}
                          idx={idx}
                          open={open}
                        />
                      </Tooltip>
                    )
                  )}
                </List>
                {idx < labels.length - 1 && (
                  <Divider className={style.sideBarDivider} />
                )}
              </div>
            ))}
          </div>
          <div className={style.bottomLink}>
            {open ? (
              admin ? (
                <KnowledgeBaseItem user={user} open={open} />
              ) : (
                <SupportItem support={additionalItems.support} open={open} />
              )
            ) : admin ? (
              <Tooltip text={tooltips?.knowledgeBase} position={'right'}>
                <KnowledgeBaseItem user={user} open={open} />
              </Tooltip>
            ) : (
              <Tooltip text={tooltips?.support} position={'right'}>
                <SupportItem support={additionalItems.support} open={open} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideNavigation
