// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__badge--aCRuN {
  align-items: center;
  display: flex;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
  width: max-content;
}
.style__badgeGreen--AAnHl {
  background: #eaffe9;
  color: #00a751;
}
.style__badgeGrey--raxDd {
  background: #f1f4f2;
  color: #626a65;
}
.style__badgeRed--NbiNE {
  background: #fff3f0;
  color: #d72a1d;
}
.style__badgeYellow--SaUIC {
  background: #fffae5;
  color: #e5b300;
}
.style__badgeBlue--H8KVl {
  background: #f8f9ff;
  color: #0070cc;
}
.style__badge--aCRuN img, .style__badge--aCRuN svg {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
}
.style__dot--avBXG {
  width: 0.5rem;
  height: 0.5rem;
  background: currentColor;
  border-radius: 0.5rem;
}
.style__svg--A8dbk > svg {
  transform: scale(0.75);
  width: auto;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./../components/src/Badge/style.less"],"names":[],"mappings":"AAGA;EAEI,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EAEA,kBAAA;AAJJ;AAMI;EACE,mBAAA;EACA,cAAA;AAJN;AAOI;EACE,mBAAA;EACA,cAAA;AALN;AAQI;EACE,mBAAA;EACA,cAAA;AANN;AASI;EACE,mBAAA;EACA,cAAA;AAPN;AAUI;EACE,mBAAA;EACA,cAAA;AARN;AAxBA;EAqCM,WAAA;EACA,YAAA;EACA,mBAAA;AATN;AA9BA;EA4CI,aAAA;EACA,cAAA;EACA,wBAAA;EACA,qBAAA;AAXJ;AApCA;EAoDM,sBAAA;EACA,WAAA;EACA,YAAA;AAbN","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .badge {\n    align-items: center;\n    display: flex;\n    border-radius: @spacing-100;\n    padding: @spacing-25 @spacing-50;\n    gap: @spacing-25;\n\n    width: max-content;\n\n    &Green {\n      background: @color-green-099;\n      color: @color-green-060;\n    }\n\n    &Grey {\n      background: @color-pewter-grey-095;\n      color: @color-pewter-grey-040;\n    }\n\n    &Red {\n      background: @color-red-099;\n      color: @color-red-050;\n    }\n\n    &Yellow {\n      background: @color-yellow-099;\n      color: @color-yellow-050;\n    }\n\n    &Blue {\n      background: @color-blue-099;\n      color: @color-blue-050;\n    }\n\n    img,\n    svg {\n      width: @spacing-100;\n      height: @spacing-100;\n      border-radius: @spacing-100;\n    }\n  }\n\n  .dot {\n    width: @spacing-50;\n    height: @spacing-50;\n    background: currentColor;\n    border-radius: @spacing-50;\n  }\n\n  .svg {\n    > svg {\n      transform: scale(0.75);\n      width: auto;\n      height: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `style__badge--aCRuN`,
	"badgeGreen": `style__badgeGreen--AAnHl`,
	"badgeGrey": `style__badgeGrey--raxDd`,
	"badgeRed": `style__badgeRed--NbiNE`,
	"badgeYellow": `style__badgeYellow--SaUIC`,
	"badgeBlue": `style__badgeBlue--H8KVl`,
	"dot": `style__dot--avBXG`,
	"svg": `style__svg--A8dbk`
};
export default ___CSS_LOADER_EXPORT___;
