// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__infoBox--Jjhti {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 3px solid #e3e8e5;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 0.75rem;
  width: 100%;
  height: fit-content;
}
.style__infoBoxHeader--GLZOh {
  color: #18191b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.style__infoBoxItems--B2K0T {
  display: flex;
  flex-direction: column;
}
.style__infoItemContentHeader--JkoGA {
  color: #626a65;
}
.style__infoItemContent--s1d9a {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: #0c0d0d;
}
.style__infoItem--vT83h {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
}
`, "",{"version":3,"sources":["webpack://./../components/src/InfoBox/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,yBAAA;EACA,qBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAHJ;AAPA;EAcI,cAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAJJ;AAbA;EAqBI,aAAA;EACA,sBAAA;AALJ;AAjBA;EA0BI,cAAA;AANJ;AApBA;EA8BI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AAPJ;AA1BA;EAqCI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,YAAA;AARJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/shadows';\n\n:local {\n  .infoBox {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    border: 3px solid @color-pewter-grey-090;\n    border-radius: @spacing-50;\n    padding: @spacing-100;\n    gap: @spacing-75;\n    width: 100%;\n    height: fit-content;\n  }\n\n  .infoBoxHeader {\n    color: @color-asphalt-grey-010;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .infoBoxItems {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .infoItemContentHeader {\n    color: @color-pewter-grey-040;\n  }\n\n  .infoItemContent {\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-25;\n    color: @color-pewter-grey-005;\n  }\n\n  .infoItem {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    gap: @spacing-75;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `style__infoBox--Jjhti`,
	"infoBoxHeader": `style__infoBoxHeader--GLZOh`,
	"infoBoxItems": `style__infoBoxItems--B2K0T`,
	"infoItemContentHeader": `style__infoItemContentHeader--JkoGA`,
	"infoItemContent": `style__infoItemContent--s1d9a`,
	"infoItem": `style__infoItem--vT83h`
};
export default ___CSS_LOADER_EXPORT___;
