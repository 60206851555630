import {
  CreateVehicle,
  Vehicle,
  VehicleResponse,
} from '@yaak/components/services/api/api'
import { Dispatch, SetStateAction } from 'react'
import { transpose } from './common'
import { NavigateFunction } from 'react-router-dom'
import {
  GridEditIconElement,
  GridKitElement,
  GridPartnerElement,
  GridSuccessCancelIconElement,
  GridTextElement,
  GridVehicleElement,
} from '@yaak/components/src/Grid/GridElements'

export const HEADERS = [
  'License Plate',
  'VIN',
  'Friendly name',
  'Kit ID',
  'Partner',
  'Calibration data',
  '',
]

export const HEADERS_WITHOUT_PARTNER = [
  'License Plate',
  'VIN',
  'Friendly name',
  'Kit ID',
  'Calibration data',
  '',
]

interface VehicleActions {
  onEdit: (vin: string) => void
}

interface VehicleOptions {
  withPartner: boolean
}

const getRows = (
  vehicles: Vehicle[],
  actions: VehicleActions,
  options: VehicleOptions,
  navigate: NavigateFunction,
  matches: boolean
) =>
  vehicles.map((vehicle) => {
    const row = []
    row.push(
      GridVehicleElement({
        licensePlate: vehicle.licensePlate,
        vin: vehicle.vin,
        ellipsis: matches,
        navigate,
      })
    )
    row.push(
      GridVehicleElement({
        vin: vehicle.vin,
        ellipsis: matches,
        navigate,
      })
    )
    row.push(
      GridTextElement({
        text: vehicle.friendlyName || '-',
      })
    )
    row.push(
      GridKitElement({
        id: vehicle.kit?.id,
        ellipsis: matches,
        navigate,
      })
    )
    options.withPartner &&
      row.push(
        GridPartnerElement({
          partner: vehicle.partner,
          ellipsis: matches,
          navigate,
        })
      )
    row.push(
      GridSuccessCancelIconElement({
        condition: vehicle.calibrationAvailable,
        positive: 'Available',
        negative: 'Not available',
      })
    )
    row.push(
      GridEditIconElement({
        data: vehicle.vin,
        editAction: actions.onEdit,
        name: 'vehicle',
      })
    )
    return row
  })

export const getMappedVehicles = (
  vehicles: Vehicle[],
  navigate: NavigateFunction,
  matches: boolean,
  actions: VehicleActions,
  options: VehicleOptions = {
    withPartner: true,
  }
) => {
  const columns = transpose(
    getRows(vehicles, actions, options, navigate, matches)
  )

  columns.length > 0
    ? columns.map((row: any, i: number) =>
        (options.withPartner ? HEADERS : HEADERS_WITHOUT_PARTNER).map(
          (header, k) => i === k && row.unshift(header)
        )
      )
    : (options.withPartner ? HEADERS : HEADERS_WITHOUT_PARTNER).map((header) =>
        columns.push([header])
      )

  return {
    rows: columns,
    options: {
      fixedColumns: 1,
    },
  }
}

export const VINValidationRegex = /^\w{17}$/

export const editVehicleAction = ({
  vin,
  vehicles,
  setSelectedVehicle,
}: {
  vin: string
  vehicles: VehicleResponse
  setSelectedVehicle?: Dispatch<SetStateAction<CreateVehicle | undefined>>
}) => {
  const vehicle = vehicles?.data.find((v) => v.vin === vin) || null
  vehicle &&
    setSelectedVehicle?.({
      vin: vehicle.vin,
      licensePlate: vehicle.licensePlate,
      partnerID: vehicle.partner?.id || null,
      friendlyName: vehicle.friendlyName,
    })
}
