import {
  SessionData,
  User,
  LessonTopic,
  CreateUser,
} from '@yaak/components/services/api/api'
import { User as Auth0User } from '@auth0/auth0-spa-js/dist/typings/global'

export const toHoursAndMinutes = (totalMinutes: number | undefined) => {
  if (totalMinutes !== undefined) {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = Math.floor(totalMinutes % 60)
    return `${hours}h ${minutes.toString().padStart(2, '0')}m`
  }
}

export const groupDrivesByMonth = (
  drives: SessionData[],
  intl: any,
  user: any
) => {
  return drives.reduce((acc, drive) => {
    const startDate = new Date(drive.startTimestamp)
    const currentMonth = new Date().getUTCMonth()
    const currentYear = new Date().getUTCFullYear()
    const groupName =
      startDate.getUTCMonth() === currentMonth &&
      startDate.getUTCFullYear() === currentYear
        ? intl.formatMessage({ id: 'this_month' })
        : startDate.toLocaleDateString(getUserLocale(user), {
            year: 'numeric',
            month: 'long',
          })
    const curGroup = acc[groupName] ?? []
    return { ...acc, [groupName]: [...curGroup, drive] }
  }, {} as any)
}

export const getCategories = (lessonTopics: { data: LessonTopic[] }) =>
  lessonTopics.data.reduce(
    (acc, topic) =>
      acc.indexOf(topic.category) === -1 ? [...acc, topic.category] : acc,
    [] as any
  )

export const getMandatoryDrivingMinutesForCategory = (
  lessonTopics: { data: LessonTopic[] },
  category: string
) =>
  lessonTopics.data.reduce(
    (acc, topic: LessonTopic) =>
      topic.category === category ? acc + topic.mandatoryDrivingMinutes : acc,
    0
  )

export const getRatingTrend = (userStats: {
  rating: number
  ratingRecords: any[]
}) => {
  const found = userStats.ratingRecords
    .slice(0, -1)
    .reverse()
    .find((record) => record.rating)
  return found && parseFloat((userStats.rating - found.rating).toFixed(1))
}

export const getDurationIncrementInMin = (userStats: {
  durationMinutes: number
  ratingRecords: any[]
}) => {
  const found = userStats.ratingRecords
    .slice(0, -1)
    .reverse()
    .find((record) => record.durationMinutes)
  return userStats.durationMinutes - found?.durationMinutes || 0
}

export const getUserLocale = (user: any | undefined): string => {
  const localesKey =
    user && Object.keys(user).filter((key) => key.indexOf('locale') !== -1)[0]
  return localesKey ? user?.[localesKey as keyof User].toString() : 'en'
}

const regexEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const isUserValid = (user: CreateUser | null) =>
  user?.firstName !== undefined &&
  user?.firstName.length > 1 &&
  user?.lastName !== undefined &&
  user?.lastName.length > 1 &&
  user?.email !== undefined &&
  regexEmail.test(user?.email)

export const isEmailValid = (email: string) =>
  email !== undefined && regexEmail.test(email)

export const containsSpaces = (email: string) => /^\S+$/.test(email)

export const getAuth0UserRole = (user?: Auth0User) => {
  const roleKey = Object.keys(user as any).filter(
    (key) => key.indexOf('roles') !== -1
  )[0]
  return user?.[roleKey]
}

export const getAuth0UserLocale = (user?: Auth0User) => {
  const localesKey = Object.keys(user as any).filter(
    (key) => key.indexOf('locale') !== -1
  )[0]
  return user?.[localesKey]
}

export const transpose = (matrix: any) =>
  matrix?.[0]?.map((col: any, i: number) => matrix.map((row: any) => row[i])) ||
  []
