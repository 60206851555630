// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__gridWrapper--dWEdy {
  height: calc(100% - 10rem);
  display: flex;
  flex-direction: column;
}
.style__searchContainer--DTG_d {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
}
.style__searchContainer--DTG_d > div {
  width: 75%;
}
.style__dialog--B0rZ3 h2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.style__dialog--B0rZ3 h2 div {
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  background-color: #f9f5ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5960de;
}
.style__addButton--wKR2L {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/VehicleOverview/style.less"],"names":[],"mappings":"AAGA;EAEI,0BAAA;EACA,aAAA;EACA,sBAAA;AAHJ;AADA;EAQI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;AAJJ;AARA;EAeM,UAAA;AAJN;AAXA;EAqBM,aAAA;EACA,sBAAA;EACA,SAAA;AAPN;AAhBA;EA0BQ,YAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAPR;AA1BA;EAuCI,mBAAA;AAVJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .gridWrapper {\n    height: calc(100% - 10rem);\n    display: flex;\n    flex-direction: column;\n  }\n\n  .searchContainer {\n    display: flex;\n    gap: 1rem;\n    margin-bottom: @spacing-100;\n    width: 100%;\n    justify-content: space-between;\n\n    > div {\n      width: 75%;\n    }\n  }\n\n  .dialog {\n    h2 {\n      display: flex;\n      flex-direction: column;\n      gap: @spacing-100;\n\n      div {\n        height: @spacing-300;\n        width: @spacing-300;\n        border-radius: @spacing-150;\n        background-color: @color-indigo-099;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        color: @color-indigo-050;\n      }\n    }\n  }\n\n  .addButton {\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridWrapper": `style__gridWrapper--dWEdy`,
	"searchContainer": `style__searchContainer--DTG_d`,
	"dialog": `style__dialog--B0rZ3`,
	"addButton": `style__addButton--wKR2L`
};
export default ___CSS_LOADER_EXPORT___;
