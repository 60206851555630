import React, { ReactElement } from 'react'
import { User } from '@yaak/components/services/api/api'
import { useNavigate } from 'react-router-dom'
import style from './style.less'
import Typography from '../Typography'
import { TypographyTypes } from '../Typography/Typography'
import Tooltip from '../Tooltip'
import AvatarNamePlate from '../AvatarNamePlate'
import { FormattedMessage } from 'react-intl'

export interface ActiveStudentsProps {
  activeStudents: User[]
  dsAdmin?: boolean
}

const ActiveStudents: React.FunctionComponent<ActiveStudentsProps> = ({
  activeStudents,
  dsAdmin,
}): ReactElement => {
  const navigate = useNavigate()

  return (
    <div className={style.activeStudentsBox}>
      <Typography type={TypographyTypes.label}>
        {dsAdmin ? (
          <FormattedMessage id={'active_students'} />
        ) : (
          'Active students'
        )}
      </Typography>
      <Typography type={TypographyTypes.body}>
        <div className={style.studentsBox}>
          {activeStudents?.map((student, index) => (
            <Tooltip
              key={student.id}
              text={
                dsAdmin ? (
                  <FormattedMessage id={'view_user_profile'} />
                ) : (
                  'View user profile'
                )
              }
              position={'top'}
            >
              <AvatarNamePlate
                gravatarURL={student.profileImageURL}
                name={`${student.firstName} ${student.lastName}`}
                key={index}
                click={() => {
                  navigate(
                    dsAdmin ? `/students/${student.id}` : `/users/${student.id}`
                  )
                }}
              />
            </Tooltip>
          ))}
        </div>
      </Typography>
    </div>
  )
}

export default ActiveStudents
