import React, { ReactElement, useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import { useAuth0 } from '@auth0/auth0-react'

import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import WarningDialog from '@yaak/components/src/WarningDialog'
import Button from '@yaak/components/src/Button'
import style from './style.less'
import { resetPassword } from '@yaak/components/services/api/api'
import { getUserRole } from '../../helpers/users'
import Icon, { IconSizes } from '@yaak/components/src/Icon/Icon'
import Divider from '@yaak/components/src/Divider/Divider'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

interface AccountProps {}

const Account: React.FunctionComponent<AccountProps> = (): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false)

  const { user: auth0User } = useAuth0()

  return (
    <>
      <Typography type={TypographyTypes.title} size={TypographySizes.large}>
        Personal info
      </Typography>
      <Typography type={TypographyTypes.body}>
        Update your personal profile here.
      </Typography>
      <div className={style.settingsRow}>
        <Typography type={TypographyTypes.label} size={TypographySizes.large}>
          Name
        </Typography>
        <div className={style.settingsSecondRow}>
          <div className={style.inputFieldBox}>
            {'First name'}
            <TextField
              disabled
              defaultValue={auth0User?.given_name}
              InputProps={{ style: { borderRadius: '0.5rem' } }}
            />
          </div>
          <div className={style.inputFieldBox}>
            {'Last name'}
            <TextField
              disabled
              defaultValue={auth0User?.family_name}
              InputProps={{ style: { borderRadius: '0.5rem' } }}
            />
          </div>
        </div>
      </div>
      <Divider small={true} />
      <div className={style.settingsRow}>
        <Typography type={TypographyTypes.label} size={TypographySizes.large}>
          Email
        </Typography>
        <div className={style.settingsSecondRow}>
          <TextField
            className={style.longField}
            disabled
            fullWidth
            placeholder={'Email'}
            defaultValue={auth0User?.email}
            InputProps={{ style: { borderRadius: '0.5rem' } }}
          />
        </div>
      </div>
      <Divider small={true} />
      <div className={style.settingsRow}>
        <Typography type={TypographyTypes.label} size={TypographySizes.large}>
          Phone
        </Typography>
        <div className={style.settingsSecondRow}>
          <TextField
            disabled
            fullWidth
            label={'Phone'}
            defaultValue={auth0User?.phone_number}
            InputProps={{ style: { borderRadius: '0.5rem' } }}
          />
        </div>
      </div>
      <Divider small={true} />
      <div className={style.settingsRow}>
        <div>
          <Typography type={TypographyTypes.label} size={TypographySizes.large}>
            Role
          </Typography>
        </div>
        <div className={style.settingsSecondRow}>
          <TextField
            disabled
            fullWidth
            defaultValue={getUserRole(auth0User)}
            InputProps={{ style: { borderRadius: '0.5rem' } }}
          />
        </div>
      </div>
      <Divider small={true} />
      <div className={style.settingsRow}>
        <div>
          <Typography type={TypographyTypes.label} size={TypographySizes.large}>
            Password
          </Typography>
        </div>
        <div className={style.settingsSecondRow}>
          <Button
            onClick={async () => {
              setShowChangePasswordDialog(true)
              auth0User?.email &&
                (await resetPassword({
                  email: auth0User.email,
                  onAlert: setShowToast,
                }))
            }}
            text={'Change password'}
          />
        </div>
      </div>
      <WarningDialog
        icon={<Icon name={'Email'} size={IconSizes.large} />}
        isOpen={showChangePasswordDialog}
        onSubmit={() => setShowChangePasswordDialog(false)}
        buttons={{
          submit: 'Ok',
        }}
        dialogTitle={'Reset your password'}
        dialogContentText={
          'Please check your email for the password reset link we sent you.'
        }
      />
    </>
  )
}

export default Account
