// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__content--Yjulj {
  display: flex;
  gap: 2rem;
  height: calc(100% - 11rem);
}
.style__content--Yjulj > div {
  width: 70%;
}
.style__vehicleTags--FBfH9 {
  display: flex;
  align-items: start;
}
.style__tagContainer--txSUO {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.style__header--Jfk1Y {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.style__headerName--zVmMp {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin: 1rem 0;
  align-items: center;
  align-content: center;
}
.style__infoBox--iJkay {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #e3e8e5;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 0.75rem;
  width: 100%;
  height: fit-content;
}
.style__infoBoxHeader--wUkW9 {
  color: #7d8287;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.style__infoBoxItems--VlC6r {
  display: flex;
  justify-content: space-between;
}
.style__infoBoxItem--DrCS_ {
  display: flex;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/VehicleDetailsPage/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,SAAA;EACA,0BAAA;AAHJ;AADA;EAOM,UAAA;AAHN;AAJA;EAYI,aAAA;EACA,kBAAA;AALJ;AARA;EAiBI,aAAA;EACA,mBAAA;EACA,WAAA;AANJ;AAbA;EAuBI,mBAAA;EACA,aAAA;EACA,8BAAA;AAPJ;AAlBA;EA4BI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,qBAAA;AAPJ;AA1BA;EAqCI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,yBAAA;EACA,qBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AARJ;AArCA;EAiDI,cAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AATJ;AA3CA;EAwDI,aAAA;EACA,8BAAA;AAVJ;AA/CA;EA6DI,aAAA;EACA,SAAA;AAXJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .content {\n    display: flex;\n    gap: @spacing-200;\n    height: calc(100% - 11rem);\n\n    > div {\n      width: 70%;\n    }\n  }\n\n  .vehicleTags {\n    display: flex;\n    align-items: start;\n  }\n\n  .tagContainer {\n    display: flex;\n    flex-direction: row;\n    gap: @spacing-50;\n  }\n\n  .header {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n  }\n  .headerName {\n    display: flex;\n    flex-direction: row;\n    gap: @spacing-75;\n    margin: @spacing-100 0;\n    align-items: center;\n    align-content: center;\n  }\n\n  .infoBox {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    border: 1px solid @color-pewter-grey-090;\n    border-radius: @spacing-50;\n    padding: @spacing-100;\n    gap: @spacing-75;\n    width: 100%;\n    height: fit-content;\n  }\n\n  .infoBoxHeader {\n    color: @color-asphalt-grey-050;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .infoBoxItems {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .infoBoxItem {\n    display: flex;\n    gap: @spacing-100;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style__content--Yjulj`,
	"vehicleTags": `style__vehicleTags--FBfH9`,
	"tagContainer": `style__tagContainer--txSUO`,
	"header": `style__header--Jfk1Y`,
	"headerName": `style__headerName--zVmMp`,
	"infoBox": `style__infoBox--iJkay`,
	"infoBoxHeader": `style__infoBoxHeader--wUkW9`,
	"infoBoxItems": `style__infoBoxItems--VlC6r`,
	"infoBoxItem": `style__infoBoxItem--DrCS_`
};
export default ___CSS_LOADER_EXPORT___;
