import React, { memo } from 'react'
import classNames from 'classnames'
import style from './style.less'
import Typography from '../Typography'
import { TypographyTypes } from '../Typography/Typography'
import Icon from '../Icon'
import { IconTypes } from '../Icon/Icon'

interface GridHeaderElementProps {
  icon: boolean
  sortOrder: boolean | null
  column: any
  sortEnabled: boolean
}

interface SortCaretElementProps {
  sortOrder: boolean | null
}

const SortCaretElement = ({ sortOrder }: SortCaretElementProps) => {
  return (
    <div className={style.sortCaretButton}>
      {sortOrder ? (
        <Icon name={'ArrowDownward'} type={IconTypes.regular} />
      ) : (
        <Icon
          className={style.caretDown}
          name={'ArrowDownward'}
          type={IconTypes.regular}
        />
      )}
    </div>
  )
}

const GridHeaderElement = ({
  sortOrder,
  column,
  icon,
  sortEnabled,
}: GridHeaderElementProps) => {
  return (
    <div
      className={classNames(
        style.gridItemHeader,
        sortEnabled ? style.pointer : undefined,
        icon ? style.gridItemCenter : undefined
      )}
    >
      <Typography type={TypographyTypes.label} className={style.tableHeader}>
        {column.text || column}
      </Typography>
      {sortEnabled && <SortCaretElement sortOrder={sortOrder} />}
    </div>
  )
}

export default memo(GridHeaderElement)
