import React, { ReactElement, useContext, useEffect, useState } from 'react'
import style from './style.less'
import appStyle from '../../style.less'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import { useAuth0 } from '@auth0/auth0-react'
import Tabs from '@yaak/components/src/Tabs'
import { getUser, User } from '@yaak/components/services/api/api'
import { useNavigate, useParams } from 'react-router-dom'
import Account from './Account'
import Preferences from './Preferences'
import Team from './Team'
import { useTabIndex } from '@yaak/components/customHooks/useTabIndex'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

const tabsMapping: Record<number, string> = {
  0: 'account',
  1: 'preferences',
  2: 'team',
}

interface SettingsPageProps {
  token: string
}

const SettingsPage: React.FunctionComponent<SettingsPageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User>({} as User)

  const { user: auth0User } = useAuth0()
  const navigate = useNavigate()

  const { tab } = useParams()

  const currentTab = useTabIndex(tabsMapping, tab)

  useEffect(() => {
    const fetchUser = async () => {
      const ourUser = await getUser({
        token,
        onAlert: setShowToast,
        id: auth0User?.sub,
      })
      if (ourUser) {
        setUser(ourUser)
      } else {
        navigate('/')
      }
      setLoading(false)
    }

    token && fetchUser()
  }, [token, auth0User])

  const getTabPageByIndex = (index: number) => {
    if (index === 1) {
      return <Preferences token={token} user={user} />
    } else if (index === 2) {
      return <Team token={token} />
    }
    return <Account />
  }

  return (
    <div className={appStyle.page}>
      {loading ? (
        <ProgressBar />
      ) : (
        <div className={style.settings}>
          <div className={style.settingsTabs}>
            <Typography type={TypographyTypes.headline}>Settings</Typography>
            <Tabs
              currentTab={currentTab}
              setCurrentTab={(tab) => {
                navigate(`/settings/${tabsMapping[tab]}`)
              }}
              tabs={[
                {
                  index: 0,
                  text: 'Account',
                },
                {
                  index: 1,
                  text: 'Preferences',
                },
                {
                  index: 2,
                  text: 'Team',
                },
              ]}
            />
          </div>
          <Divider />
          {getTabPageByIndex(currentTab)}
        </div>
      )}
    </div>
  )
}

export default SettingsPage
