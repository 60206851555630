import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider/Divider'
import Tabs from '@yaak/components/src/Tabs'
import appStyle from '../../style.less'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { useTabIndex } from '@yaak/components/customHooks/useTabIndex'
import style from './style.less'
import {
  deleteSim,
  getActiveSimulationDrives,
  getSimulationDrives,
  SimulationDrive,
  SimulationDrives,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Grid from '@yaak/components/src/Grid'
import Empty from '@yaak/components/src/Empty'
import Counter from '@yaak/components/src/Counter'
import { transpose } from '../../helpers/common'
import { toastType } from '@yaak/components/src/Toast/Toast'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'
import { formatTimestamp, toDuration } from '../../helpers/time'
import { getStatus } from '../../helpers/simulation'
import WarningDialog from '@yaak/components/src/WarningDialog'
import {
  GridCopyIconElement,
  GridDeleteIconElement,
  GridDriverElement,
  GridSuccessCancelIconElement,
  GridTextElement,
} from '@yaak/components/src/Grid/GridElements'
import SearchFilter from '@yaak/components/src/SearchFilter'

const COMPLETED_HEADERS = ['Time', 'Module', 'Driver', 'Duration', 'Result', '']
const COMPLETED_HEADERS_WITHOUT_DRIVER = [
  'Time',
  'Module',
  'Duration',
  'Result',
  '',
]
const ALL_HEADERS = [
  'Time',
  'Simulator rig',
  'Module',
  'Driver',
  'Duration',
  'Result',
  '',
  '',
]
const ACTIVE_HEADERS = [
  'Time',
  'Module',
  'Driver',
  'Duration',
  'Status',
  '',
  '',
]

const sortValue = ['startTimestamp']

const getRows = (
  simulationDrives: SimulationDrive[],
  navigate: NavigateFunction,
  matches: boolean,
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  withDriver: boolean
) =>
  simulationDrives?.map((simulationDrive) => {
    const row = []
    row.push(
      GridTextElement({
        text: formatTimestamp(simulationDrive.startTimestamp),
        ellipsis: matches,
      })
    )
    row.push(
      GridTextElement({
        ellipsis: true,
        text: simulationDrive.module.title || '-',
      })
    )
    withDriver &&
      row.push(
        GridDriverElement({
          driver: simulationDrive.user,
          ellipsis: matches,
          navigate,
        })
      )
    row.push(
      GridTextElement({
        text: toDuration(
          simulationDrive.startTimestamp,
          simulationDrive.endTimestamp
        ),
      })
    )
    row.push(
      GridSuccessCancelIconElement({
        condition: simulationDrive.result === 'SUCCESS',
        positive: 'Drive result available',
        negative: 'Drive result not available',
      })
    )
    row.push(
      GridCopyIconElement({
        data: simulationDrive,
        name: 'drive',
        setShowToast,
      })
    )
    return row
  })

interface ActiveSimulationDrivesActions {
  delete: (id: string) => void
}

const getActiveRows = (
  simulationDrives: SimulationDrive[],
  navigate: NavigateFunction,
  matches: boolean,
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  actions: ActiveSimulationDrivesActions,
  showSimRig: boolean
) =>
  simulationDrives?.map((simulationDrive) => {
    const row = []
    row.push(
      GridTextElement({
        text: formatTimestamp(simulationDrive.startTimestamp),
        ellipsis: matches,
      })
    )

    if (showSimRig) {
      row.push(
        GridTextElement({
          ellipsis: true,
          text: simulationDrive.simDeviceName,
        })
      )
    }

    row.push(
      GridTextElement({
        ellipsis: true,
        text: simulationDrive.module.title || '-',
      })
    )
    row.push(
      GridDriverElement({
        driver: simulationDrive.user,
        ellipsis: matches,
        navigate,
      })
    )
    simulationDrive.status
      ? row.push(
          GridTextElement({
            text: <Counter time={simulationDrive.startTimestamp} />,
          })
        )
      : row.push(
          GridTextElement({
            text: toDuration(
              simulationDrive.startTimestamp,
              simulationDrive.endTimestamp
            ),
          })
        )
    row.push({
      text: simulationDrive.status ? getStatus(simulationDrive.status) : '-',
    })
    row.push(
      GridCopyIconElement({
        data: simulationDrive,
        name: 'simulator rig',
        setShowToast,
      })
    )
    row.push(
      GridDeleteIconElement({
        data: simulationDrive,
        deleteAction: actions.delete,
        tooltip: 'Remove active session',
        visible: true,
      })
    )
    return row
  })

const getMappedSimulationDrives = ({
  simulationDrives,
  navigate,
  matches,
  setShowToast,
  withDriver,
}: {
  simulationDrives: SimulationDrive[]
  navigate: NavigateFunction
  matches: boolean
  withDriver: boolean
  setShowToast: Dispatch<SetStateAction<toastType | null>>
}) => {
  const columns = transpose(
    getRows(simulationDrives, navigate, matches, setShowToast, withDriver)
  )
  columns.map((row: any, i: number) =>
    (withDriver ? COMPLETED_HEADERS : COMPLETED_HEADERS_WITHOUT_DRIVER).map(
      (header, k) => i === k && row.unshift(header)
    )
  )
  return {
    options: {
      fixedColumns: 2,
    },
    rows: columns,
  }
}

const getMappedActiveSimulationDrives = ({
  simulationDrives,
  navigate,
  matches,
  setShowToast,
  actions,
  showSimRig = false,
}: {
  simulationDrives: SimulationDrive[]
  navigate: NavigateFunction
  matches: boolean
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  actions: ActiveSimulationDrivesActions
  showSimRig?: boolean
}) => {
  const columns = transpose(
    getActiveRows(
      simulationDrives,
      navigate,
      matches,
      setShowToast,
      actions,
      showSimRig
    )
  )
  columns.map((row: any, i: number) =>
    (showSimRig ? ALL_HEADERS : ACTIVE_HEADERS).map(
      (header, k) => i === k && row.unshift(header)
    )
  )
  return {
    options: {
      fixedColumns: 2,
    },
    rows: columns,
  }
}

interface SimulationOverviewProps {
  token: string
  withHeader?: boolean
  withDriver?: boolean
  partnerId?: string
  userId?: string
  showAll?: boolean
}

const tabsMapping: Record<number, string> = {
  0: 'active',
  1: 'completed',
}

interface dateRange {
  sessionStart: string | undefined
  sessionEnd: string | undefined
}

const SimulationOverview: React.FunctionComponent<SimulationOverviewProps> = ({
  token,
  withHeader = true,
  withDriver = true,
  partnerId,
  userId,
  showAll = false,
}): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [simulationDrives, setSimulationDrives] =
    useState<SimulationDrives | null>(null)
  const [allSimulationDrives, setAllSimulationDrives] =
    useState<SimulationDrives | null>(null)
  const [activeSimulationDrives, setActiveSimulationDrives] =
    useState<SimulationDrives | null>(null)
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [simToDelete, setSimToDelete] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [dateRange, setDateRange] = useState<dateRange | null>({
    sessionStart: undefined,
    sessionEnd: undefined,
  })

  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const navigate = useNavigate()
  const { tab } = useParams()

  const smallScreenMatches = useSmallScreenMatches()

  const currentTab = useTabIndex(tabsMapping, tab)

  useEffect(() => {
    const fetchSimulationDrives = async () => {
      const [simulationDrives, activeSimulationDrives] = await Promise.all([
        getSimulationDrives({
          token,
          partnerId,
          userId,
          sortOrder,
          sortBy,
          onAlert: setShowToast,
        }),
        getActiveSimulationDrives({
          token,
          sortOrder,
          onAlert: setShowToast,
        }),
      ])
      setSimulationDrives(simulationDrives)
      setActiveSimulationDrives(activeSimulationDrives)

      setLoading(false)
    }

    token && fetchSimulationDrives()
  }, [token, sortOrder, sortBy])

  useEffect(() => {
    activeSimulationDrives &&
      simulationDrives &&
      setAllSimulationDrives({
        data: [...activeSimulationDrives.data.concat(simulationDrives.data)],
        metaData: {
          count:
            activeSimulationDrives.metaData.count +
            simulationDrives.metaData.count,
          totalCount:
            activeSimulationDrives.metaData.totalCount +
            simulationDrives.metaData.totalCount,
        },
      })
  }, [simulationDrives, activeSimulationDrives])

  useEffect(() => {
    const fetchSimulationDrives = async () => {
      const simulationDrives = await getSimulationDrives({
        token,
        partnerId,
        userId,
        sortBy,
        sortOrder,
        onAlert: setShowToast,
        searchQuery,
        ...dateRange,
      })

      setSimulationDrives(simulationDrives)
    }

    if (searchQuery !== null) {
      const timeout = setTimeout(() => {
        token && fetchSimulationDrives()
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [token, searchQuery, dateRange, sortBy])

  useEffect(() => {
    showAll
      ? (async () => {
          await fetchData2()
          await fetchDataActive()
        })()
      : fetchMoreData && currentTab === 1
      ? fetchData2()
      : fetchDataActive()
    setFetchMoreData(false)
  }, [fetchMoreData, showAll])

  const fetchData = () => {
    setFetchMoreData(true)
  }
  const fetchData2 = async () => {
    const morePages =
      simulationDrives?.data.length &&
      simulationDrives?.data.length < simulationDrives?.metaData.totalCount

    if (morePages) {
      const moreSimulationDrives = await getSimulationDrives({
        token,
        sortOrder,
        sortBy,
        onAlert: setShowToast,
        offset: simulationDrives.data.length,
      })
      if (moreSimulationDrives) {
        setSimulationDrives({
          ...simulationDrives,
          data: [...simulationDrives.data, ...moreSimulationDrives.data],
        })
      }
    }
  }

  const fetchDataActive = async () => {
    const morePages =
      activeSimulationDrives?.data.length &&
      activeSimulationDrives?.data.length <
        activeSimulationDrives?.metaData.totalCount

    if (morePages) {
      const moreSimulationDrives = await getActiveSimulationDrives({
        token,
        onAlert: setShowToast,
        offset: activeSimulationDrives.data.length,
      })
      if (moreSimulationDrives) {
        setActiveSimulationDrives({
          ...activeSimulationDrives,
          data: [...activeSimulationDrives.data, ...moreSimulationDrives.data],
        })
      }
    }
  }

  const deleteSimulationDrive = (id: string) => {
    setSimToDelete(id)
  }

  const onSimDelete = async () => {
    simToDelete &&
      (await deleteSim({ token, id: simToDelete, onAlert: setShowToast }))
    activeSimulationDrives &&
      setActiveSimulationDrives({
        ...activeSimulationDrives,
        data: activeSimulationDrives.data.filter(
          (activeSim) => activeSim.id !== simToDelete
        ),
      })
    setSimToDelete(null)
  }

  return (
    <div className={withHeader ? appStyle.page : style.container}>
      {withHeader && (
        <>
          <div className={style.header}>
            <Typography type={TypographyTypes.headline}>
              Drives: Simulation
            </Typography>
            <Tabs
              initial
              currentTab={currentTab}
              setCurrentTab={(tab) => {
                navigate(`/drives/simulation/${tabsMapping[tab]}`)
              }}
              tabs={[
                {
                  index: 0,
                  text: 'Active',
                  tooltip: 'Simulation drives that are in progress',
                },
                {
                  index: 1,
                  text: 'Completed',
                  tooltip: 'Simulation drives that are complete',
                },
              ]}
            />
          </div>
          <Divider />
        </>
      )}
      {currentTab === 1 && (
        <SearchFilter
          setSearchQuery={setSearchQuery}
          setDateRange={setDateRange}
        />
      )}
      {loading ? (
        <ProgressBar />
      ) : showAll && allSimulationDrives ? (
        <div
          className={
            withHeader
              ? style.simulationDrivesWrapper
              : style.simulationDrivesWrapperWithoutHeader
          }
        >
          <Grid
            headers={ALL_HEADERS}
            data={getMappedActiveSimulationDrives({
              simulationDrives: allSimulationDrives.data,
              showSimRig: true,
              navigate,
              matches: smallScreenMatches,
              setShowToast,
              actions: {
                delete: deleteSimulationDrive,
              },
            })}
            fetchData={fetchData}
            headerSort={['Time']}
            onHeaderClicked={(props) => {
              setSortBy(sortValue[props.index])
              setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
            }}
          />
        </div>
      ) : !tab || currentTab === 1 || userId ? (
        simulationDrives && simulationDrives.data.length > 0 ? (
          <div
            className={
              withHeader
                ? style.simulationDrivesWrapper
                : style.simulationDrivesWrapperWithoutHeader
            }
          >
            <Grid
              headers={
                withDriver
                  ? COMPLETED_HEADERS
                  : COMPLETED_HEADERS_WITHOUT_DRIVER
              }
              data={getMappedSimulationDrives({
                simulationDrives: simulationDrives.data,
                navigate,
                matches: smallScreenMatches,
                setShowToast,
                withDriver,
              })}
              fetchData={fetchData}
              headerSort={['Time']}
              onHeaderClicked={(props) => {
                setSortBy(sortValue[props.index])
                setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
              }}
            />
          </div>
        ) : userId ? (
          <Empty
            header={'Simulation registration needed'}
            content={'This student is not registered for simulator lessons.'}
          />
        ) : (
          simulationDrives?.data.length === 0 && (
            <Empty
              header={'Looks like there are no completed drives yet.'}
              content={
                'Only simulation drives that have been successfully completed will appear here.'
              }
            />
          )
        )
      ) : activeSimulationDrives && activeSimulationDrives.data.length > 0 ? (
        <div
          className={
            withHeader
              ? style.simulationDrivesWrapper
              : style.simulationDrivesWrapperWithoutHeader
          }
        >
          <Grid
            headers={ACTIVE_HEADERS}
            data={getMappedActiveSimulationDrives({
              simulationDrives: activeSimulationDrives.data,
              navigate,
              matches: smallScreenMatches,
              setShowToast,
              actions: {
                delete: deleteSimulationDrive,
              },
            })}
            fetchData={fetchData}
            headerSort={['Time']}
            onHeaderClicked={(props) => {
              setSortBy(sortValue[props.index])
              setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
            }}
          />
        </div>
      ) : (
        activeSimulationDrives?.data.length === 0 && (
          <Empty
            header={'Looks like there are no active drives.'}
            content={
              'Only simulation drives that are in-progress will appear here.'
            }
          />
        )
      )}
      <WarningDialog
        isOpen={simToDelete !== null}
        onCancel={() => {
          setSimToDelete(null)
        }}
        onSubmit={onSimDelete}
        buttons={{
          cancel: 'Cancel',
          submit: 'Remove',
        }}
        dialogTitle={'Are you sure you want to remove the active session?'}
        dialogContentText={''}
      />
    </div>
  )
}

export default SimulationOverview
