import React from 'react'
import { DEIcon, ENIcon } from '@yaak/components/assets'
import style from './style.less'
import { Partner } from '@yaak/components/services/api/api'
import { isValidPhoneNumber } from '../../helpers/validation'
import { containsSpaces, isEmailValid } from '../../helpers/users'

export const firstNameInput = {
  name: 'firstName',
  title: 'First name',
  required: true,
  placeHolderText: 'e.g. Bugs',
}

export const lastNameInput = {
  name: 'lastName',
  title: 'Last name',
  required: true,
  placeHolderText: 'e.g. Bunny',
}

export const emailInput = {
  name: 'email',
  title: 'Email',
  required: true,
  placeHolderText: 'e.g. bugsbunny@gmail.com',
  validate: (email: string) => containsSpaces(email) && isEmailValid(email),
  errorText: 'Please provide a valid email.',
}

export const phoneNumberInput = {
  name: 'phoneNumber',
  title: 'Mobile',
  required: false,
  placeHolderText: '01234567890',
  description:
    'Mobile numbers only. Country code required (e.g. +4901234567890).',
  validate: (number: string) => isValidPhoneNumber(number) !== null,
  errorText: 'Please provide a valid phone number (e.g. +4901234567890).',
}

export const partnerInput = (partners: Array<Partner>) => ({
  name: 'partnerId',
  title: 'Partner',
  required: true,
  placeHolderText: 'Select a partner',
  description: 'Be sure you have already created the partner entity.',
  selections: partners.map((partner) => ({
    value: partner.id,
    name: partner.name,
    item: <div>{partner.name}</div>,
  })),
})

export const preferredLanguageInput = {
  name: 'preferredLanguage',
  title: 'Language',
  required: true,
  placeHolderText: 'Select a language',
  description: 'This is the partner’s primary contact language.',
  selections: [
    {
      name: 'English',
      value: 'en',
      item: (
        <div className={style.languageItem}>
          <ENIcon />
          {'English'}
        </div>
      ),
    },
    {
      name: 'Deutsch',
      value: 'de',
      item: (
        <div className={style.languageItem}>
          <DEIcon />
          {'Deutsch'}
        </div>
      ),
    },
  ],
}

export const statusInput = {
  name: 'active',
  title: 'Status',
  required: false,
}

export const simEnabledInput = {
  name: 'simEnabled',
  title: 'Sim-enabled',
  required: false,
}
