import React, { Dispatch, SetStateAction } from 'react'
import style from './style.less'
import SearchBar from '../SearchBar'
import DateRangePicker from 'rsuite/DateRangePicker'
import { predefinedRanges } from '../../helpers/dateRangePicker'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

interface dateRange {
  sessionStart: string | undefined
  sessionEnd: string | undefined
}

export interface SearchFilterProps {
  setSearchQuery: Dispatch<SetStateAction<string>>
  setDateRange: Dispatch<SetStateAction<dateRange | null>>
}

const SearchFilter = ({ setSearchQuery, setDateRange }: SearchFilterProps) => {
  return (
    <div className={style.searchContainer}>
      <SearchBar
        fullWidth
        clear
        placeholder={
          'Search partners, drive types, vehicles, kits and drive IDs'
        }
        setSearchQuery={setSearchQuery}
      />
      <div className={style.datePickerContainer}>
        <DateRangePicker
          character={'-'}
          size="lg"
          ranges={predefinedRanges}
          placeholder="Filter by date"
          placement={'bottomEnd'}
          onChange={(value: any | null) => {
            if (value && (value[0] || value[1])) {
              document.querySelector('#gridContainer')?.scrollTo(0, 0)
              setDateRange({
                sessionStart: startOfDay(value[0]).toISOString(),
                sessionEnd: endOfDay(value[1]).toISOString(),
              })
            }
          }}
          onClean={() => {
            setDateRange(null)
          }}
        />
      </div>
    </div>
  )
}

export default SearchFilter
