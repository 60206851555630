import React from 'react'
import Typography from '@yaak/components/src/Typography'
import {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'

interface EmptyProps {
  header: string | React.ReactNode
  content: string | React.ReactNode
}

const Empty: React.FunctionComponent<EmptyProps> = ({ header, content }) => {
  return (
    <div className={style.empty}>
      <Typography type={TypographyTypes.headline} size={TypographySizes.small}>
        {header}
      </Typography>
      <Typography
        type={TypographyTypes.body}
        size={TypographySizes.large}
        className={style.body}
      >
        {content}
      </Typography>
    </div>
  )
}

export default Empty
