import React from 'react'
import style from './style.less'

export interface ProgressionProps {
  percent: number
}

const Progression: React.FunctionComponent<ProgressionProps> = ({
  percent,
}) => (
  <div className={style.container}>
    <div
      className={style.progression}
      style={{
        width: `${percent}%`,
      }}
    ></div>
  </div>
)

export default Progression
