import { useState, useEffect } from 'react'

const MATCH_MEDIA = '(max-width: 1140px)'

export function useSmallScreenMatches() {
  const [matches, setMatches] = useState(window.matchMedia(MATCH_MEDIA).matches)

  useEffect(() => {
    window
      .matchMedia(MATCH_MEDIA)
      .addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  return matches
}
