import React, { ReactNode, useEffect, useState } from 'react'
import style from './style.less'
import Typography from '../Typography'
import Tooltip from '../Tooltip'
import { TypographySizes, TypographyTypes } from '../Typography/Typography'
import classNames from 'classnames'

type Tab = {
  index: number
  icon?: ReactNode
  text: string | ReactNode
  children?: ReactNode
  childrenTop?: ReactNode
  tooltip?: string
  badge?: string
}

export interface TabsProps {
  initial?: boolean
  tabs: (Tab | null)[]
  contentPadding?: boolean
  setCurrentTab?: (tab: number) => void
  currentTab?: number | null
}

const Tabs: React.FunctionComponent<TabsProps> = ({
  initial,
  tabs,
  contentPadding,
  setCurrentTab,
  currentTab,
}) => {
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    currentTab !== undefined && currentTab !== null && setSelected(currentTab)
  }, [currentTab])

  return (
    <div
      className={
        initial ? style.tabsContainerInitialOverflow : style.tabsContainer
      }
    >
      <div className={style.tabs}>
        {tabs.map(
          (tab, i) =>
            tab && (
              <div
                className={classNames(
                  style.tab,
                  selected === i ? style.tabSelected : undefined
                )}
                key={i}
                onClick={() => {
                  setSelected(i)
                  setCurrentTab?.(i)
                }}
              >
                {tab.icon}
                {tab.tooltip ? (
                  <Tooltip text={tab.tooltip} position={'left'}>
                    <Typography
                      type={TypographyTypes.label}
                      size={TypographySizes.large}
                    >
                      {tab.text}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    type={TypographyTypes.label}
                    size={TypographySizes.large}
                  >
                    {tab.text}
                  </Typography>
                )}
                {tab.badge && <div className={style.badge}>{tab.badge}</div>}
              </div>
            )
        )}
      </div>
      {tabs[selected]?.childrenTop}
      {tabs[selected]?.children && (
        <div
          className={classNames(
            style.content,
            contentPadding ? style.contentPadding : undefined
          )}
        >
          {tabs[selected]?.children}
        </div>
      )}
    </div>
  )
}

export default Tabs
