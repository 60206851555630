import React, { useState, useContext } from 'react'
import FormDialog, {
  InputProp,
  FormDetailsEntry,
  FormDialogTypes,
} from '@yaak/components/src/FormDialog/FormDialog'
import { YaakIcon } from '@yaak/components/assets'
import {
  CreateUser,
  Partner,
  getPartners,
  User,
} from '@yaak/components/services/api/api'
import { getRoleNameByRole } from '../../helpers/users'
import {
  emailInput,
  firstNameInput,
  lastNameInput,
  partnerInput,
  phoneNumberInput,
  preferredLanguageInput,
  simEnabledInput,
  statusInput,
} from './UserDialogInputs'
import Icon, { IconSizes } from '@yaak/components/src/Icon/Icon'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

interface UserCreationProps {
  token: string
  role: string
  isOpen: boolean
  onClose: () => void
  onCreate: (user: CreateUser) => Promise<any>
  simAdmin?: boolean
}

interface UserEditProps {
  token: string
  role: string
  user: User | null
  isOpen: boolean
  onClose: () => void
  onCreate: (user: CreateUser) => Promise<any>
  simAdmin?: boolean
}

const getDialogIconByRole = (role: string) => {
  switch (role) {
    case 'ds-admin':
      return <Icon name={'Star'} size={IconSizes.large} />
    case 'ds-instructor':
      return <Icon name={'Instructor'} size={IconSizes.large} />
    case 'ds-student':
      return <Icon name={'Student'} size={IconSizes.large} />
    default:
      return <YaakIcon />
  }
}

export const UserCreationDialog: React.FunctionComponent<UserCreationProps> = ({
  token,
  role,
  isOpen,
  onClose,
  onCreate,
  simAdmin,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [partners, setPartners] = useState<Array<Partner>>([])

  const buildCreateUserDialogInputs = async (): Promise<InputProp[]> => {
    const partners = await getPartners({ token, onAlert: setShowToast })
    setPartners(partners)
    const result: InputProp[] = [
      firstNameInput,
      lastNameInput,
      emailInput,
      phoneNumberInput,
      partnerInput(partners),
      preferredLanguageInput,
    ]
    if (role === 'ds-student') {
      result.push({
        ...simEnabledInput,
        value: simAdmin,
        disabled: simAdmin,
      })
    }
    return result
  }

  const toUserDetailsEntry = (result: any): FormDetailsEntry[] => {
    const items = [
      {
        icon: <Icon name={'User'} size={IconSizes.medium} />,
        value: `${result.firstName} ${result.lastName}`,
      },
      {
        icon: <Icon name={'Email'} size={IconSizes.medium} />,
        value: result.email,
      },
      result.phoneNumber
        ? {
            icon: <Icon name={'Phone'} size={IconSizes.medium} />,
            value: result.phoneNumber,
          }
        : undefined,
      {
        icon: <Icon name={'Partner'} size={IconSizes.medium} />,
        value: partners.find((partner) => partner.id === result.partnerId)
          ?.name,
      },
      {
        icon: <Icon name={'Language'} size={IconSizes.medium} />,
        value: result.preferredLanguage,
      },
    ]

    if (result.simEnabled) {
      items.push({
        icon: <Icon name={'VR'} size={IconSizes.medium} />,
        value: result.simEnabled && 'Sim-enabled',
      })
    }
    return items.filter((e) => e) as FormDetailsEntry[] // sad typescript...
  }

  return (
    <FormDialog
      title={`Add ${getRoleNameByRole(role)}`}
      confirmationTitle="Confirm details"
      titleIcon={getDialogIconByRole(role)}
      open={isOpen}
      onCancel={onClose}
      onConfirmed={onCreate}
      inputs={buildCreateUserDialogInputs}
      toDetailEntries={toUserDetailsEntry}
    />
  )
}

export const UserEditDialog: React.FunctionComponent<UserEditProps> = ({
  token,
  role,
  user,
  isOpen,
  onClose,
  onCreate,
  simAdmin,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const buildEditUserDialogInputs = async (): Promise<InputProp[]> => {
    const partners = await getPartners({ token, onAlert: setShowToast })
    return [
      {
        ...firstNameInput,
        value: user?.firstName,
      },
      {
        ...lastNameInput,
        value: user?.lastName,
      },
      {
        ...emailInput,
        value: user?.email,
      },
      {
        ...phoneNumberInput,
        value: user?.phoneNumber,
      },
      {
        ...partnerInput(partners),
        disabled: true,
        value: user?.partnerId,
      },
      {
        ...preferredLanguageInput,
        value: user?.preferredLanguage,
      },
      {
        ...statusInput,
        value: user?.active,
      },
      {
        ...simEnabledInput,
        value: user?.simEnabled,
        disabled: simAdmin,
      },
    ]
  }

  return (
    <FormDialog
      title={`Edit ${getRoleNameByRole(role)}`}
      confirmationTitle=""
      titleIcon={getDialogIconByRole(role)}
      open={isOpen}
      onCancel={onClose}
      onConfirmed={onCreate}
      inputs={buildEditUserDialogInputs}
      dialogType={FormDialogTypes.edit}
    />
  )
}
