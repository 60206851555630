import React, { ReactElement, useState, useContext } from 'react'
import style from './style.less'
import Typography from '@yaak/components/src/Typography'
import {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DEIcon, ENIcon } from '@yaak/components/assets'
import Button from '@yaak/components/src/Button'
import { updateUser, User } from '@yaak/components/services/api/api'
import Icon from '@yaak/components/src/Icon'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import Switch from '@yaak/components/src/Switch'
import { DARK_THEME, LIGHT_THEME, ThemeContext } from '../../App'

interface PreferencesProps {
  token: string
  user: User
}

const Preferences: React.FunctionComponent<PreferencesProps> = ({
  token,
  user,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [language, setLanguage] = useState<string>('')
  const { theme, setTheme } = useContext(ThemeContext)

  return (
    <>
      <div className={style.preferences}>
        <div>
          <Typography type={TypographyTypes.title} size={TypographySizes.large}>
            Preferences
          </Typography>
          <Typography type={TypographyTypes.body}>
            Manage and change your system preferences here.
          </Typography>
        </div>
        <Button
          onClick={async () => {
            const updatedUser = await updateUser({
              token,
              user: {
                ...user,
                preferredLanguage: language,
              },
              onAlert: setShowToast,
            })
            if (updatedUser) {
              user.preferredLanguage = updatedUser.preferredLanguage
            }
          }}
          text={'Save changes'}
          disabled={!language}
        />
      </div>
      <div className={style.settingsRow}>
        <div>
          <Typography type={TypographyTypes.label} size={TypographySizes.large}>
            Language
          </Typography>
          <Typography type={TypographyTypes.body}>
            Changes will be reflected here and in the app.
          </Typography>
        </div>
        <div className={style.settingsSecondRow}>
          <div className={style.inputFieldBox}>
            {'Language'}
            <Select
              className={style.inputField}
              defaultValue={user.preferredLanguage}
              onChange={(event) => setLanguage(event.target.value)}
              MenuProps={{ classes: { paper: style.inputFieldMenu } }}
              IconComponent={(_props) => {
                const opened = _props.className.toString().includes('iconOpen')
                return (
                  <Icon
                    className={style.iconSelect}
                    name={opened ? 'ExpandLess' : 'ExpandMore'}
                  />
                )
              }}
            >
              <MenuItem key={0} value={'en'}>
                <div className={style.languageItem}>
                  <ENIcon />
                  {'English'}
                </div>
              </MenuItem>
              <MenuItem key={1} value={'de'}>
                <div className={style.languageItem}>
                  <DEIcon />
                  {'Deutsch'}
                </div>
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      <div className={style.settingsRow}>
        <Typography type={TypographyTypes.title} size={TypographySizes.large}>
          Theme
        </Typography>
        <Switch
          checked={theme === LIGHT_THEME}
          onChange={() => {
            setTheme(theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME)
          }}
        />
      </div>
    </>
  )
}

export default Preferences
