import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@yaak/components/src/Button'
import style from './style.less'

const AccessDenied: React.FunctionComponent = () => {
  const { logout } = useAuth0()
  return (
    <div className={style.accessDenied}>
      <h1>Access Denied</h1>
      <Button
        onClick={() => logout({ returnTo: window.location.origin })}
        text="Back to login"
      />
    </div>
  )
}

export default AccessDenied
