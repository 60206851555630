import React from 'react'
import style from './style.less'

export interface ProgressBarProps {}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = () => (
  <div className={style.container}>
    <div className={style.skill}>
      <div className={style.outer}>
        <div className={style.inner}></div>
      </div>
      <svg
        className={style.svg}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="48px"
        height="48px"
      >
        <circle
          className={style.circle}
          cx="26"
          cy="22"
          r="20"
          transform="rotate(-90 24 24)"
        />
      </svg>
    </div>
  </div>
)

export default ProgressBar
