import React, { Dispatch, SetStateAction, useContext } from 'react'

import { DEIcon, ENIcon } from '@yaak/components/assets'
import Icon from '@yaak/components/src/Icon'
import FormDialog, {
  FormDialogTypes,
  FormInputProps,
} from '@yaak/components/src/FormDialog/FormDialog'
import style from './style.less'
import { getUsers, Partner } from '@yaak/components/services/api/api'
import { toastType } from '@yaak/components/src/Toast/Toast'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

interface PartnerEditDialogProps {
  token: string
  selectedPartner: Partner | null
  onCancel: () => void
  onConfirmed: (result: any) => Promise<any>
  simAdmin: boolean
}

export const partnerNameDialogInput = {
  name: 'name',
  title: 'Partner name',
  required: true,
  placeHolderText: 'e.g. Bugs Bunny’s Driving School',
  validate: (name: string) => name.length >= 2,
}

const partnerMainContactDialogInputBase = {
  name: 'mainContactId',
  title: 'Main Contact',
  required: false,
  placeHolderText: 'Select a DS Admin',
  selections: [],
}

export const partnerSimEnabled = {
  name: 'simEnabled',
  title: 'Sim enabled',
  value: true,
}

const buildEditPartnerDialogInputs = async (
  token: string,
  selectedPartner: Partner | null,
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  simAdmin: boolean
): Promise<FormInputProps[]> => {
  if (selectedPartner === null) {
    return []
  }

  const users = await getUsers({
    token,
    partnerId: selectedPartner.id,
    roles: ['ds-admin'],
    onAlert: setShowToast,
  })

  const userSelections = users.data.map((user) => ({
    value: user.id,
    name: `${user.firstName} ${user.lastName}`,
    item: <div>{`${user.firstName} ${user.lastName}`}</div>,
  }))

  const dialogInputs = [
    {
      ...partnerNameDialogInput,
      value: selectedPartner.name,
    },
    {
      ...partnerMainContactDialogInputBase,
      selections: userSelections,
      value: selectedPartner.mainContactId,
    },
    {
      ...partnerLanguageDialogInput,
      value: selectedPartner.language,
    },
    {
      ...partnerSimEnabled,
      value: selectedPartner.simEnabled,
      disabled: simAdmin,
    },
  ]

  return dialogInputs
}

export const partnerLanguageDialogInput = {
  name: 'language',
  title: 'Language',
  required: true,
  placeHolderText: 'Select a language',
  description: 'This is the partner’s primary contact language.',
  selections: [
    {
      name: 'English',
      value: 'en',
      item: (
        <div className={style.languageItem}>
          <ENIcon />
          {'English'}
        </div>
      ),
    },
    {
      name: 'Deutsch',
      value: 'de',
      item: (
        <div className={style.languageItem}>
          <DEIcon />
          {'Deutsch'}
        </div>
      ),
    },
  ],
}

const PartnerEditDialog: React.FunctionComponent<PartnerEditDialogProps> = ({
  simAdmin,
  token,
  selectedPartner,
  onCancel,
  onConfirmed,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  return (
    <FormDialog
      titleIcon={<Icon name={'Partner'} size={IconSizes.large} />}
      title={'Edit partner'}
      dialogType={FormDialogTypes.edit}
      inputs={() =>
        buildEditPartnerDialogInputs(
          token,
          selectedPartner,
          setShowToast,
          simAdmin
        )
      }
      open={selectedPartner !== null}
      confirmationTitle={''}
      onCancel={onCancel}
      onConfirmed={onConfirmed}
    ></FormDialog>
  )
}

export default PartnerEditDialog
