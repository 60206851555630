import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {getElearningTimeReportData} from "../../services/api/elearningapi";
import {ToastContext, ToastContextType} from "../../context/toastContext";
import style from './style.less'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Box, FormControl, InputLabel} from "@mui/material";
import {convertIntMetacogToString} from "../../helpers/metacog";
import {FormattedMessage} from "react-intl";

interface TheoryChartProps {
  userId: string
  token: string
  themes: string[]
  theme: string
}

type chartEntry = {
  name: string
  metacog: number
}

// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const convertedLabel = convertIntMetacogToString(payload[0].value + 1);

    return (
      <div style={{backgroundColor: "white", padding: "20px"}}>
        <p>{`${label} : ${convertedLabel}`}</p>
      </div>
    );
  }

  return null;
};

const TimeseriesChart: React.FunctionComponent<
  TheoryChartProps
> = (props): ReactElement => {
  const [theme, setTheme] = useState(props.theme)
  const [data, setData] = useState(Array<chartEntry>)
  const [themes] = useState<Array<string>>(props.themes)
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  useEffect(() => {
    setTheme(props.theme)
  }, [props.theme]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getElearningTimeReportData({
        userId: props.userId,
        token: props.token,
        theme,
        onAlert: setShowToast
      });
      setData([])

      if (resp === undefined) {
        return
      }
      for (const [key, entry] of Object.entries(resp.entries)) {
        const chart: chartEntry = {
          name: key,
          metacog: entry.metacog
        }
        setData((data) => [...data, chart])
      }
    }

    if (theme !== "") {
      fetchData()
    }
  }, [theme])

  return (
    <>
        <Box sx={{ height: "6em" }} />
        <FormControl>
          <InputLabel id="themegebiet-label"><FormattedMessage id={'chart_placeholder_option'}/></InputLabel>
          <Select
            labelId="themegebiet-label"
            label={<FormattedMessage id={'chart_placeholder_option'}/>}
            className={style.inputField}
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          >
            {themes.map((option, index) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {(data !== undefined) &&
          <ResponsiveContainer height={500} width={'100%'}>
            <LineChart
              width={900}
              height={500}
              data={data}
              margin={{
                top: 25,
                right: 30,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name" />
              <YAxis width={220}
                     padding={{bottom: 5}}
                     domain={[0, 1, 2, 3]}
                     type="category"
                     includeHidden={true}
                     tickFormatter={(v) => convertIntMetacogToString(v + 1)}/>
              <Tooltip content={<CustomTooltip />}/>
              <Legend formatter={(v, e, i) => "Metacognition"}/>
              <Line type="linear" dataKey="metacog" stroke="#8884d8" activeDot={{r: 8}} />
            </LineChart>
          </ResponsiveContainer>
        }
    </>
  );
}

export default TimeseriesChart
