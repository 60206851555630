// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__examResult--oGWj9 {
  border-radius: 1rem;
  line-height: 1.6rem;
  text-align: center;
  display: inline-block;
  padding: 0.25rem 0.5rem;
}
.style__passedExamInactive--LzOea {
  color: #7d8287;
  background: #4ed4ff;
}
.style__passedExamActive--L4keS {
  color: #000;
  background: #f9fafa;
}
.style__failedExam--LT8f1 {
  color: #fc3633;
  background: #fff3f0;
}
`, "",{"version":3,"sources":["webpack://./../ds-admin/src/components/ExamResult/style.less"],"names":[],"mappings":"AAGA;EAEI,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;EACA,uBAAA;AAHJ;AAHA;EAUI,cAAA;EACA,mBAAA;AAJJ;AAPA;EAeI,WAAA;EACA,mBAAA;AALJ;AAXA;EAmBI,cAAA;EACA,mBAAA;AALJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .examResult {\n    border-radius: @spacing-100;\n    line-height: 1.6rem;\n    text-align: center;\n    display: inline-block;\n    padding: @spacing-25 @spacing-50;\n  }\n\n  .passedExamInactive {\n    color: @color-asphalt-grey-050;\n    background: @color-light-blue-080;\n  }\n\n  .passedExamActive {\n    color: @color-black;\n    background: @color-asphalt-grey-099;\n  }\n  .failedExam {\n    color: @color-red-neon;\n    background: @color-red-099;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"examResult": `style__examResult--oGWj9`,
	"passedExamInactive": `style__passedExamInactive--LzOea`,
	"passedExamActive": `style__passedExamActive--L4keS`,
	"failedExam": `style__failedExam--LT8f1`
};
export default ___CSS_LOADER_EXPORT___;
