// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__empty--a__Jj {
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}
.style__body--LnRMm {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./../components/src/Empty/style.less"],"names":[],"mappings":"AAEA;EAEI,mBAAA;EACA,uBAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AAFJ;AALA;EAWI,kBAAA;AAHJ","sourcesContent":["@import '@yaak/components/src/spacing';\n\n:local {\n  .empty {\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    margin: 0 @spacing-100;\n  }\n\n  .body {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": `style__empty--a__Jj`,
	"body": `style__body--LnRMm`
};
export default ___CSS_LOADER_EXPORT___;
