import React, { useContext } from 'react'
import FormDialog, {
  InputProp,
  FormDialogTypes,
} from '@yaak/components/src/FormDialog/FormDialog'
import {
  getPartners,
  Partner,
  SimRigsData,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import Icon, { IconSizes } from '@yaak/components/src/Icon/Icon'

const nameInput = {
  name: 'name',
  title: 'Name',
  required: true,
  placeHolderText: 'e.g. Bugs',
}

const partnerInput = (partners: Array<Partner>) => ({
  name: 'partnerId',
  title: 'Partner',
  required: true,
  placeHolderText: 'Select a partner',
  description: 'Be sure you have already created the partner entity.',
  selections: partners.map((partner) => ({
    value: partner.id,
    name: partner.name,
    item: <div>{partner.name}</div>,
  })),
})

interface SimulatorEditProps {
  token: string
  simRig: SimRigsData | null
  isOpen: boolean
  onClose: () => void
  onCreate: (simRig: SimRigsData) => Promise<any>
}

export const SimulatorEditDialog: React.FunctionComponent<
  SimulatorEditProps
> = ({ simRig, token, isOpen, onClose, onCreate }) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const buildEditSimulatorRigDialogInputs = async (): Promise<InputProp[]> => {
    const partners = await getPartners({ token, onAlert: setShowToast })
    return [
      {
        ...nameInput,
        value: simRig?.name,
      },
      {
        ...partnerInput(partners),
        required: false,
        value: simRig?.partner?.id,
      },
    ]
  }

  return (
    <FormDialog
      title={`Edit simulator rig`}
      confirmationTitle=""
      titleIcon={<Icon name={'VR'} size={IconSizes.large} />}
      open={isOpen}
      onCancel={onClose}
      onConfirmed={onCreate}
      inputs={buildEditSimulatorRigDialogInputs}
      dialogType={FormDialogTypes.edit}
    />
  )
}
