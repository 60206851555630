export const convertIntMetacogToString = (value: number): string => {
  switch (value) {
    case 1:
      return "Unconsciously Incompetent"
    case 2:
      return "Consciously Incompetent"
    case 3:
      return "Unconsciously Competent"
    case 4:
      return "Consciously Competent"
    default:
      return ""
  }
}
