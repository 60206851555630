// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__header--sBglb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.style__simulationDrivesWrapper--WajOG {
  height: calc(100% - 9rem);
}
.style__simulationDrivesWrapperWithoutHeader--_o0sK {
  height: 100%;
  margin-top: 1rem;
  width: 100%;
}
.style__iconRed--lJ0Bz {
  color: #d72a1d;
}
.style__container--r0z5u {
  overflow: hidden;
  height: 100%;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SimulationOverview/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,mBAAA;EACA,8BAAA;AAHJ;AADA;EAQI,yBAAA;AAJJ;AAJA;EAYI,YAAA;EACA,gBAAA;EACA,WAAA;AALJ;AATA;EAkBI,cAAA;AANJ;AAZA;EAsBI,gBAAA;EACA,YAAA;EACA,aAAA;AAPJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .simulationDrivesWrapper {\n    height: calc(100% - 9rem);\n  }\n\n  .simulationDrivesWrapperWithoutHeader {\n    height: 100%;\n    margin-top: @spacing-100;\n    width: 100%;\n  }\n\n  .iconRed {\n    color: @color-red-050;\n  }\n\n  .container {\n    overflow: hidden;\n    height: 100%;\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `style__header--sBglb`,
	"simulationDrivesWrapper": `style__simulationDrivesWrapper--WajOG`,
	"simulationDrivesWrapperWithoutHeader": `style__simulationDrivesWrapperWithoutHeader--_o0sK`,
	"iconRed": `style__iconRed--lJ0Bz`,
	"container": `style__container--r0z5u`
};
export default ___CSS_LOADER_EXPORT___;
