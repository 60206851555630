// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__content--J9Zv_ {
  margin: 1rem;
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
}
.style__languageItem--g7Fi5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}
.style__languageItem--g7Fi5 > svg {
  transform: scale(0.833);
}
.style__searchBox--VFS5I {
  flex-grow: 1;
}
.style__actionBarButtonBox--mQ_Qc {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserOverview/style.less"],"names":[],"mappings":"AAIA;EAEI,YAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;AAJJ;AADA;EASI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AALJ;AAPA;EAeM,uBAAA;AALN;AAVA;EAoBI,YAAA;AAPJ;AAbA;EAwBI,YAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AARJ","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n@import '@yaak/components/src/shadows';\n\n:local {\n  .content {\n    margin: @spacing-100;\n    height: calc(100% - 2rem);\n    display: flex;\n    flex-direction: column;\n  }\n\n  .languageItem {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: @spacing-75;\n\n    > svg {\n      transform: scale(0.833);\n    }\n  }\n\n  .searchBox {\n    flex-grow: 1;\n  }\n\n  .actionBarButtonBox {\n    flex-grow: 1;\n    display: flex;\n    justify-content: flex-end;\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style__content--J9Zv_`,
	"languageItem": `style__languageItem--g7Fi5`,
	"searchBox": `style__searchBox--VFS5I`,
	"actionBarButtonBox": `style__actionBarButtonBox--mQ_Qc`
};
export default ___CSS_LOADER_EXPORT___;
