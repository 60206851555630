// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__breadcrumbs--ItKJ_ {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.style__beforeSecond--oheAs {
  color: #7d8287;
}
.style__beforeSecond--oheAs > a {
  color: #7d8287;
}
.style__first--RQIGv {
  color: #7d8287;
}
`, "",{"version":3,"sources":["webpack://./../components/src/Breadcrumbs/style.less"],"names":[],"mappings":"AAGA;EAEI,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AAHJ;AAHA;EAUI,cAAA;AAJJ;AANA;EAYM,cAAA;AAHN;AATA;EAiBI,cAAA;AALJ","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .breadcrumbs {\n    align-items: center;\n    background: @color-white;\n    display: flex;\n    flex-direction: row;\n    gap: 0.5rem;\n  }\n\n  .beforeSecond {\n    color: @color-asphalt-grey-050;\n    > a {\n      color: @color-asphalt-grey-050;\n    }\n  }\n\n  .first {\n    color: @color-asphalt-grey-050;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `style__breadcrumbs--ItKJ_`,
	"beforeSecond": `style__beforeSecond--oheAs`,
	"first": `style__first--RQIGv`
};
export default ___CSS_LOADER_EXPORT___;
